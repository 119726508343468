<template>
    <div></div>
</template>

<script>
import axios from 'axios'

export default {
    async mounted() {
        const html = await this.getLocationsList(this.$store.state.user.location_id)

        this.$swal({
            html,
            input: 'text',
            inputPlaceholder: this.$t('files.reference'),
            showCancelButton: true,
            showLoaderOnConfirm: true,
            confirmButtonColor: '#0092c8',
            title: this.$t('context.create_files'),
            confirmButtonText: this.$t('main.create'),
            cancelButtonText: this.$t('main.cancel'),
            preConfirm: value => {
                if (!value) {
                    return this.$swal.showValidationMessage('Reference cannot be empty')
                }

                const location_id = document.getElementById('location-id').value

                const m = /#(\d{1,2})-(\d{1,2})/.exec(value)

                if (m && m[1] < m[2] && m[2] - m[1] < 20) {
                    let arr = []
                    let reference = value.substring(0, value.indexOf(m[0]))

                    for (let i = m[1]; i <= m[2]; i++) {
                        arr.push(
                            axios.post(`files`, {
                                scan_code: reference + i,
                                location_id,
                            }),
                        )
                    }

                    return axios
                        .all(arr)
                        .then(() => {
                            this.$router.push({ name: 'files' })
                            window.toast('Files created')
                        })
                        .catch(({ response }) => {
                            this.$swal.showValidationMessage(response.data.file)
                        })
                } else {
                    return axios
                        .post(`files`, { scan_code: value, location_id })
                        .then(({ data }) => {
                            this.$router.push({
                                name: 'files.show',
                                params: { id: data.id },
                            })
                            window.toast(this.$t('i.create', { i: data.scan_code }))
                        })
                        .catch(({ response }) => {
                            const key = Object.keys(response.data)[0]
                            this.$swal.showValidationMessage(response.data[key])
                        })
                }
            },
        }).then(result => {
            if ('dismiss' in result) {
                this.$router.push({ name: 'files' })
            }
        })
    },

    methods: {
        async getLocationsList(location_id = null) {
            const { data } = await axios.get('locations', {
                params: { limit: 200 },
            })

            const options = data.data
                .map(
                    loc =>
                        `<option value="${loc.id}" ${loc.id === location_id ? 'selected' : ''}>${
                            loc.location
                        }</option>`,
                )
                .join('')

            return `<select id="location-id" class="form-select select-lg" value="1">${options}</select>`
        },
    },

    beforeUnmount() {
        this.$swal.close()
    },
}
</script>
