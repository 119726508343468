<template>
    <Modal :title="$t('devices.order_for_app')" @close="$router.push({ name: 'workflows' })">
        <div class="workflow-builder-wrapper">
            <Container @drop="onDrop" lock-axis="y" drag-handle-selector=".workflow-builder-item">
                <Draggable v-for="(workflow, index) in workflows" :key="workflow.id">
                    <div class="workflow-builder-item c-hand">
                        <div class="handler" style="padding: 6px 14px; flex: 0">
                            <i class="fas fa-grip-vertical"></i>
                        </div>
                        <div>
                            <span class="mr-2">{{ index + 1 }}.</span>
                            <span>{{ workflow.name }}</span>
                        </div>
                    </div>
                </Draggable>
            </Container>
        </div>

        <template #footer>
            <button @click="save()" class="btn btn-primary" :class="{ loading }">{{ $t('main.save') }}</button>
        </template>
    </Modal>
</template>

<script>
import axios from 'axios'
import { Container, Draggable } from 'vue3-smooth-dnd'

export default {
    components: { Container, Draggable },

    data() {
        return {
            id: this.$route.params.id,
            loading: false,

            workflows: [],
        }
    },

    mounted() {
        this.fetchData()
    },

    methods: {
        async fetchData() {
            const { data: paginator } = await axios.get('workflows', {
                params: { sort: 'order', by: 'asc', limit: 200 },
            })

            this.workflows = paginator.data
        },

        async save() {
            this.loading = true

            await axios.post('workflows_order', this.workflows)

            this.$router.push({ name: 'workflows' })
            window.toast(this.$t('devices.order_for_app_applied'))

            this.loading = false
        },

        onDrop(dropResult) {
            this.workflows = this.drop(this.workflows, dropResult)
        },

        drop(arr, dropResult) {
            const { removedIndex, addedIndex, payload } = dropResult

            if (removedIndex === null && addedIndex === null) return arr

            const result = [...arr]
            let itemToAdd = payload

            if (removedIndex !== null) {
                itemToAdd = result.splice(removedIndex, 1)[0]
            }

            if (addedIndex !== null) {
                result.splice(addedIndex, 0, itemToAdd)
            }

            return result
        },
    },
}
</script>
