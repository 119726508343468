<template>
    <form @submit.prevent="submit()" class="loading-lg" :class="{ loading: invalid }">
        <div class="form-group">
            <h2>{{ $t('auth.get_started_today') }}</h2>
        </div>

        <div class="form-group">
            <Errors :errors="errors" />
        </div>

        <div class="form-group">
            <label class="form-label" for="name">{{ $t('users.name') }}</label>
            <input v-model="invitation.name" id="name" class="form-input" readonly />
        </div>

        <div class="form-group">
            <label class="form-label" for="email">{{ $t('users.email') }}</label>
            <input v-model="invitation.email" id="email" class="form-input" readonly />
        </div>

        <NewPasswordInput v-model="invitation.password" label></NewPasswordInput>

        <div class="form-group d-flex">
            <label class="form-checkbox">
                <input id="tos" type="checkbox" v-model="TOSAccepted" />
                <i class="form-icon"></i>
            </label>
            <label for="tos" v-html="tos"></label>
        </div>

        <div class="form-group">
            <button type="submit" class="btn btn-primary" :disabled="!TOSAccepted" :class="{ loading }">
                <span>{{ $t('auth.create_account') }}</span>
            </button>
        </div>
    </form>
</template>

<script>
import axios from 'axios'
import { useVuelidate } from '@vuelidate/core'
import NewPasswordInput from '~/components/htmlform/NewPasswordInput.vue'

export default {
    setup: () => ({ v$: useVuelidate() }),

    components: { NewPasswordInput },

    data() {
        return {
            errors: null,
            invalid: true,
            loading: false,

            tos: null,
            TOSAccepted: false,

            token: this.$route.params.token,

            invitation: {},
        }
    },

    mounted() {
        const terms = this.$t('auth.terms')
        const privacy = this.$t('auth.privacy')
        const cookie_use = this.$t('auth.cookie_use')

        this.tos = this.$t('auth.tos', {
            terms: `<a href='https://www.cargosnap.com/terms-of-use' target='_blank'>${terms}</a>`,
            privacy: `<a href='https://www.cargosnap.com/privacy-terms' target='_blank'>${privacy}</a>`,
            cookie_use: `<a href='https://www.cargosnap.com/cookie-use-terms' target='_blank'>${cookie_use}</a>`,
        })

        axios
            .get(`auth/invited/${this.token}`)
            .then(({ data }) => {
                this.invalid = false
                this.invitation = data
            })
            .catch(() => {
                this.$router.push({ name: 'dashboard' })
                window.toast(this.$t('main.oops'), 'error')
            })
    },

    methods: {
        async submit() {
            if (!await this.v$.$validate()) return

            this.errors = null
            this.loading = true

            axios
                .post(`auth/invited/${this.token}`, this.invitation)
                .then(() => {
                    axios
                        .post('auth/login', {
                            email: this.invitation.email,
                            password: this.invitation.password,
                        })
                        .then(({ data: jwt }) => {
                            this.$store.dispatch('startSession', jwt)
                            this.$router.push({ name: 'dashboard' })
                            window.toast(this.$t('auth.welcome'))
                        })
                        .catch(({ response }) => {
                            this.loading = false
                            this.errors = response.data
                        })
                })
                .catch(({ response }) => {
                    this.loading = false
                    this.errors = response.data
                })
        },
    },
}
</script>
