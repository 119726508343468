<template>
    <div class="sidenav-wrapper">
        <slot name="toggle" :open="openNav"></slot>

        <div class="sidenav nav" :class="{ open }">
            <div class="sidenav-backdrop" @click="open = false"></div>
            <div class="sidenav-content">
                <a class="sidenav-close float-right" href="#" @click.prevent="open = false">
                    <i class="fa-regular fa-close fa-fw"></i>
                </a>
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: () => ({ open: false }),

    watch: {
        $route() {
            this.open = false
        },
    },

    methods: {
        openNav() {
            this.open = true
        },
    },
}
</script>
