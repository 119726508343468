<template>
    <SidePanel
        ref="panel"
        :title="$t('context.create_distribution_lists')"
        @close="$router.push({ name: 'settings.contact_lists' })"
    >
        <form @submit.prevent="createContactList()">
            <div class="form-group">
                <Errors :errors="errors" />
            </div>

            <div class="form-group">
                <label class="form-label" for="name">{{ $t('distribution_lists.name') }}</label>
                <input
                    id="name"
                    class="form-input"
                    v-model="list.name"
                    :placeholder="$t('distribution_lists.name')"
                    required
                />
            </div>

            <div class="form-group">
                <label class="form-label" for="customer_id">{{ $t('distribution_lists.customer_id') }}</label>
                <input
                    id="customer_id"
                    class="form-input"
                    v-model="list.customer_id"
                    :placeholder="$t('distribution_lists.customer_id')"
                    required
                />
            </div>

            <div class="form-group">
                <div class="input-group my-1" v-for="(v, i) in list.recipients" :key="i">
                    <input
                        type="email"
                        class="form-input input-sm"
                        v-model="list.recipients[i].email"
                        :placeholder="$t('users.email')"
                        required
                    />

                    <input
                        class="form-input input-sm ml-1"
                        v-model="list.recipients[i].label"
                        :placeholder="$t('distribution_lists.label')"
                    />

                    <a href="#" class="btn btn-sm btn-clear" @click.prevent="removeRecipient(i)"></a>
                </div>

                <button type="button" class="btn btn-sm" @click="addRecipient()">
                    <i class="fas fa-plus-circle mr-2"></i>
                    <span>{{ $t('distribution_lists.add_recipient') }}</span>
                </button>
            </div>

            <div class="form-group">
                <label class="form-label" for="language">{{ $t('main.language') }}</label>
                <select v-model="list.language" id="language" class="form-select" required>
                    <option v-for="(lang, key) in locales" :key="key" :value="key">{{ lang }}</option>
                </select>
            </div>

            <div class="form-group">
                <button class="btn btn-primary" type="submit">{{ $t('main.create') }}</button>
            </div>
        </form>
    </SidePanel>
</template>

<script>
import axios from 'axios'
import SidePanel from '~/components/SidePanel.vue'

export default {
    components: { SidePanel },

    data() {
        return {
            errors: null,
            locales: window.config.locales,

            list: {
                name: '',
                language: 'en',
                recipients: [{ email: '', label: '' }],
            },
        }
    },

    methods: {
        createContactList() {
            axios
                .post('distribution_lists', this.list)
                .then(() => {
                    this.$emit('refresh')
                    this.$router.push({ name: 'settings.contact_lists' })
                    window.toast(this.$t('i.create', { i: this.list.name }))
                })
                .catch(({ response }) => {
                    this.errors = response.data
                })
        },

        addRecipient() {
            this.list.recipients.push({ email: '', label: '' })
        },

        removeRecipient(index) {
            if (this.list.recipients.length > 1) {
                this.list.recipients.splice(index, 1)
            }
        },
    },
}
</script>
