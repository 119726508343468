<template>
    <Transition name="fade" appear>
        <div class="modal-custom" ref="bg">
            <div class="modal-wrapper">
                <div class="modal-header">
                    <h4 class="text-bold m-0">{{ title }}</h4>
                    <button v-if="onClose" class="btn btn-clear" @click="close()"></button>
                </div>

                <div class="modal-content">
                    <slot></slot>
                </div>

                <div class="modal-footer">
                    <slot name="footer"></slot>
                </div>
            </div>
        </div>
    </Transition>
</template>

<script>
export default {
    props: {
        title: String,
        onClose: [Function, Array]
    },

    data: () => ({ mouseDownOutsideModal: false }),

    mounted() {
        this.activate()
    },

    activated() {
        this.activate()
    },

    deactivated() {
        this.deactivate()
    },

    unmounted() {
        this.deactivate()
    },

    methods: {
        activate() {
            window.addEventListener('keyup', this.handleKeyUp)
            window.addEventListener('mouseup', this.handleMouseUp)
            window.addEventListener('mousedown', this.handleMouseDown)

            // Remove the scrollbar from the body, except when the modal is a child modal
            if (!this.hasParentModal()) document.body.classList.add('no-scroll')
        },

        deactivate() {
            window.removeEventListener('keyup', this.handleKeyUp)
            window.removeEventListener('mouseup', this.handleMouseUp)
            window.removeEventListener('mousedown', this.handleMouseDown)

            // Add the scrollbar back to the body, except when the modal is a child modal
            if (!this.hasParentModal()) document.body.classList.remove('no-scroll')
        },

        handleKeyUp(e) {
            if (e.key === 'Escape') this.close()
        },

        handleMouseUp(e) {
            // Check if the click started & ended outside the modal, only then close the modal
            const clickOutsideModal = this.mouseDownOutsideModal && e.target === this.$refs.bg

            if (clickOutsideModal) this.close()
        },

        handleMouseDown(e) {
            // Check if the cursor is on the scrollbar
            if (e.offsetX > e.target.clientWidth || e.offsetY > e.target.clientHeight) {
                return
            }

            this.mouseDownOutsideModal = e.target === this.$refs.bg
        },

        hasParentModal() {
            return this.$el.classList.contains('modal-stacked')
        },

        close() {
            if (!this.onClose) return

            // We do not want to close the modal when
            // - it has a child modal (e.g. creating a Form in WorkflowBuilder)
            // - it has a VSelect open, since this component also listens to Escape key
            // - it has a Datepicker open, since this component also listens to Escape key
            const hasChildModal = this.$el.querySelector('.modal-stacked') !== null
            const hasVSelectOpen = this.$el.querySelector('.vs__dropdown-menu') !== null
            const hasDatePickerOpen = this.$el.querySelector('.dp__outer_menu_wrap') !== null

            if (hasChildModal || hasVSelectOpen || hasDatePickerOpen) return

            this.$emit('close')
        },
    },
}
</script>
