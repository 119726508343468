import axios from 'axios'

const getDefaultState = () => {
    return {
        step_count: 6,
        current_step_number: 1,
        previous_step_number: 1,

        in_progress: false,
        is_finished: false,

        steps: {
            1: {
                name: '',
                labels: [],
            },
            2: {
                workflow_language: 'en',
                workflow_category: 'containers',
                workflow_identifier: '',
            },
            3: {
                workflow_identifiers_installed: [],
            },
            4: {
                name: '',
                team_name: '',
                company_sites: 'no',
            },
            5: {
                email: '',
                invited_emails: [],
            },
            6: {
                device_created_id: null,
                device_created_activated: false,
            },
        },
    }
}

export default {
    state: getDefaultState(),
    mutations: {
        TOUR_RESET(state) {
            Object.assign(state, getDefaultState())
        },

        TOUR_IN_PROGRESS(state, in_progress) {
            state.in_progress = in_progress
        },

        TOUR_IS_FINISHED(state, is_finished) {
            state.is_finished = is_finished
        },

        TOUR_SET_STEP_NUMBER(state, step_number) {
            state.previous_step_number = state.current_step_number
            state.current_step_number = step_number
        },

        TOUR_SET_CURRENT_STEP_STATE(state, current_step_state) {
            state.steps[state.current_step_number] = current_step_state
        },
    },
    actions: {
        startTour({ commit }) {
            commit('TOUR_SET_STEP_NUMBER', 1)
            commit('TOUR_IN_PROGRESS', true)
            commit('TOUR_IS_FINISHED', false)
        },

        dismissTour({ commit }) {
            commit('TOUR_IN_PROGRESS', false)
        },

        endTour({ commit }) {
            commit('TOUR_RESET')
        },

        async tourNextStep({ commit, getters, state }) {
            try {
                await axios.post(`onboarding_tour/step/${state.current_step_number}`, getters.currentStep)

                commit('TOUR_SET_CURRENT_STEP_STATE', getters.currentStep)

                if (state.current_step_number >= state.step_count) {
                    commit('TOUR_IS_FINISHED', true)
                } else {
                    commit('TOUR_SET_STEP_NUMBER', state.current_step_number + 1)
                }
            } catch {
            }
        },

        tourPreviousStep({ commit, state }) {
            // Would we want to save current step on this action?
            commit('TOUR_SET_STEP_NUMBER', state.current_step_number - 1)
        },
    },

    getters: {
        currentStep: state => state.steps[state.current_step_number],
    },
}
