<template>
    <SideNav>
        <template #toggle="{ open }">
            <button class="btn mobile-menu mr-2" @click="open">
                <i class="fa-regular fa-bars"></i>
            </button>
        </template>

        <div class="sidenav-title">
            <img width="180" src="/images/logo-blue.svg" class="img-invertable" />
        </div>

        <NavBarItems :user="user"></NavBarItems>
    </SideNav>
</template>

<script>
import NavBarItems from './NavBarItems.vue'
import SideNav from '~/components/SideNav.vue'

export default {
    props: ['user'],

    components: { NavBarItems, SideNav },
}
</script>
