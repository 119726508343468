<template>
    <div class="box" :id="`map-${snap_id}`"></div>
</template>

<script>
import * as L from 'leaflet'
import 'leaflet.markercluster'

export default {
    props: ['index', 'uploads', 'snap_id'],

    data: () => ({ map: null, markers: null, icons: {} }),

    watch: {
        uploads: 'fetchData',
        snap_id: 'moveToSnapId',
    },

    mounted() {
        this.loadMap()
        this.fetchData()
    },

    methods: {
        loadMap() {
            const layer = L.tileLayer(window.config.openStreetMap.skin, {
                attribution: window.config.openStreetMap.copyright,
                maxZoom: 19,
            })

            this.map = L.map(`map-${this.snap_id}`, {
                zoom: 2,
                minZoom: 2,
                layers: [layer],
                center: [51.505, -0.09],
                touchZoom: true,
            })

            this.icons.blue = L.icon({
                iconUrl: '/images/markers/marker-icon-blue.png',
                iconSize: [36, 36],
                iconAnchor: [17, 35],
                popupAnchor: [0, -30],
            })

            this.icons.red = L.icon({
                iconUrl: '/images/markers/marker-icon-red.png',
                iconSize: [36, 36],
                iconAnchor: [17, 35],
                popupAnchor: [0, -30],
            })

            this.map.scrollWheelZoom.disable()
        },

        moveToSnapId() {
            if (isNaN(this.snap_id)) {
                this.map.fitBounds(this.markers.getBounds())
            } else {
                this.markers.eachLayer(marker => {
                    if (marker.snap_id == this.snap_id) {
                        this.markers.zoomToShowLayer(marker, () => {
                            marker.openPopup()
                        })
                    }
                })
            }
        },

        fetchData() {
            const items = this.uploads

            if (this.markers) {
                this.markers.clearLayers()
            }

            this.markers = L.markerClusterGroup({})

            items.forEach(item => {
                const createdAt = dayjs(item.created_at).format('YYYY-MM-DD HH:mm:ss')

                const address = item.geocoding
                    && item.geocoding.addresses
                    && item.geocoding.addresses.length
                    && item.geocoding.addresses[0].address.freeformAddress

                if (item.latitude !== null) {
                    const marker = L.marker(L.latLng(item.latitude, item.longitude), {
                        icon: item.has_damage ? this.icons.red : this.icons.blue,
                    })

                    marker.snap_id = item.id

                    const popup = L.popup({
                        maxWidth: 250,
                    }).setContent(
                        `<div>
                            <a href="/files/${item.file_id}" class="postzegel mb-2">
                                <img src="${item.image_thumb_sm}" width="100" alt />
                            </a>
                            <div><a href="/files/${item.file_id}">${item.scan_code}</a></div>
                            <div>${createdAt}</div>
                            ${address ? '<div>' + address + '</div>' : ''}
                            <div>${item.latitude} / ${item.longitude}</div>
                        </div>`,
                    )

                    marker.bindPopup(popup)

                    this.markers.addLayer(marker)
                }
            })

            // only add markers if there are any
            const layersToBeAdded = this.markers.getLayers()

            if (layersToBeAdded.length) {
                this.map.addLayer(this.markers)
                this.moveToSnapId()
            }
        },
    },
}
</script>

<style lang="scss">
@import '../../node_modules/leaflet/dist/leaflet.css';
@import '../../node_modules/leaflet.markercluster/dist/MarkerCluster.css';
@import '../../node_modules/leaflet.markercluster/dist/MarkerCluster.Default.css';
</style>
