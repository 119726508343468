<template>
    <form @submit.prevent="save()">
        <ContextBar :title="$t('main.permissions')"></ContextBar>
        <Errors :errors="errors" />

        <div v-for="(values, permission) in permissions" :key="permission">
            <div class="flex-space">
                <div class="">{{ $t(`permissions.${permission}`) }}</div>

                <select v-model="permissions[permission]" class="form-select mw-250">
                    <option :value="0">{{ $t('permissions.role_none') }}</option>
                    <option :value="1">{{ $t('permissions.role_super_admin') }}</option>
                    <option :value="2">{{ $t('permissions.role_super_admin_manager') }}</option>
                    <option :value="3">{{ $t('permissions.role_super_admin_manager_user') }}</option>
                </select>
            </div>
            <div class="divider"></div>
        </div>

        <div class="flex-space my-4">
            <VisibleOnChangeButton ref="observer" :data="permissions"></VisibleOnChangeButton>
        </div>
    </form>
</template>

<script>
import axios from 'axios'

export default {
    beforeRouteLeave(to, from, next) {
        this.$refs.observer.beforeRouteLeave(to, from, next)
    },

    data() {
        return {
            errors: null,
            permissions: [],
        }
    },

    async mounted() {
        const { data: permissions } = await axios.get('permissions')

        for (let permission in permissions) {
            permissions[permission] = this.permissionToIndex(permissions[permission])
        }

        this.permissions = permissions
    },

    methods: {
        save() {
            const permissions = {}

            for (let key in this.permissions) {
                const index = this.permissions[key]

                permissions[key] = this.indexToPermission(index)
            }

            axios
                .post('permissions', permissions)
                .then(() => {
                    this.$refs.observer.initialize()
                    window.toast(this.$t('i.update', { i: this.$t('main.permissions') }))
                })
                .catch(({ response }) => {
                    this.errors = response.data
                })
        },

        permissionToIndex(permission) {
            if (permission.User) {
                return 3
            } else if (permission.Manager) {
                return 2
            } else if (permission.SuperAdmin) {
                return 1
            } else {
                return 0
            }
        },

        indexToPermission(index) {
            switch (index) {
                case 1:
                    return { SuperAdmin: true, Manager: false, User: false }
                case 2:
                    return { SuperAdmin: true, Manager: true, User: false }
                case 3:
                    return { SuperAdmin: true, Manager: true, User: true }
                default:
                    return { SuperAdmin: false, Manager: false, User: false }
            }
        },
    },
}
</script>
