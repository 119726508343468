<template>
    <Modal>
        <LoginRoute v-if="type === 'login'"></LoginRoute>
        <RegisterRoute v-if="type === 'register'"></RegisterRoute>

        <template #footer v-if="properties.modal_closable">
            <button class="btn" @click="$emit('close')">{{ $t('main.cancel') }}</button>
        </template>
    </Modal>
</template>

<script>
import LoginRoute from '~/pages/auth/login.vue'
import RegisterRoute from '~/pages/auth/register.vue'

export default {
    components: { LoginRoute, RegisterRoute },

    props: ['type', 'properties'],

    created() {
        const query = { email: this.properties.email }

        if (this.type === 'register') query.type = 'free'

        this.$router.push({ path: this.$route.fullPath, query })
    },

    mounted() {
        document.body.classList.add('no-scroll')
    },

    beforeUnmount() {
        document.body.classList.remove('no-scroll')
    },
}
</script>
