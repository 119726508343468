<template>
    <Modal :title="$t('auth.welcome')">
        <p>{{ $t('auth.activate_your_account') }}</p>

        <template #footer>
            <button class="btn" @click="resendActivationEmail()">{{ $t('auth.resend_activation_email') }}</button>
        </template>
    </Modal>
</template>

<script>
import axios from 'axios'

export default {
    data: () => ({ activationEmailSent: false }),

    methods: {
        resendActivationEmail() {
            if (this.activationEmailSent) return

            this.activationEmailSent = true

            axios.post('resend_activation').then(() => {
                window.toast(this.$t('auth.sent_activation_email'))
            })
        },
    },
}
</script>
