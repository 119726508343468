<template>
    <div class="files-show">
        <div class="context-bar">
            <div class="context-left">
                <router-link v-if="backRoute" class="btn btn-sm s-circle mr-2" :to="{ name: backRoute }" exact>
                    <i class="far fa-arrow-left"></i>
                </router-link>
                <h2 :title="lookupInfo" @click="$emit('editReference')" @mouseover.once="fetchReferenceInfo()">
                    <span>{{ file.scan_code }}</span>
                </h2>
                <button class="close-file btn btn-sm s-circle" @click="$emit('toggleClosed')">
                    <i class="fas fa-xs fa-fw" :class="[file.closed_at ? 'fa-lock' : 'fa-lock-open']"></i>
                </button>
            </div>

            <div class="context-right">
                <slot name="actions"></slot>
            </div>
        </div>

        <div class="box file-header">
            <div class="flex-space">
                <div>
                    <div class="file-header-info">
                        <div>
                            <div class="text-bold">{{ $t('files.uploads') }}</div>
                            <div class="flex-space">
                                <h2 class="m-0 mr-2">{{ counters['all'] - counters['form'] }}</h2>
                                <div v-if="counters['snap_with_damage']" class="chip chip-lg bg-error ml-2">
                                    <i class="fa-regular fa-circle-exclamation"></i>
                                    <span class="ml-2">{{ counters['snap_with_damage'] }}</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="text-bold">{{ $t('main.created') }}</div>
                            <div>
                                <DateTime :date="file.created_at"></DateTime>
                            </div>
                        </div>
                        <div class="processing-time">
                            <div class="text-bold">{{ $t('files.processing_time') }}</div>
                            <div>{{ processingTime(file.created_at, file.updated_at) }}</div>
                        </div>
                    </div>

                    <Fields :fields="file.fields" hideSnapFields></Fields>
                </div>

                <div class="qr-code hide-sm">
                    <QrcodeVue :value="file.scan_code"></QrcodeVue>
                </div>
            </div>

            <blockquote v-if="file.share && file.share.comment">
                <div>{{ file.share.comment }}</div>
                <span class="text-gray">{{ file.share.shared_by }}</span>
            </blockquote>
        </div>

        <IssueFileDisplay :issues="file.issues"></IssueFileDisplay>

        <div class="flex-end my-4">
            <div class="dropdown dropdown-right mr-2">
                <a tabindex="0" class="btn dropdown-toggle">
                    {{ $t(`files.filter_${activeFilter}`) }}
                    <i class="fas fa-fw fa-filter ml-2"></i>
                </a>
                <ul class="menu">
                    <template v-for="(count, key) in counters" :key="key">
                        <li class="menu-item" v-show="count">
                            <a href="#" :class="{ active: key === activeFilter }" @click.prevent="updateFilter(key)">
                                {{ $t(`files.filter_${key}`) }} ({{ count }})
                            </a>
                        </li>
                    </template>
                    <li class="menu-item" v-if="file.fields.length">
                        <a href="#" @click.prevent="updateFilter('fields')">
                            {{ $t('files.filter_fields') }}
                        </a>
                    </li>
                    <li class="divider"></li>
                    <li class="menu-item">
                        <a href="#" :class="{ loading: togglingShares }"
                            @click.prevent="toggleSharesForActiveFilter(true)">
                            {{ $t('files.toggle_shared_enable') }}
                        </a>
                    </li>

                    <li class="menu-item">
                        <a href="#" :class="{ loading: togglingShares }"
                            @click.prevent="toggleSharesForActiveFilter(false)">
                            {{ $t('files.toggle_shared_disable') }}
                        </a>
                    </li>
                </ul>
            </div>
            <button class="btn mr-2" @click="toggleSort()"
                :title="[activeSort ? $t('files.sort_old_to_new') : $t('files.sort_new_to_old')]">
                <i class="far fa-fw" :class="[activeSort ? 'fa-sort-numeric-down' : 'fa-sort-numeric-up']"></i>
            </button>

            <button class="btn" @click="toggleView()"
                :title="[viewType === 'list' ? $t('files.view_list') : $t('files.view_grid')]">
                <i class="far fa-fw" :class="[viewType === 'list' ? 'fa-list' : 'fa-grid']"></i>
            </button>
        </div>

        <Timeline :filter="activeFilter" :sortDesc="activeSort" :viewType="viewType" :fields="file.fields"
            :uploads="file.uploads" :form_submits="file.form_submits" :workflow_runs="file.workflow_runs"
            @refresh="$emit('refresh')">
        </Timeline>
    </div>
</template>

<script>
import axios from 'axios'
import QrcodeVue from 'qrcode.vue'
import Timeline from '~/components/timeline/Timeline.vue'
import Fields from '~/components/files/FileFields.vue'
import IssueFileDisplay from '~/components/issues/IssueFileDisplay.vue'
const getContainerLookups = () => import('~/assets/container_lookups.json').then(m => m.default || m)

export default {
    props: ['file', 'backRoute'],

    components: { QrcodeVue, Timeline, Fields, IssueFileDisplay },

    data() {
        return {
            counters: {},
            lookupInfo: '',

            viewType: this.$store.state.timelineViewType,
            activeFilter: this.$route.query.filter || 'all',
            activeSort: this.$store.state.timelineSortDesc,

            togglingShares: false,

            canClose: this.$store.getters.can('Close files'),
        }
    },

    mounted() {
        document.title = `File ${this.file.scan_code} | Cargosnap`

        this.calculateCountsPerUploadType(this.file.timeline)

        setTimeout(() => {
            const hash = window.location.hash
            const element = document.getElementById(hash.substring(1))

            if (element) {
                element.scrollIntoView()
            }
        }, 0)
    },

    methods: {
        calculateCountsPerUploadType(uploads) {
            const counters = {
                all: this.file.timeline.length,
                snap: 0,
                snap_with_damage: 0,
                attachment: 0,
                document: 0,
                video: 0,
                form: 0,
                seal: 0,
            }

            for (let i = 0; i < uploads.length; i++) {
                const upload = uploads[i]

                if (upload.has_damage) {
                    counters.snap_with_damage++
                }

                if (upload.upload_type in this.counters) {
                    counters[upload.upload_type]++
                }
            }

            this.counters = counters
        },

        updateFilter(filter) {
            if (this.activeFilter === filter) return

            this.activeFilter = filter
            this.$router.replace({ ...this.$route, query: { filter } })
        },

        toggleSort() {
            this.activeSort = !this.activeSort
            this.$store.commit('UPDATE_TIMELINE_SORT_DESC', this.activeSort)
        },

        toggleView() {
            this.viewType = this.viewType === 'list' ? 'grid' : 'list'
            this.$store.commit('UPDATE_TIMELINE_VIEW_TYPE', this.viewType)
        },

        async fetchReferenceInfo() {
            const lookups = await getContainerLookups()
            const info = lookups[this.file.scan_code.substring(0, 4)]

            if (info) {
                this.lookupInfo = `${info.company}\n${info.city}, ${info.country}`
            }
        },

        processingTime(created_at, updated_at) {
            return dayjs(created_at).to(updated_at, true)
        },

        toggleSharesForActiveFilter(included) {
            if (this.activeFilter === 'fields') return

            this.togglingShares = true

            let items = []

            if (this.activeFilter === 'all') {
                items = this.file.timeline
            } else if (this.activeFilter === 'snap_with_damage') {
                items = this.file.timeline.filter(upload => upload.upload_type === 'snap' && upload.has_damage)
            } else {
                items = this.file.timeline.filter(upload => upload.upload_type === this.activeFilter)
            }

            axios
                .patch(`files/${this.file.id}/include_items_in_share`, {
                    include_in_share: included,
                    items,
                })
                .then(() => {
                    if (this.activeFilter === 'all') {
                        items.forEach(i => (i.shared = included))
                        items.forEach(i => (i.include_in_share = included))
                    } else if (this.activeFilter === 'form') {
                        items.forEach(i => (i.shared = included))
                    } else {
                        items.forEach(i => (i.include_in_share = included))
                    }

                    this.togglingShares = false
                })
        },
    },
}
</script>
