<template>
    <div class="form-group" :class="{ 'has-error': v$.value.$error }">
        <label v-if="label" class="form-label" for="password">{{ $t('users.password') }}</label>

        <input type="password" id="password" v-model="value" class="form-input" autocomplete="new-password"
            :placeholder="placeholder" />

        <div v-if="v$.value.$error" class="form-input-hint">{{ v$.value.$errors[0].$message }}</div>
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, minLength, helpers } from '@vuelidate/validators'

const passwordRules = (password) => {
    if (false === /\d/.test(password)) return false // Minimum of 1 Number
    if (false === /[A-Z]/.test(password)) return false // Minimum of 1 Uppercase Letter

    return true
}

export default {
    props: {
        modelValue: String,
        placeholder: String,
        label: Boolean,
    },
    emits: ['update:modelValue'],

    setup: () => ({ v$: useVuelidate() }),

    computed: {
        value: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        }
    },

    validations() {
        return {
            value: {
                required: helpers.withMessage('', required),
                minLength: helpers.withMessage(this.$t('auth.new_password_minlength', { minlength: 8 }), minLength(8)),
                passwordRules: helpers.withMessage(this.$t('auth.new_password_complexity'), passwordRules),
            },
        }
    },
}
</script>