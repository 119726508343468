<template>
    <div class="search-on-device-dropdown dropdown dropdown-right" @mouseover.once="fetchData()">
        <a tabindex="0" class="btn dropdown-toggle" :class="{ badge: value }" data-badge="1">
            <i class="fa-regular fa-fw fa-mobile mr-2"></i>
            <span>{{ $t('files.search_on_device') }}</span>
        </a>
        <div class="menu">
            <div class="nav">
                <div class="form-group">
                    <div class="flex-space">
                        <select ref="search" class="form-select" v-model="value">
                            <option :value="undefined" disabled hidden>{{ $t('files.search_on_device') }}</option>
                            <option v-for="d in devices" :key="d.id" :value="d.id">{{ d.nickname }}</option>
                        </select>
                        <button v-if="value" class="btn btn-clear ml-2" @click="value = undefined"></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    props: { modelValue: [Number, String] },
    emits: ['update:modelValue'],

    data: () => ({ devices: [] }),

    computed: {
        value: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('update:modelValue', value)
                setTimeout(() => this.$refs.search.focus())
            }
        }
    },

    mounted() {
        // Prefetch data on mobile
        if (window.innerWidth <= 1280) this.fetchData()
    },

    methods: {
        async fetchData() {
            const { data: paginator } = await axios.get('devices', {
                params: { sort: 'nickname', by: 'asc', limit: 200 },
            })

            this.devices = paginator.data
        },
    },
}
</script>
