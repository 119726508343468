<template>
    <div class="platform">
        <NavBar :user="user"></NavBar>

        <div class="platform-wrapper">
            <TenantImpersonationIndicator v-if="user.impersonate_tenant_id" :company="user.company">
            </TenantImpersonationIndicator>
            <TrialExpirationIndicator v-if="user.company.trial_expires_at" :expiresAt="user.company.trial_expires_at">
            </TrialExpirationIndicator>

            <div class="content">
                <router-view></router-view>
            </div>
        </div>

        <NotActivatedModal v-if="user.activated === false" @close="user.activated = true"></NotActivatedModal>
        <NeedsPasswordChangeModal v-else-if="user.needs_password_change === true"></NeedsPasswordChangeModal>
        <OnboardingTour v-else-if="tour.in_progress"></OnboardingTour>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { defineAsyncComponent } from 'vue'

import NavBar from './navbar/NavBar.vue'
import NotActivatedModal from './NotActivatedModal.vue'
import NeedsPasswordChangeModal from './NeedsPasswordChangeModal.vue'
import TrialExpirationIndicator from './TrialExpirationIndicator.vue'
import TenantImpersonationIndicator from './TenantImpersonationIndicator.vue'

export default {
    components: {
        NavBar,
        NotActivatedModal,
        NeedsPasswordChangeModal,
        TrialExpirationIndicator,
        TenantImpersonationIndicator,
        OnboardingTour: defineAsyncComponent(() => import('./onboarding/tour/OnboardingTour.vue'))
    },

    computed: mapState(['user', 'tour']),

    async mounted() {
        await this.$store.dispatch('initColorScheme')
        await this.$store.dispatch('fetchUser')

        this.$gtag.set('user_properties', { user_uuid: this.user.id, tenant_id: this.user.tenant_id })
    },
}
</script>
