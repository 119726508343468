<template>
    <div v-for="item in timeline" :key="item.id" class="issue-timeline-item" :class="{ comment: item.comment }">
        <IssueTimelineItem :name="item.author.name" :type="item.type" :oldData="item.oldData" :newData="item.newData"
            :timestamp="item.timestamp">
        </IssueTimelineItem>

        <div v-if="item.comment" class="issue-timeline-item-comment">
            <TextEditor v-if="item.edit" v-model="item.comment.description" @save="updateComment(item)"
                @delete="deleteComment(item)" @cancel="item.edit = false" :placeholder="$t('issues.write_a_comment')"
                autoFocus>
            </TextEditor>

            <div v-else class="text-comment">
                <div class="ql-editor p-0" v-html="item.comment.description"></div>
                <i v-if="item.author.id === $store.state.user.id" @click="item.edit = true"
                    class="fa-regular fa-pencil c-hand"></i>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import IssueTimelineItem from './IssueTimelineItem.vue'
import TextEditor from '~/components/htmlform/TextEditor.vue'

export default {
    emits: ['update'],
    props: { timeline: Array },
    components: { IssueTimelineItem, TextEditor },

    data() {
        return {
            issue_number: this.$route.params.issue_number,
        }
    },

    methods: {
        async updateComment(item) {
            await axios.patch(`issues/${this.issue_number}/comments/${item.comment.id}`, {
                description: item.comment.description
            })

            item.edit = false
        },

        deleteComment(item) {
            this.$swal({
                showCancelButton: true,
                title: this.$t('main.are_you_sure'),
                confirmButtonText: this.$t('main.yes_delete_it'),
                cancelButtonText: this.$t('main.cancel'),
            }).then(async result => {
                if (!result.value) return

                await axios.delete(`issues/${this.issue_number}/comments/${item.comment.id}`)
                this.timeline.splice(this.timeline.indexOf(item), 1)
                this.$emit('update')
            })
        },
    }
}
</script>