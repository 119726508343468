<template>
    <div>
        <ContextBar
            :title="$t('main.webhooks')"
            :buttonText="$t('context.create_webhooks')"
            buttonAbility="Use global settings"
            buttonRoute="settings.webhooks.create"
        ></ContextBar>

        <span class="mr-2">{{ $t('settings.api_tokens_to_consume_data') }}</span>
        <a href="https://developer.cargosnap.com" target="_blank">{{ $t('api_tokens.documentation') }}</a>

        <DataTable endpoint="webhooks" :columns="columns" :key="refresh">
            <template #column(description)="{ value }">{{ value }}</template>
            <template #column(action)="{ value }">{{ $t(`webhooks.${value}`) }}</template>
            <template #column(paused)="{ value }">
                <span v-if="value">
                    {{ $t('webhooks.paused') }}
                    <span class="ml-1 tooltip" :data-tooltip="$t('webhooks.paused_info')">
                        <i class="fas fa-info-circle"></i>
                    </span>
                </span>
                <span v-else>{{ $t('webhooks.running') }}</span>
            </template>
            <template #column(email_on_failure)="{ value }">
                <div v-for="email in value.split(',')" :key="email">{{ email }}</div>
            </template>
            <template #column(actions)="{ item }">
                <div class="dropdown dropdown-right">
                    <a tabindex="0" class="btn dropdown-toggle">
                        <i class="fas fa-ellipsis-h fa-fw"></i>
                    </a>
                    <ul class="menu">
                        <li class="menu-item">
                            <router-link :to="{ name: 'settings.webhooks.show', params: { id: item.id } }">
                                {{ $t('main.edit') }}
                            </router-link>
                        </li>
                        <li class="menu-item">
                            <a href="#" class="text-error" @click.prevent="deleteWebhook(item)">
                                {{ $t('main.delete') }}
                            </a>
                        </li>
                    </ul>
                </div>
            </template>
        </DataTable>

        <router-view @refresh="refresh++"></router-view>
    </div>
</template>

<script>
import axios from 'axios'
import DataTable from '~/components/DataTable.vue'

export default {
    components: { DataTable },

    data() {
        return {
            refresh: 0,
            columns: [
                { name: 'description', th: 'webhooks.description', sortable: true },
                { name: 'action', th: 'webhooks.action', sortable: true },
                { name: 'paused', th: 'webhooks.status', sortable: true },
                { name: 'email_on_failure', th: 'webhooks.email', sortable: true },
                { name: 'actions', min: true },
            ],
        }
    },

    methods: {
        deleteWebhook(token) {
            this.$swal({
                showCancelButton: true,
                title: this.$t('main.are_you_sure'),
                text: this.$t('main.you_wont_be_able_to_revert_this'),
                confirmButtonText: this.$t('main.yes_delete_it'),
                cancelButtonText: this.$t('main.cancel'),
            }).then(result => {
                if (result.value) {
                    axios.delete(`webhooks/${token.id}`).then(() => this.refresh++)
                }
            })
        },
    },
}
</script>
