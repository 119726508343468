<template>
    <div :class="{ 'has-icon-left': searchIcon, 'has-icon-right': clearable }">
        <input :value="value" @input="input" ref="input" class="form-input"
            :placeholder="placeholder || $t('main.search')" tabindex="10" />

        <i v-if="searchIcon" class="form-icon fa-regular fa-magnifying-glass"></i>
        <i v-if="clearable" class="form-icon fa-regular fa-xmark c-hand" @click="clear()"></i>
    </div>
</template>

<script>
export default {
    props: {
        modelValue: String,
        clearable: Boolean,
        searchIcon: Boolean,
        placeholder: String,
    },

    emits: ['update:modelValue'],

    computed: {
        value: {
            get() {
                return this.modelValue
            }
        }
    },

    methods: {
        input($event) {
            const timeoutId = window.setTimeout(() => { }, 0)
            for (let id = timeoutId; id >= 0; id -= 1) {
                window.clearTimeout(id)
            }

            // debounce 250ms
            const value = $event.target.value || undefined
            setTimeout(() => { this.$emit('update:modelValue', value) }, 250)
        },

        clear() {
            this.$emit('update:modelValue', undefined)
            this.$refs.input.focus()
        },
    },
}
</script>
