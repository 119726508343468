<template>
    <div class="search-on-field-dropdown dropdown dropdown-right" @mouseover.once="fetchData()">
        <a tabindex="0" class="btn dropdown-toggle" :class="{ badge: searchFieldsEnabledCount }"
            :data-badge="searchFieldsEnabledCount">
            <i class="fa-regular fa-fw fa-tags mr-2"></i>
            <span>{{ $t('files.search_on_fields') }}</span>
        </a>
        <div class="menu">
            <div class="nav">
                <select ref="search" class="form-select" v-model="newSearch.field" @change="newSearch.operator = '='">
                    <option :value="null" disabled hidden>{{ $t('files.search_on_fields') }}</option>
                    <option v-for="field in fields" :key="field.id" :value="field">{{ field.name }}</option>
                </select>
                <template v-if="newSearch.field">
                    <div v-if="operatorsForType.length > 1">
                        <button v-for="operator in operatorsForType" :key="operator"
                            @click="newSearch.operator = operator" class="btn mr-2"
                            :class="{ 'btn-primary': operator === newSearch.operator }">
                            {{ $t(`files.operator_${operator}`) }}
                        </button>
                    </div>

                    <div>
                        <FieldsModel :field="newSearch.field" v-model="newSearch.value"></FieldsModel>
                        <button @click="saveSearch()" class="btn btn-primary ml-2">{{ $t('main.search') }}</button>
                    </div>
                </template>

                <div v-if="searchFields.length" class="field-searches">
                    <div class="divider text-center"
                        :data-content="$tc('table.field_searches_active', searchFieldsEnabledCount)"></div>

                    <div class="fields-searches-list">
                        <div class="flex-space" v-for="(search, index) in searchFields" :key="index">
                            <label class="form-checkbox">
                                <input type="checkbox" v-model="search.enabled" />
                                <i class="form-icon"></i>
                            </label>
                            <div style="flex: 1">
                                <span>{{ search.name }}</span>
                                <span>&nbsp;</span>
                                <span style="text-transform: lowercase">
                                    {{ $t(`files.operator_${search.operator}`) }}
                                </span>
                                <span>&nbsp;</span>
                                <span>'{{ search.value }}'</span>
                            </div>
                            <a href="#" class="btn btn-sm btn-clear" @click.prevent="removeSearch(search)"></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import FieldsModel from '~/components/files/FileFieldsModel.vue'

export default {
    props: {
        searchFields: {
            type: Array,
            required: true,
        },
    },

    components: { FieldsModel },

    data() {
        return {
            fields: [],
            newSearch: this.getDefault(),
        }
    },

    mounted() {
        // Prefetch data on mobile
        if (window.innerWidth <= 1280) this.fetchData()
    },

    computed: {
        searchFieldsEnabledCount() {
            return this.searchFields.filter(field => field.enabled).length
        },

        operatorsForType() {
            const type = this.newSearch.field.type

            if (type === 'number') {
                return ['<', '=', '>']
            } else if (type === 'text') {
                return ['=', 'contains']
            } else {
                return ['=']
            }
        },
    },

    watch: {
        searchFields: {
            deep: true,
            handler(fields) {
                // Save field searches to vuex store
                this.$store.commit('UPDATE_FILES_SEARCH_FIELDS', fields)
            },
        },
    },

    methods: {
        async fetchData() {
            const { data: paginator } = await axios.get('fields', {
                params: { limit: 200, sort: 'name', by: 'asc' },
            })

            this.fields = paginator.data
        },

        getDefault() {
            return {
                field: null,
                value: null,
                enabled: true,
                operator: null,
            }
        },

        saveSearch() {
            const search = this.newSearch

            if (search.field.type === 'checkbox') {
                search.value = search.value ? '1' : '0'
            }

            if (!search.value) return

            this.searchFields.unshift({
                value: search.value,
                id: search.field.id,
                name: search.field.name,
                operator: search.operator,
                enabled: search.enabled,
            })

            setTimeout(() => this.$refs.search.focus())

            this.newSearch = this.getDefault()
        },

        removeSearch(search) {
            setTimeout(() => this.$refs.search.focus())

            const index = this.searchFields.indexOf(search)

            this.searchFields.splice(index, 1)
        },
    },
}
</script>
