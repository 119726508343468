<template>
    <SidePanel ref="panel" :title="$t('context.create_webhooks')" @close="$router.push({ name: 'settings.webhooks' })">
        <form v-if="$store.state.user.properties.use_api" @submit.prevent="createWebhook()">
            <div class="form-group">
                <Errors :errors="errors" />
            </div>

            <div class="form-group">
                <label class="form-label" for="description">{{ $t('webhooks.description') }}</label>
                <input id="description" class="form-input" v-model="hook.description"
                    :placeholder="$t('webhooks.description')" />
            </div>

            <div class="form-group">
                <label class="form-label" for="action">{{ $t('webhooks.action') }}</label>
                <select id="action" v-model="hook.action" class="form-select" required>
                    <option :value="action" v-for="action in actions" :key="action">
                        {{ $t(`webhooks.${action}`) }}
                    </option>
                </select>
            </div>

            <div class="form-group">
                <label class="form-label" for="url">{{ $t('webhooks.url') }}</label>
                <input type="url" id="url" class="form-input" v-model="hook.public_url"
                    placeholder="https://webhook.url" required />
            </div>

            <div class="form-group">
                <label class="form-label" for="email_on_failure">{{ $t('webhooks.email_on_failure') }}</label>
                <input type="email" class="form-input" id="email_on_failure" v-model="hook.email_on_failure"
                    :placeholder="$t('webhooks.emailcsv')" required />
            </div>

            <div class="flex-space">
                <button type="submit" class="btn btn-primary">{{ $t('main.create') }}</button>
                <button type="button" class="btn" @click="$router.push({ name: 'settings.webhooks' })">
                    {{ $t('main.cancel') }}
                </button>
            </div>
        </form>
        <NeedsPremiumLicense v-else :text="$t('license_limit.api_tokens')"
            :limit-text="$t('license_limit.premium_feature')"></NeedsPremiumLicense>
    </SidePanel>
</template>

<script>
import axios from 'axios'
import SidePanel from '~/components/SidePanel.vue'
import NeedsPremiumLicense from '~/components/NeedsPremiumLicense.vue'

export default {
    components: { SidePanel, NeedsPremiumLicense },

    data() {
        return {
            hook: {
                url: '',
                description: '',
                email_on_failure: '',
                action: 'NEW_FILE_CREATED',
            },

            actions: [
                'NEW_FILE_CREATED',
                'SUBMIT_FORM',
                'WORKFLOW_RUN_COMPLETE',
                'SHARE_CREATED',
                'FIELD_VALIDATION_FAILED',
                'FILE_CLOSED',
            ],

            errors: null,
        }
    },

    methods: {
        createWebhook() {
            this.errors = null

            axios
                .post('webhooks', this.hook)
                .then(() => {
                    this.$emit('refresh')
                    this.$router.push({ name: 'settings.webhooks' })
                })
                .catch(({ response }) => {
                    this.errors = response.data
                })
        },
    },
}
</script>
