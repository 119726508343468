<template>
    <SidePanel ref="panel" :title="$t('context.create_devices')" @close="$router.push({ name: 'settings.devices' })">
        <div class="form-group">
            {{
                $t('devices.you_have_active_devices', {
                    current: $store.state.user.company.counts.devices,
                    max: $store.state.user.company.license_devices_limit,
                })
            }}
        </div>

        <form
            v-if="$store.state.user.company.counts.devices < $store.state.user.company.license_devices_limit"
            @submit.prevent="createDevice()"
        >
            <div class="form-group">
                <Errors :errors="errors" />
            </div>

            <div class="form-group">
                <label class="form-label" for="location">{{ $t('main.location') }}</label>
                <select v-model="device.location_id" id="location" class="form-select" required>
                    <option v-for="location in locations" :key="location.id" :value="location.id">
                        {{ location.location }}
                    </option>
                </select>
            </div>

            <div class="form-group">
                <label class="form-label" for="name">{{ $t('main.name') }}</label>
                <input id="name" class="form-input" v-model="device.nickname" :placeholder="$t('main.name')" required />
            </div>

            <div class="flex-space">
                <button class="btn btn-primary" type="submit">{{ $t('main.create') }}</button>
            </div>
        </form>
        <NeedsPremiumLicense v-else :text="$t('license_limit.devices')"></NeedsPremiumLicense>
    </SidePanel>
</template>

<script>
import axios from 'axios'
import SidePanel from '~/components/SidePanel.vue'
import NeedsPremiumLicense from '~/components/NeedsPremiumLicense.vue'

export default {
    props: ['locations'],

    components: { SidePanel, NeedsPremiumLicense },

    data() {
        return {
            errors: null,
            device: { location_id: this.$store.state.user.location_id },
        }
    },

    methods: {
        createDevice() {
            axios
                .post('devices', this.device)
                .then(({ data }) => {
                    this.$emit('refresh')
                    this.$router.push({
                        name: 'settings.devices.activate',
                        params: { id: data.id },
                    })
                    window.toast(this.$t('i.create', { i: this.device.nickname }))
                })
                .catch(({ response }) => {
                    this.errors = response.data
                })
        },
    },
}
</script>
