<template>
    <SidePanel ref="panel" :title="$t('main.device')" @close="$router.push({ name: 'settings.devices' })">
        <div class="form-group">
            <Errors :errors="errors" />
        </div>

        <div class="dev-feature" v-if="$store.state.hasAllAbilities">
            <a href="#" @click.prevent="getApiSettingsUrl()">API Settings Call</a>

            <label class="form-checkbox">
                <input type="checkbox" v-model="device.properties.insights.metrics.enabled" />
                <i class="form-icon"></i>
                <span>Enable metrics</span>
            </label>

            <label class="form-checkbox">
                <input type="checkbox" v-model="device.properties.insights.logging.enabled" />
                <i class="form-icon"></i>
                <span>Enable logging</span>
            </label>

            <select
                class="form-select"
                :class="{ ghost: !device.properties.insights.logging.enabled }"
                v-model="device.properties.insights.logging.severity"
            >
                <option :value="0">Debug</option>
                <option :value="1">Info</option>
                <option :value="2">Warning</option>
                <option :value="3">Error</option>
                <option :value="4">Fatal</option>
            </select>
        </div>

        <fieldset :disabled="!canEdit">
            <router-view v-if="device.activated === false" :device="device" @activated="activated"></router-view>

            <div v-if="showCargy" class="mb-4">
                <div class="text-center">
                    <img height="300" src="/images/snappy/thumbup.png" alt style="margin-top: -75px" />
                    <p class="text-bold">{{ $t('devices.device_activated') }}</p>
                </div>
            </div>

            <div class="form-group">
                <label class="form-label" for="nickname">{{ $t('main.name') }}</label>
                <input
                    v-model="device.nickname"
                    class="form-input"
                    id="nickname"
                    :placeholder="$t('main.name')"
                    required
                />
            </div>

            <div class="form-group">
                <label class="form-label" for="location">{{ $t('main.location') }}</label>
                <select v-model="device.location_id" class="form-select" id="location" required>
                    <option v-for="location in locations" :key="location.id" :value="location.id">
                        {{ location.location }}
                    </option>
                </select>
            </div>

            <details class="accordion my-4" v-if="device.advanced_settings">
                <summary class="accordion-header">
                    <i class="fas fa-chevron-right mr-2"></i>
                    <span>{{ $t('devices.advanced_settings') }}</span>
                </summary>
                <div class="accordion-body">
                    <div class="mx-2 p-2">
                        <div class="mb-4">
                            <label class="form-checkbox">
                                <input
                                    type="checkbox"
                                    v-model="device.delete_after_upload"
                                    :true-value="false"
                                    :false-value="true"
                                />
                                <i class="form-icon"></i>
                                <span>{{ $t('devices.delete_after_upload') }}</span>
                            </label>

                            <label class="form-checkbox">
                                <input type="checkbox" v-model="device.allow_upload_without_wifi" />
                                <i class="form-icon"></i>
                                <span>{{ $t('devices.allow_upload_without_wifi') }}</span>
                            </label>

                            <label
                                class="form-checkbox"
                                :class="{
                                    ghost: device.allow_upload_without_wifi,
                                }"
                            >
                                <input type="checkbox" v-model="device.force_damage_upload" />
                                <i class="form-icon"></i>
                                <span>{{ $t('devices.force_damage_upload') }}</span>
                            </label>
                        </div>

                        <div class="mb-2">
                            <button class="btn" @click="$refs.widgetBuilder.showModal()">
                                <i class="fas fa-fw fa-mobile-alt mr-2"></i>
                                <span>{{ $t('devices.manage_widgets') }}</span>
                            </button>
                        </div>

                        <div class="mb-2">
                            <button class="btn" @click="$refs.regexBuilder.showModal()">
                                <i class="fas fa-fw fa-exclamation-triangle mr-2"></i>
                                <span>{{ $t('regex.reference_warning') }}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </details>

            <div class="flex-space">
                <div>
                    <VisibleOnChangeButton
                        class="mr-2"
                        ref="observer"
                        :data="device"
                        @click="updateDevice()"
                    ></VisibleOnChangeButton>

                    <router-link
                        tag="button"
                        class="btn btn-secondary"
                        v-if="device.activated === false"
                        :to="{ name: 'settings.devices.activate' }"
                    >
                        {{ $t('devices.activate') }}
                    </router-link>
                    <button class="btn btn-secondary" @click="deactivateDevice()" v-else-if="device.activated === true">
                        {{ $t('devices.deactivate') }}
                    </button>
                </div>

                <button class="btn btn-error" @click="deleteDevice()">{{ $t('main.delete') }}</button>
            </div>

            <table class="table mt-4" v-if="attributes">
                <template v-for="(attr, key) in attributes">
                    <tr v-if="attr" :key="key">
                        <td class="text-gray">{{ $t(`devices.attr_${key}`) }}</td>
                        <td colspan="2" v-html="attr"></td>
                    </tr>
                </template>
            </table>

            <template v-if="device.id">
                <WidgetBuilder ref="widgetBuilder" :widgets="device.widgets" @save="saveWidgets"></WidgetBuilder>

                <RegexBuilder
                    ref="regexBuilder"
                    :regex="device.scancode_regex"
                    :custom="device.scancode_custom_regex"
                    @save="saveScancodeRegex"
                ></RegexBuilder>
            </template>
        </fieldset>
    </SidePanel>
</template>

<script>
import axios from 'axios'
import SidePanel from '~/components/SidePanel.vue'
import RegexBuilder from '~/components/builders/RegexBuilder.vue'
import WidgetBuilder from '~/components/builders/WidgetBuilder.vue'

const getDeviceModels = () => import('~/assets/devices.json').then(m => m.default || m)

export default {
    props: ['locations'],

    components: {
        SidePanel,
        WidgetBuilder,
        RegexBuilder,
    },

    beforeRouteLeave(to, from, next) {
        this.$refs.observer.beforeRouteLeave(to, from, next)
    },

    data() {
        return {
            errors: null,

            device: {
                advanced_settings: false,
                properties: {
                    insights: {
                        logging: { enabled: false, severity: 0 },
                        metrics: { enabled: false },
                    },
                },
            },
            id: this.$route.params.id,

            models: {},
            showCargy: false,

            attributes: null,

            canEdit: this.$store.getters.can('Update devices'),
        }
    },

    async mounted() {
        axios.get(`devices/${this.id}`).then(({ data }) => {
            this.device = data

            if (this.device.attributes) {
                this.applyAppAttributes(this.device)
            }

            if (!this.device.properties) {
                this.device.properties = {
                    insights: {
                        logging: { enabled: false, severity: 0 },
                        metrics: { enabled: false },
                    },
                }
            }

            if (this.$store.state.user.role === 'Manager') {
                this.canEdit = this.$store.state.user.location_id === data.location_id
            }
        })

        this.models = await getDeviceModels()
    },

    methods: {
        getApiSettingsUrl() {
            axios.get(`devices/${this.id}/get_developer_api_settings_url`).then(({ data }) => {
                window.open(data, '_blank')
            })
        },

        activated() {
            this.showCargy = true

            this.device.activated = true
            this.$refs.observer.initialize()

            this.$router.replace({ name: 'settings.devices.show' })
            window.toast(this.$t('devices.device_activated'))
        },

        applyAppAttributes(device) {
            const attributes = device.attributes

            const model = attributes.model
            const platform = attributes.platform

            this.attributes = {
                app_version: device.app_version,
                platform: platform,
                model: model,
                manufacturer: attributes.manufacturer,
                locale: attributes.locale,
            }

            if (model) {
                const m = this.models[model] || { market_name: '' }
                this.attributes['model'] += ' <b>' + m.market_name + '</b>'
            }

            if (platform) {
                const c = platform === 'ios' ? 'apple' : platform
                const logo = `<i class='fab fa-fw fa-${c}'></i>`
                this.attributes['platform'] += logo
            }

            if (attributes.platform_version) {
                this.attributes['platform'] += '(' + attributes.platform_version + ')'
            }
        },

        saveWidgets(widgets) {
            this.device.widgets = widgets
            this.updateDevice()
        },

        saveScancodeRegex(regex, custom) {
            this.device.scancode_regex = regex
            this.device.scancode_custom_regex = custom
            this.updateDevice()
        },

        deactivateDevice() {
            this.$swal({
                showCancelButton: true,
                confirmButtonColor: '#e89543',
                title: this.$t('main.are_you_sure'),
                text: this.$t('main.you_wont_be_able_to_revert_this'),
                cancelButtonText: this.$t('main.cancel'),
                confirmButtonText: this.$t('devices.deactivate'),
            }).then(result => {
                if (result.value) {
                    axios.patch(`devices/${this.id}/deactivate`).then(({ data }) => {
                        this.$emit('refresh')
                        this.showCargy = false
                        this.device.activated = false
                        this.device.token = data.token
                        this.$refs.observer.initialize()
                    })
                }
            })
        },

        updateDevice() {
            this.errors = null

            axios
                .patch(`devices/${this.id}`, this.device)
                .then(() => {
                    this.$emit('refresh')
                    this.$refs.observer.initialize()
                    window.toast(this.$t('i.update', { i: this.device.nickname }))
                })
                .catch(({ response }) => {
                    this.errors = response.data
                })
        },

        deleteDevice() {
            this.$swal({
                showCancelButton: true,
                title: this.$t('main.are_you_sure'),
                text: this.$t('main.you_wont_be_able_to_revert_this'),
                confirmButtonText: this.$t('main.yes_delete_it'),
                cancelButtonText: this.$t('main.cancel'),
            }).then(result => {
                if (result.value) {
                    axios.delete(`devices/${this.id}`).then(() => {
                        this.$emit('refresh')
                        this.$router.push({ name: 'settings.devices' })
                        window.toast(this.$t('i.delete', { i: this.device.nickname }))
                    })
                }
            })
        },
    },
}
</script>
