<template>
    <div>
        <ContextBar :title="$t('profile.notifications')"></ContextBar>

        <label class="form-switch">
            <input v-model="settings.receive_notifications_for_all_locations" type="checkbox" />
            <i class="form-icon"></i>
            <span v-if="settings.receive_notifications_for_all_locations">
                {{ $t('profile.you_get_notifications_for_all_locations') }}
            </span>
            <span v-else>
                {{ $t('profile.you_get_notifications_for_locations') }}
            </span>
        </label>

        <div v-show="!settings.receive_notifications_for_all_locations" class="px-2">
            <div v-for="location in settings.location_filters" :key="location.id">
                <label class="form-switch">
                    <input v-model="location.notifier" type="checkbox" />
                    <i class="form-icon"></i>
                    <span>{{ location.location }}</span>
                </label>
            </div>
        </div>

        <div class="text-bold text-large my-4">{{ $t('profile.you_get_notification_when') }}</div>

        <label class="form-switch">
            <input type="checkbox" v-model="settings.notified_on_file_creation" />
            <i class="form-icon"></i>
            <span>{{ $t('profile.new_file_is_created') }}</span>
        </label>
        <label class="form-switch">
            <input type="checkbox" v-model="settings.notified_on_share_creation" />
            <i class="form-icon"></i>
            <span>{{ $t('profile.file_has_been_shared_with_you') }}</span>
        </label>
        <label class="form-switch">
            <input type="checkbox" v-model="settings.notified_on_snap_with_damage_creation" />
            <i class="form-icon"></i>
            <span>{{ $t('profile.snap_with_damage_uploaded') }}</span>
        </label>
        <label class="form-switch">
            <input type="checkbox" v-model="settings.notified_on_document_creation" />
            <i class="form-icon"></i>
            <span>{{ $t('timeline.document') }}</span>
        </label>

        <div class="context-bar flex-start" :class="{ ghost: !settingsChanged }">
            <button class="btn btn-primary" @click="save()">{{ $t('main.save') }}</button>
            <button class="btn" @click="cancel()">{{ $t('main.cancel') }}</button>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    data: () => ({ settings: [], settingsCache: [] }),

    computed: {
        settingsChanged() {
            return JSON.stringify(this.settings) !== JSON.stringify(this.settingsCache)
        },
    },

    async mounted() {
        const { data: settings } = await axios.get('settings')
        this.settings = settings

        this.settings.location_filters.forEach(location => {
            this.settings.locations_notifiers.forEach(notifier => {
                if (notifier.id === location.id) {
                    location.notifier = true
                }
            })
        })

        this.settingsCache = JSON.parse(JSON.stringify(this.settings))
    },

    methods: {
        async save() {
            this.settings.locations_notifiers = this.settings.location_filters.filter(location => location.notifier)

            await axios.post('notifications', this.settings)
            window.toast(this.$t('profile.settings_updated'))

            this.settingsCache = JSON.parse(JSON.stringify(this.settings))
        },

        cancel() {
            this.settings = JSON.parse(JSON.stringify(this.settingsCache))
        },
    },
}
</script>
