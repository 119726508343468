<template>
    <div>
        <ContextBar :title="$t('main.forms')" backRoute="forms">
            <label class="form-switch tooltip tooltip-bottom m-0" :data-tooltip="$t('table.compact_tooltip')">
                <input v-model="compact" type="checkbox" @change="updateCompactView()" />
                <i class="form-icon"></i>
                <span>{{ $t('table.compact') }}</span>
            </label>
            <DebounceInput v-model="reference" :placeholder="$t('files.search_on_reference')" clearable searchIcon />
        </ContextBar>

        <DataTable :endpoint="`forms/${id}/submits`" :columns="columns" :parameters="parameters" :key="refresh"
            :class="{ 'table-compact': compact }" hasFilters>
            <template #context-left v-if="form.id">
                <h2>{{ form.title }}</h2>
                <div v-if="$store.getters.can('Use global settings')" class="dropdown dropdown-right ml-auto">
                    <a tabindex="0" class="btn dropdown-toggle">
                        <i class="fas fa-ellipsis-h fa-fw"></i>
                    </a>
                    <ul class="menu">
                        <li class="menu-item">
                            <router-link :to="{ name: 'forms.edit', params: { id: form.id } }">
                                {{ $t('main.edit') }}
                            </router-link>
                        </li>
                        <li class="menu-item">
                            <a href="#" @click.prevent="duplicateForm(form)">{{ $t('main.duplicate') }}</a>
                        </li>
                        <li class="menu-item">
                            <a href="#" class="text-error" @click.prevent="deleteForm(form)">{{ $t('main.delete') }}</a>
                        </li>
                    </ul>
                </div>
            </template>
            <template #context-right>
                <DatePicker v-model="date_range" :placeholder="$t('files.created_between')" range multi-calendars>
                </DatePicker>
                <DebounceInput v-model="search" :placeholder="$t('forms.search_any_answer')" clearable />
                <button class="btn btn-primary" @click="exportExcel()" :class="{ loading }">
                    <span class="mr-2">{{ $t('forms.export_excel') }}</span>
                    <i class="fas fa-file-excel"></i>
                </button>
            </template>

            <template #column(submit_date_time)="{ value }">
                <DateTime :date="value"></DateTime>
            </template>
            <template #column(scan_code)="{ item }">
                <FileReference :id="item.file_id" :reference="item.scan_code"></FileReference>
            </template>
            <template #column(nick)="{ value }">{{ value }}</template>
            <template v-for="column in dynamicColumns" v-slot:[`column(${column.name})`]="{ item }">
                <div class="td-cell">{{ getValueForColumn(column, item) }}</div>
            </template>
            <template #column(actions)="{ item }">
                <div class="dropdown dropdown-right">
                    <a tabindex="0" class="btn dropdown-toggle">
                        <i class="fas fa-ellipsis-h fa-fw"></i>
                    </a>
                    <ul class="menu">
                        <li class="menu-item" v-if="canEdit">
                            <router-link
                                :to="{ name: 'form_submit.show', params: { id: item.form_id, submit_id: item.id } }">
                                {{ $t('main.edit') }}
                            </router-link>
                        </li>
                        <li class="menu-item text-error" v-if="canDelete">
                            <a href="#" @click.prevent="deleteSubmit(item)">{{ $t('main.delete') }}</a>
                        </li>
                    </ul>
                </div>
            </template>
        </DataTable>

        <router-view @refresh="refresh++"></router-view>
    </div>
</template>

<script>
import axios from 'axios'
import DataTable from '~/components/DataTable.vue'
import FileReference from '~/components/FileReference.vue'
import { duplicateForm, deleteForm, getAnswerForField } from '~/utils'

export default {
    components: { DataTable, FileReference },

    data() {
        return {
            refresh: 0,

            form: {},
            loading: false,
            canEdit: this.$store.getters.can('Update form submits'),
            canDelete: this.$store.getters.can('Delete form submits'),

            id: this.$route.params.id,
            compact: this.$store.state.formSubmitsTableCompactView,
            search: this.$route.query.reference,
            reference: this.$route.query.reference,
            date_range: this.$route.query.date_range,

            columns: [
                { name: 'submit_date_time', th: 'timeline.submit_date_time', sortable: true, class: 'mw-250' },
                { name: 'scan_code', th: 'files.reference', sortable: true },
                { name: 'nick', th: 'timeline.uploaded_by', sortable: true },
            ],

            dynamicColumns: [],
        }
    },

    computed: {
        parameters() {
            return {
                search: this.search || undefined,
                reference: this.reference || undefined,
                date_range: this.date_range || undefined,
            }
        },
    },

    async mounted() {
        const { data: form } = await axios.get(`forms/${this.id}`)

        this.form = form

        for (let i = 0; i < form.fields.length; i++) {
            const field = form.fields[i]

            if (!this.isDisplayableType(field)) continue

            this.dynamicColumns.push({ name: field.id, th: field.name })
        }

        this.columns = this.columns.concat(this.dynamicColumns)
        this.columns.push({ name: 'actions', min: true })
    },

    methods: {
        updateCompactView() {
            this.$store.commit('UPDATE_FORM_SUBMITS_TABLE_COMPACT_VIEW', this.compact)
        },

        async exportExcel() {
            if (this.loading) return

            this.loading = true

            try {
                const { data: excel } = await axios.get(`forms/${this.id}/submits/excel`, {
                    params: { ...this.parameters },
                })

                const link = document.createElement('a')

                link.href = excel
                link.target = '_blank'

                document.body.appendChild(link)
                link.click()
            } catch {
                window.toast(this.$t('main.oops'), 'error')
            }

            this.loading = false
        },

        getValueForColumn(column, item) {
            const field = item.form.fields.find(field => field.id === column.name)
            const answer = getAnswerForField(field, item.answers)

            // check if answer is an array (multi-select) 
            if (Array.isArray(answer)) {
                return answer.join(', ')
            }

            return answer
        },

        isDisplayableType(field) {
            if (!field.show_in_platform) return false

            const displayableTypes = ['text', 'single_select', 'checkbox', 'number', 'date']

            return displayableTypes.indexOf(field.type) > -1
        },

        deleteSubmit(item) {
            this.$swal({
                showCancelButton: true,
                title: this.$t('main.are_you_sure'),
                text: this.$t('main.you_wont_be_able_to_revert_this'),
                confirmButtonText: this.$t('main.yes_delete_it'),
                cancelButtonText: this.$t('main.cancel'),
            }).then(result => {
                if (result.value) {
                    axios.delete(`submits/${item.id}`).then(() => this.refresh++)
                }
            })
        },

        async deleteForm(form) {
            await deleteForm(form, this.$swal)

            window.toast(this.$t('i.delete', { i: form.title }))
            this.$router.push({ name: 'forms' })
        },

        async duplicateForm(form) {
            const { data } = await duplicateForm(form, this.$swal)

            window.toast(this.$t('i.create', { i: data.title }))
            this.$router.push({ name: 'forms.edit', params: { id: data.id } })
        },
    },
}
</script>
