<template>
    <div>
        <ContextBar :title="$t('profile.display')"></ContextBar>

        <select class="form-select w-250" v-model="preferColorScheme" @change="updateColorScheme()">
            <option v-for="scheme in colorSchemes" :value="scheme" :key="scheme">
                {{ $t(`profile.display_${scheme}`) }}
            </option>
        </select>

        <h6>{{ $t('profile.general_settings') }}</h6>

        <label class="form-switch">
            <input v-model="preferences.maps" @change="updatePreferences()" type="checkbox" />
            <i class="form-icon"></i>
            <span>{{ $t(`profile.maps`) }}</span>
        </label>

        <label class="form-switch">
            <input v-model="preferences.counters" @change="updatePreferences()" type="checkbox" />
            <i class="form-icon"></i>
            <span>{{ $t(`profile.counters`) }}</span>
        </label>

        <div v-if="preferences.counters">
            <div class="dropdown mt-4 p-2">
                <a tabindex="0" class="btn dropdown-toggle">
                    <i class="fa-regular fa-timer mr-2"></i>
                    <span>{{ $t(`timeline.filter_date_${preferences.start_date}`) }}</span>
                    <i class="fa-solid fa-caret-down ml-2"></i>
                </a>
                <ul class="menu">
                    <li class="menu-item" v-for="date in countersDates" :key="date">
                        <a href="#" @click.prevent="changeCountersDate(date)"
                            :class="{ active: date === preferences.start_date }">
                            {{ $t(`timeline.filter_date_${date}`) }}
                        </a>
                    </li>
                </ul>
            </div>

            <CountersConfig :config="countersConfig"></CountersConfig>
        </div>

        <div class="context-bar flex-start" v-if="countersConfigChanged">
            <button class="btn btn-primary" @click="saveCountersConfig()">{{ $t('main.save') }}</button>
            <button class="btn" @click="cancel()">{{ $t('main.cancel') }}</button>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import CountersConfig from './CountersConfig.vue'

export default {
    components: { CountersConfig },

    data() {
        return {
            colorSchemes: ['light', 'dark'],
            preferColorScheme: this.$store.state.preferColorScheme,

            preferences: this.$store.state.preferences,
            countersConfig: this.$store.state.countersConfig,
            countersConfigCache: JSON.parse(JSON.stringify(this.$store.state.countersConfig)),
            countersDates: ['today', 'last_7_days', 'last_30_days', 'all_time'],
        }
    },

    computed: {
        countersConfigChanged() {
            return JSON.stringify(this.countersConfig) !== JSON.stringify(this.countersConfigCache)
        },
    },

    methods: {
        updateColorScheme() {
            this.$store.dispatch('updateColorScheme', this.preferColorScheme)
            window.toast(this.$t('profile.settings_updated'))
        },

        updatePreferences() {
            this.$store.commit('UPDATE_PREFERENCES', this.preferences)
            axios.post('sync', { ...this.$store.state.preferences })
            window.toast(this.$t('profile.settings_updated'))
        },

        changeCountersDate(date) {
            this.preferences.start_date = date
            this.updatePreferences()
        },

        saveCountersConfig() {
            this.countersConfigCache = JSON.parse(JSON.stringify(this.countersConfig))

            this.$store.commit('UPDATE_COUNTERS_CONFIG', this.countersConfig)
            axios.post('sync', { ...this.$store.state.preferences, counterConfig: this.countersConfig })
            window.toast(this.$t('profile.settings_updated'))
        },

        cancel() {
            this.countersConfig = JSON.parse(JSON.stringify(this.countersConfigCache))
        },
    },
}
</script>
