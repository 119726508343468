<template>
    <Modal :title="$t('files.download_images_as_zip')" @close="back()">
        <Errors :errors="errors" />

        <template #footer>
            <button class="btn btn-primary" :disabled="download" @click="downloadZip()">
                {{ $t('main.download') }}
            </button>
        </template>
    </Modal>
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            errors: null,
            download: false,

            ids: this.$route.params.id ? [this.$route.params.id] : this.$route.query.files,
        }
    },

    methods: {
        back() {
            if (this.$route.params.id) {
                this.$router.push({ name: 'files.show' })
            } else {
                this.$router.push({ name: 'files' })
            }
        },

        downloadZip() {
            this.download = true

            axios.post('files/create_zip_url', { files: this.ids }).then(({ data }) => {
                const link = document.createElement('a')

                link.href = data

                document.body.appendChild(link)
                link.click()

                window.toast(this.$t('files.download_images_as_zip'))

                this.back()
            })
        },
    },
}
</script>
