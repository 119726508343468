<template>
    <form @submit.prevent="updatePassword()">
        <div class="form-group">
            <Errors :errors="errors" />
        </div>

        <div v-if="currentPw" class="form-group" :class="{ 'has-error': v$.password.$error }">
            <input class="d-none" id="emailfield" :value="$store.state.user.email" autocomplete="username" />
            <input type="password" class="form-input" v-model="password" :placeholder="$t('auth.current_password')" />
        </div>

        <NewPasswordInput v-model="new_password" :placeholder="$t('auth.new_password')"></NewPasswordInput>

        <div class="form-group">
            <button type="submit" class="btn btn-primary mr-2" :class="{ loading }">{{ $t('main.save') }}</button>
            <button v-if="cancelBtn" type="button" class="btn" @click="$emit('close')">{{ $t('main.cancel') }}</button>
        </div>
    </form>
</template>

<script>
import axios from 'axios'
import { useVuelidate } from '@vuelidate/core'
import { requiredIf } from '@vuelidate/validators'
import NewPasswordInput from '~/components/htmlform/NewPasswordInput.vue'

export default {
    props: { cancelBtn: Boolean, currentPw: Boolean },

    components: { NewPasswordInput },

    setup: () => ({ v$: useVuelidate() }),

    data() {
        return {
            errors: null,
            loading: false,

            password: '',
            new_password: '',
        }
    },

    validations() {
        return {
            password: { required: requiredIf(this.currentPw) },
        }
    },

    methods: {
        async updatePassword() {
            if (!await this.v$.$validate()) return

            this.errors = null
            this.loading = true

            axios
                .post('user', {
                    password: this.password,
                    new_password: this.new_password,
                })
                .then(() => {
                    this.loading = false
                    window.toast(this.$t('i.update', { i: this.$t('users.password') }))

                    this.$emit('close')
                })
                .catch(({ response }) => {
                    this.loading = false
                    this.errors = response.data
                })
        },
    },
}
</script>
