<template>
    <Modal v-if="!useReportInBrowser" :title="$t('files.create_report')" @close="back()">
        <form @submit.prevent="create()" ref="form">
            <div class="form-group">
                <Errors :errors="errors" />
            </div>

            <div class="form-group">
                <label class="form-label" for="name">{{ $t('main.name') }}</label>
                <div class="input-group">
                    <input class="form-input" v-model="report.name" id="name" required />
                    <select class="form-select text-uppercase" v-model="report.format" style="flex:0" required>
                        <template v-for="format in formats" :key="format.name">
                            <option v-if="canCreateFormat(format.name)" :value="format.name">{{ format.name }}</option>
                        </template>
                    </select>
                    <span class="input-group-addon">
                        <i class="fas fa-fw" :class="currentFormat.icon"></i>
                    </span>
                </div>
            </div>

            <div class="form-group">
                <label class="form-label" for="language">{{ $t('main.language') }}</label>
                <select v-model="report.language" class="form-select" id="language" required>
                    <option v-for="(lang, code) in locales" :key="code" :value="code">{{ lang }}</option>
                </select>
            </div>

            <div class="form-group">
                <label class="form-label" for="layout">{{ $t('reports.layout') }}</label>
                <select v-model="report.template" class="form-select" id="layout" required>
                    <option v-for="template in templates" :key="template.id" :value="template.code">
                        {{ template.name }}
                    </option>
                </select>
            </div>
        </form>

        <template #footer>
            <button @click="$refs.form.requestSubmit()" class="btn btn-primary" :class="{ loading }">
                {{ $tc('reports.create_report', ids.length) }}
            </button>
        </template>
    </Modal>
</template>

<script>
import axios from 'axios'
import { tenantCanCreateDocxExport } from '~/guards/featureGuard.js'

export default {
    data() {
        return {
            useReportInBrowser: this.$store.state.user.properties.use_report_in_browser,
            ids: this.$route.params.id ? [this.$route.params.id] : this.$route.query.files,

            open: true,
            errors: null,
            loading: false,

            templates: [],
            locales: window.config.locales,

            formats: [
                { name: 'pdf', icon: 'fa-file-pdf' },
                { name: 'docx', icon: 'fa-file-word' },
                { name: 'json', icon: 'fa-code' },
            ],

            report: {
                timezone: dayjs().format('Z'),
                format: this.$store.state.lastReportCreatedValues.format || 'pdf',
                template: this.$store.state.lastReportCreatedValues.template || '1',
                language: this.$store.state.lastReportCreatedValues.language || this.$store.state.language,
                name: `${this.$store.state.user.name} ${new Date().toISOString().substring(0, 10)}`,
            },
        }
    },

    computed: {
        currentFormat() {
            return this.formats.find(format => format.name === this.report.format) || {}
        },
    },

    async mounted() {
        if (this.useReportInBrowser) {
            window.open(`/files/${this.ids.join(',')}/print`, '_blank')
            return this.back()
        }

        const { data: templates } = await axios.get('report_templates')
        this.templates = templates
    },

    methods: {
        back() {
            this.$route.params.id ?
                this.$router.push({ name: 'files.show' }) :
                this.$router.push({ name: 'files' })
        },

        canCreateFormat(format) {
            if (format === 'json') return this.$store.state.hasAllAbilities
            if (format === 'docx') return tenantCanCreateDocxExport(this.$store.state.user.tenant_id)

            return true
        },

        create() {
            this.errors = null
            this.loading = true

            axios
                .post('reports', { ...this.report, files: this.ids })
                .then(() => {
                    this.$store.commit('SET_LAST_REPORT_CREATED_VALUES', this.report)

                    window.toast(this.$t('reports.being_generated'))

                    this.$gtag.event('create_report')

                    this.$router.push({ name: 'reports' })
                })
                .catch(({ response }) => {
                    this.errors = response.data
                    this.loading = false
                })
        },
    },
}
</script>
