<template>
    <SidePanel ref="panel" :title="$t('main.user')" @close="$router.push({ name: 'settings.users' })">
        <Errors class="form-group" :errors="errors" />

        <fieldset :disabled="!canEdit || user.deleted_at">
            <div class="form-group">
                <label class="form-label" for="name">{{ $t('users.name') }}</label>
                <input v-model="user.name" class="form-input" id="name" />
            </div>

            <div class="form-group">
                <label class="form-label" for="location">{{ $t('main.location') }}</label>
                <select v-model="user.location_id" class="form-select" id="location">
                    <option v-for="location in locations" :key="location.id" :value="location.id">
                        {{ location.location }}
                    </option>
                </select>
            </div>

            <div class="form-group">
                <label class="form-label" for="email">{{ $t('users.email') }}</label>
                <input v-model="user.email" type="email" class="form-input" id="email" :readonly="!canEditEmail" />
            </div>

            <div class="form-group">
                <button class="btn" @click="resetPassword()">{{ $t('main.reset') }}</button>
            </div>

            <div class="form-group">
                <label class="form-label" for="role">{{ $t('users.role') }}</label>
                <select v-model="user.role" class="form-select" id="role">
                    <option v-for="role in roles" :key="role" :value="role">{{ $t(`user_roles.${role}`) }}</option>
                </select>
            </div>

            <details class="accordion mb-4">
                <summary class="accordion-header">
                    <i class="fas fa-chevron-right mr-2"></i>
                    <span>{{ $t('users.has_permission_for_locations') }}</span>
                </summary>
                <div class="accordion-body">
                    <div class="text-bold my-1">
                        <label class="form-checkbox d-inline">
                            <input type="checkbox" v-model="allLocationsSelected" @change="selectAllLocations()" />
                            <i class="form-icon"></i>
                            <span>{{ $t('main.select_all') }}</span>
                        </label>
                    </div>

                    <div style="max-height: 200px; overflow: auto">
                        <div v-for="location in user.locations" :key="location.id">
                            <label class="form-checkbox d-inline" v-if="user.location_id == location.id">
                                <input type="checkbox" :checked="keepChecked" disabled />
                                <i class="form-icon"></i>
                                <span>{{ location.location }}</span>
                            </label>
                            <label class="form-checkbox d-inline" v-else>
                                <input type="checkbox" v-model="location.selected" />
                                <i class="form-icon"></i>
                                <span>{{ location.location }}</span>
                            </label>
                        </div>
                    </div>
                </div>
            </details>

            <div class="flex-space" v-show="!user.deleted_at">
                <div>
                    <VisibleOnChangeButton class="mr-2" ref="observer" :data="user" @click="updateUser()">
                    </VisibleOnChangeButton>

                    <button class="btn" v-if="user.activated === false" @click="resendInvitationEmail()"
                        :class="{ loading: sendingInvitationEmail }">
                        {{ $t('auth.resend_invitation_email') }}
                    </button>
                </div>

                <button class="btn btn-error" @click="deleteUser()">{{ $t('main.delete') }}</button>
            </div>
        </fieldset>

        <div class="flex-space" v-if="user.deleted_at">
            <button class="btn" @click="restoreUser()">{{ $t('users.restore') }}</button>
            <button class="btn btn-error" @click="permanentDelete()">{{ $t('users.permanent_delete') }}</button>
        </div>
    </SidePanel>
</template>

<script>
import axios from 'axios'
import SidePanel from '~/components/SidePanel.vue'

export default {
    props: ['locations'],

    components: { SidePanel },

    beforeRouteLeave(to, from, next) {
        this.$refs.observer.beforeRouteLeave(to, from, next)
    },

    data() {
        return {
            errors: null,

            user: {},
            allLocationsSelected: false,

            id: this.$route.params.id,
            roles: ['ReadOnly', 'User', 'Manager'],
            canEdit: this.$store.getters.can('Update users'),

            keepChecked: true,

            sendingInvitationEmail: false,

            canEditEmail: false,
        }
    },

    computed: {
        selectedLocations() {
            return this.user.locations.filter(location => location.selected)
        },
    },

    mounted() {
        axios.get(`users/${this.id}`).then(({ data }) => {
            if (this.$store.state.user.role === 'Manager') {
                this.canEdit = this.$store.state.user.location_id === data.location_id
            } else {
                this.roles.push('SuperAdmin')
            }

            if (
                this.$store.state.hasAllAbilities ||
                this.$store.state.user.role === 'PlatformManager' ||
                this.$store.state.user.role === 'LicenseAdmin'
            ) {
                this.roles.push('LicenseAdmin')
            }
            if (this.$store.state.user.properties.use_kiosks) {
                this.roles.push('Kiosk')
            }

            const role = (data.roles[0] || {}).name
            data.role = role

            if (this.roles.indexOf(role) === -1) {
                this.roles.push(role)
            }

            this.user = data
            this.allLocationsSelected = this.selectedLocations.length === this.user.locations.length
        })

        if (this.$store.state.user.role === 'Developer') {
            this.canEditEmail = true
        }
    },

    methods: {
        updateUser() {
            this.errors = null

            axios
                .patch(`users/${this.id}`, {
                    ...this.user,
                    locations: this.selectedLocations,
                })
                .then(() => {
                    this.$emit('refresh')
                    window.toast(this.$t('i.update', { i: this.user.name }))
                    this.$refs.observer.initialize()
                })
                .catch(({ response }) => {
                    this.errors = response.data
                })
        },

        restoreUser() {
            this.$swal({
                showCancelButton: true,
                confirmButtonColor: '#e89543',
                title: this.$t('main.are_you_sure'),
                confirmButtonText: this.$t('users.restore'),
                cancelButtonText: this.$t('main.cancel'),
            }).then(result => {
                if (result.value) {
                    axios.post(`users/${this.id}/restore`).then(() => {
                        this.$emit('refresh')
                        this.user.deleted_at = null
                        this.$refs.observer.initialize()
                    })
                }
            })
        },

        resendInvitationEmail() {
            this.sendingInvitationEmail = true

            axios.post(`users/${this.id}/resend_invitation`).then(() => {
                this.sendingInvitationEmail = false
                window.toast(this.$t('auth.sent_invitation_email'))
            })
        },

        selectAllLocations() {
            this.user.locations.forEach(u => (u.selected = this.allLocationsSelected))
        },

        deleteUser() {
            this.$swal({
                showCancelButton: true,
                showLoaderOnConfirm: true,
                title: this.$t('main.are_you_sure'),
                confirmButtonText: this.$t('main.yes_delete_it'),
                cancelButtonText: this.$t('main.cancel'),
                preConfirm: () => {
                    return axios
                        .delete(`users/${this.id}`)
                        .then(() => this.user)
                        .catch(({ response }) => {
                            return this.$swal.showValidationMessage(response.data.error)
                        })
                },
            }).then(({ value }) => {
                if (!value) return

                this.$emit('refresh')
                this.$router.push({ name: 'settings.users' })
                window.toast(this.$t('i.delete', { i: value.name }))
            })
        },

        permanentDelete() {
            this.$swal({
                showCancelButton: true,
                title: this.$t('main.are_you_sure'),
                text: this.$t('main.you_wont_be_able_to_revert_this'),
                confirmButtonText: this.$t('main.yes_delete_it'),
                cancelButtonText: this.$t('main.cancel'),
            }).then(result => {
                if (result.value) {
                    axios.post(`users/${this.id}/permanent_delete`).then(() => {
                        this.$emit('refresh')
                        this.$router.push({ name: 'settings.users' })
                        window.toast(this.$t('i.delete', { i: this.user.name }))
                    })
                }
            })
        },

        resetPassword() {
            const generatePassword = (() => {
                const charset = 'ABCDEFGHJKMNPQRSTUVWXYZ23456789'
                let pw = ''

                for (var i = 0, n = charset.length; i < 6; ++i) {
                    pw += charset.charAt(Math.floor(Math.random() * n))
                }

                return pw
            })()

            this.$swal({
                showCancelButton: true,
                title: this.$t('main.are_you_sure'),
                text: 'This will create a temporary password. The user will be asked to change their password upon logging in',
                confirmButtonText: this.$t('main.reset'),
                showLoaderOnConfirm: true,
                cancelButtonText: this.$t('main.cancel'),

                preConfirm: () => {
                    return axios.post(`users/${this.id}/reset_password`, { password: generatePassword })
                },
            }).then(result => {
                if (result.value) {
                    return this.$swal({
                        title: generatePassword,
                        text: "Keep this password in a safe place and never share it publicly. You won't be able to access it after closing this message.",
                    })
                }
            })
        },
    },
}
</script>
