<template>
    <div>
        <ContextBar :title="$t('share.incoming')">
            <DebounceInput v-model="search" :placeholder="$t('files.search_on_reference')" clearable searchIcon />
        </ContextBar>

        <DataTable endpoint="sharing" :columns="columns" :parameters="parameters" sort="created_at" :key="refresh" hasFilters>
            <template #context-left v-if="$store.getters.can('Read outgoing file shares')">
                <router-link tag="button" class="btn" :to="{ name: 'sharing' }">{{ $t('share.incoming') }}</router-link>
                <router-link tag="button" class="btn" :to="{ name: 'sharing.out' }">
                    {{ $t('share.outgoing') }}
                </router-link>
            </template>
            <template #context-right>
                <DatePicker v-model="date_range" :placeholder="$t('files.created_between')" range multi-calendars>
                </DatePicker>
                <select class="form-select" v-model="shared_with">
                    <option :value="undefined">{{ $t('share.with_company') }}</option>
                    <option value="location">{{ $t('share.with_location') }}</option>
                    <option value="me" selected>{{ $t('share.with_me') }}</option>
                </select>
                <select class="form-select" v-model="from_company">
                    <option :value="undefined">{{ $t('share.from_any_company') }}</option>

                    <option v-for="company in companies_that_shared_with_me" :key="company.tenant_id"
                        :value="company.tenant_id">
                        {{ company.company }}
                    </option>
                </select>
            </template>

            <template #column(thumb)="{ item }">
                <div v-if="item.recent_upload" class="postzegel c-hand">
                    <img :src="item.recent_upload.image_thumb_sm" alt width="100" />
                </div>
            </template>
            <template #column(scan_code)="{ item }">
                <FileReference :id="item.id" :reference="item.scan_code" :closed_at="item.closed_at"
                    route="sharing.show"></FileReference>
                <div>{{ item.customer_reference }}</div>
            </template>
            <template #column(created_at)="{ value }">
                <DateTime :date="value"></DateTime>
            </template>
            <template #column(uploads_count)="{ value }">
                <div class="chip chip-lg">
                    <i class="fa-regular fa-circle-arrow-up"></i>
                    <span class="ml-2">{{ value }}</span>
                </div>
            </template>
            <template #column(damages_count)="{ value }">
                <div class="chip chip-lg" :class="{ 'bg-error': value }">
                    <i class="fa-regular fa-circle-exclamation"></i>
                    <span class="ml-2">{{ value }}</span>
                </div>
            </template>
            <template #column(fields)="{ item }">
                <Fields :fields="item.fields" indexPage></Fields>
            </template>
            <template #column(company)="{ value }">{{ value.company }}</template>
        </DataTable>
    </div>
</template>

<script>
import axios from 'axios'
import DataTable from '~/components/DataTable.vue'
import FileReference from '~/components/FileReference.vue'
import Fields from '~/components/files/FileFields.vue'

export default {
    components: {
        Fields,
        DataTable,
        FileReference,
    },

    data() {
        return {
            refresh: 0,

            search: this.$route.query.search,
            shared_with: this.$route.query.shared_with,
            from_company: this.$route.query.from_company,
            date_range: this.$route.query.date_range,

            columns: [
                { name: 'thumb' },
                { name: 'scan_code', th: 'files.reference', sortable: true, class: 'mw-250' },
                { name: 'created_at', th: 'main.created', sortable: true },
                { name: 'uploads_count', th: 'files.uploads', sortable: true },
                { name: 'damages_count', th: 'files.damages', sortable: true },
                { name: 'fields', th: 'main.fields' },
                { name: 'company', th: 'files.owned_by', class: 'mw-250' },
            ],

            companies_that_shared_with_me: [],
        }
    },

    computed: {
        parameters() {
            return {
                search: this.search || undefined,
                date_range: this.date_range || undefined,
                shared_with: this.shared_with || undefined,
                from_company: this.from_company || undefined,
            }
        },
    },

    async mounted() {
        const { data: companies } = await axios.get('sharing_companies_that_shared_with_me')

        this.companies_that_shared_with_me = companies
    },
}
</script>
