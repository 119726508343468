<template>
    <form @submit.prevent="save()" :class="{ ghost: !tenant.id }">
        <ContextBar :title="$t('main.general')"></ContextBar>

        <Errors :errors="errors" />

        <div class="flex-space">
            <div :class="{ 'text-gray': tenant.close_file_after_days === '' }">
                {{ $t('settings.auto_close_after_days_of_inactivity', { days: tenant.close_file_after_days }) }}
            </div>

            <input
                min="0"
                type="number"
                v-model.number="tenant.close_file_after_days"
                class="form-input mw-250"
                placeholder="∞"
            />
        </div>

        <template v-if="!$store.state.user.properties.use_report_in_browser">
            <div class="divider"></div>

            <div class="flex-space">
                <div :class="{ 'text-gray': tenant.auto_delete_reports_after_days === '' }">
                    {{ $t('settings.auto_delete_reports_after_days', { days: tenant.auto_delete_reports_after_days }) }}
                </div>

                <input
                    min="0"
                    type="number"
                    v-model.number="tenant.auto_delete_reports_after_days"
                    class="form-input mw-250"
                    placeholder="∞"
                />
            </div>
        </template>

        <div class="divider"></div>

        <div class="flex-space">
            <label class="form-label mr-2" for="inbox_rule">{{ $t('inbox_rules.share_with') }}</label>

            <div>
                <select v-model="tenant.inbox_rule" class="form-select" id="inbox_rule">
                    <option v-for="rule in inbox_rules" :value="rule" :key="rule">
                        {{ $t(`inbox_rules.${rule}`) }}
                    </option>
                </select>
            </div>
        </div>
        <div class="divider"></div>

        <div class="flex-space">
            <label class="form-label mr-2" for="logo">{{ $t('share.logo') }}</label>

            <div v-if="tenant.logo_url" class="flex-space">
                <img style="width: 100px" :src="tenant.logo_url" alt />
                <button type="button" class="btn btn-error ml-2" @click="deleteLogo()">
                    {{ $t('share.logo_delete') }}
                </button>
            </div>

            <div class="input-group" v-else>
                <input type="file" ref="logo" id="logo" class="form-input" accept="image/*" />

                <button
                    type="button"
                    class="btn btn-primary input-group-btn"
                    :class="{ loading }"
                    @click="uploadLogo()"
                >
                    Upload
                </button>
            </div>
        </div>
        <div class="divider"></div>

        <div class="flex-space" style="flex-wrap: wrap">
            <label class="form-label">{{ $t('settings.uploads_of_type_automatically_available_in_share') }}</label>

            <div class="flex-space">
                <label class="form-checkbox d-inline mr-2">
                    <input type="checkbox" v-model="tenant.new_snaps_included_in_share" />
                    <i class="form-icon"></i>
                    <span>{{ $t('files.filter_snap') }}</span>
                </label>

                <label class="form-checkbox d-inline mr-2">
                    <input type="checkbox" v-model="tenant.new_snaps_with_damage_included_in_share" />
                    <i class="form-icon"></i>
                    <span>{{ $t('files.filter_snap_with_damage') }}</span>
                </label>

                <label class="form-checkbox d-inline">
                    <input type="checkbox" v-model="tenant.new_documents_included_in_share" />
                    <i class="form-icon"></i>
                    <span>{{ $t('files.filter_document') }}</span>
                </label>
            </div>
        </div>
        <div class="divider"></div>

        <template v-if="$store.state.jwt.microsoft">
            <div class="flex-space">
                <label class="form-label mr-2" for="login_with_sso">{{ $t('settings.login_with_sso') }}</label>

                <div>
                    <label class="form-checkbox d-inline">
                        <input
                            type="checkbox"
                            id="login_with_sso"
                            v-model="tenant.login_enforce_sso"
                            @change="confirmEnforceLoginSSO()"
                        />
                        <i class="form-icon"></i>
                        <span></span>
                    </label>
                </div>
            </div>
            <div class="divider"></div>
        </template>

        <div class="flex-space my-4">
            <VisibleOnChangeButton ref="observer" :data="tenant"></VisibleOnChangeButton>
        </div>
    </form>
</template>

<script>
import axios from 'axios'

export default {
    beforeRouteLeave(to, from, next) {
        this.$refs.observer.beforeRouteLeave(to, from, next)
    },

    data() {
        return {
            tenant: {},

            errors: null,
            loading: false,

            inbox_rules: ['USER', 'LOCATION', 'COMPANY'],
        }
    },

    async mounted() {
        const { data: tenant } = await axios.get('tenant')

        if (tenant.close_file_after_days === null) {
            tenant.close_file_after_days = ''
        }

        if (tenant.auto_delete_reports_after_days === null) {
            tenant.auto_delete_reports_after_days = ''
        }

        this.tenant = tenant
    },

    methods: {
        save() {
            axios
                .post('tenant', this.tenant)
                .then(() => {
                    this.$refs.observer.initialize()
                    this.$store.dispatch('fetchUser')
                    window.toast(this.$t('i.update', { i: this.$t('main.settings') }))
                })
                .catch(({ response }) => {
                    this.errors = response.data
                })
        },

        deleteLogo() {
            this.$swal({
                showCancelButton: true,
                title: this.$t('main.are_you_sure'),
                text: this.$t('main.you_wont_be_able_to_revert_this'),
                confirmButtonText: this.$t('main.yes_delete_it'),
                cancelButtonText: this.$t('main.cancel'),
            }).then(result => {
                if (result.value) {
                    axios.delete('tenant/logo').then(() => {
                        this.tenant.logo_url = null
                        this.$refs.observer.initialize()
                    })
                }
            })
        },

        uploadLogo() {
            const files = this.$refs.logo.files

            if (!files.length) {
                return
            }

            this.loading = true

            let data = new FormData()

            data.append('uploadfile', files[0])

            axios
                .post('tenant/logo', data, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                })
                .then(({ data }) => {
                    this.loading = false

                    setTimeout(() => {
                        this.tenant.logo_url = data.logo_url
                        this.$refs.observer.initialize()
                    }, 100)
                })
                .catch(({ response }) => {
                    this.loading = false
                    this.errors = response.data
                })
        },

        confirmEnforceLoginSSO() {
            if (this.tenant.login_enforce_sso) {
                this.$swal({
                    confirmButtonColor: '#0092c8',
                    title: this.$t('main.are_you_sure'),
                    text: this.$t('settings.login_with_sso_warning'),
                })
            }
        },
    },
}
</script>
