<template>
    <div class="page-not-found text-center mt-4">
        <img src="/images/404.png" />
        <h2 class="mt-4">{{ $t('main.page_not_found') }}</h2>
        <strong>
            <div>{{ $t('main.not_found') }}</div>
            <router-link to="/" exact>{{ $t('main.go_back_to_the_homepage') }}</router-link>
        </strong>
    </div>
</template>

<script>
export default {
    mounted() {
        document.title = '404 | Cargosnap'
    },
}
</script>
