<template>
    <div>
        <ContextBar :title="$t('profile.profile')"></ContextBar>

        <div class="profile-wrapper flex-space my-2">
            <Avatar :initials="$store.state.user.name" class="mr-2"></Avatar>
            <div class="ml-2" style="flex: 1">
                <div class="text-bold">{{ $store.state.user.name }}</div>
                <div>{{ $store.state.user.email }}</div>
            </div>
        </div>

        <div class="my-4">
            <div v-if="editPassword" class="profile-wrapper">
                <PasswordChange @close="editPassword = false" cancelBtn currentPw></PasswordChange>
            </div>
            <a v-else href="#" @click.prevent="editPassword = true">{{ $t('profile.change_password') }}</a>
        </div>

        <h6>{{ $t('main.language') }}</h6>
        <LanguageDropdown save></LanguageDropdown>

        <template v-if="!isFreeAccount">
            <h6>{{ $t('profile.location_filters') }}</h6>

            <div>
                <label class="form-switch text-bold">
                    <input :checked="allLocationsSelected" @change="toggleSelectAllLocations()" type="checkbox" />
                    <i class="form-icon"></i>
                    <span>{{ $t('fields.all_locations') }}</span>
                </label>

                <label v-for="location in locationFilters" :key="location.id" class="form-switch">
                    <input v-model="location.selected" type="checkbox" />
                    <i class="form-icon"></i>
                    <span>{{ location.location }}</span>
                </label>

                <div class="context-bar flex-start" :class="{ ghost: !locationFiltersChanged }">
                    <button class="btn btn-primary" @click="save()">{{ $t('main.save') }}</button>
                    <button class="btn" @click="cancel()">{{ $t('main.cancel') }}</button>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import Avatar from '~/components/Avatar.vue'
import PasswordChange from '~/components/PasswordChange.vue'

export default {
    components: { Avatar, PasswordChange },

    data: () => ({ editPassword: false, locationFilters: [], locationFiltersCache: [] }),

    async mounted() {
        const { data: settings } = await axios.get('settings')

        this.locationFilters = settings.location_filters
        this.locationFiltersCache = JSON.parse(JSON.stringify(this.locationFilters))
    },

    computed: {
        ...mapGetters(['isFreeAccount']),

        selectedLocations() {
            return this.locationFilters.filter(location => location.selected)
        },

        allLocationsSelected() {
            return this.locationFilters.length === this.selectedLocations.length
        },

        locationFiltersChanged() {
            return JSON.stringify(this.locationFilters) !== JSON.stringify(this.locationFiltersCache)
        },
    },

    methods: {
        toggleSelectAllLocations() {
            const toggle = !this.allLocationsSelected

            this.locationFilters.forEach(location => (location.selected = toggle))
        },

        async save() {
            const selected = this.locationFilters.filter(location => location.selected === true)

            await axios.post('location_filters', selected)

            window.toast(this.$t('profile.settings_updated'))

            this.locationFiltersCache = JSON.parse(JSON.stringify(this.locationFilters))
        },

        cancel() {
            this.locationFilters = JSON.parse(JSON.stringify(this.locationFiltersCache))
        },
    },
}
</script>
