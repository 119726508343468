<template>
    <Modal :title="$t(`timeline.${item.upload_type}`)" @close="$emit('close')">
        <form @submit.prevent="update()">
            <img width="150" :src="item.image_thumb_sm" class="img-responsive p-centered" />

            <div class="form-group">
                <label class="form-label" for="comment">{{ $t('timeline.comment') }}</label>
                <input id="comment" class="form-input" :placeholder="$t('timeline.comment')" v-model="item.comment" />
            </div>

            <div class="form-group" v-if="item.upload_type === 'snap'">
                <label class="form-label" for="damage_type">{{ $t('timeline.damage_type') }}</label>
                <select v-model="item.damage_type_id" id="damage_type" class="form-select">
                    <option :value="null">{{ $t('files.no_damage') }}</option>
                    <option v-for="t in damage_types" :key="t.id" :value="t.id">{{ t.value }}</option>
                </select>
            </div>

            <div class="form-group" v-if="item.upload_type === 'document'">
                <label class="form-label" for="document_type">{{ $t('timeline.document_type') }}</label>
                <select v-model="item.document_type_id" id="document_type" class="form-select">
                    <option :value="null">{{ $t('files.no_document') }}</option>
                    <option v-for="t in document_types" :key="t.id" :value="t.id">{{ t.value }}</option>
                </select>
            </div>

            <MoveToFile v-if="canMoveBetweenFiles" :item="item"></MoveToFile>

            <input type="submit" hidden />
        </form>

        <template #footer>
            <button @click="update()" :class="{ loading }" class="btn btn-primary">{{ $t('main.save') }}</button>
        </template>
    </Modal>
</template>

<script>
import axios from 'axios'
import MoveToFile from '~/components/timeline/MoveToFile.vue'

export default {
    props: ['item'],

    components: { MoveToFile },

    data() {
        return {
            loading: false,

            damage_types: [],
            document_types: [],
            canMoveBetweenFiles: this.$store.getters.can('Update snaps'),
        }
    },

    mounted() {
        const location_id = this.item.location_id

        if (this.item.upload_type === 'snap') {
            axios.get(`damage_types/${location_id}`).then(({ data }) => {
                data.forEach(type => (type.value = this.textFromType(type)))
                this.damage_types = data
            })
        }

        if (this.item.upload_type === 'document') {
            axios.get(`document_types/${location_id}`).then(({ data }) => {
                data.forEach(type => (type.value = this.textFromType(type)))
                this.document_types = data
            })
        }
    },

    watch: {
        'item.damage_type_id'(id) {
            if (id) {
                this.item.has_damage = true
                const type = this.damage_types.find(type => type.id === id) || {}
                this.item.damage_type_desc = type.value
            } else if (id === null) {
                this.item.has_damage = false
                this.item.damage_type_desc = null
            }
        },

        'item.document_type_id'(id) {
            if (id) {
                const type = this.document_types.find(type => type.id === id) || {}
                this.item.document_type_desc = type.value
            } else if (id === null) {
                this.item.document_type_desc = null
            }
        },
    },

    methods: {
        async update() {
            this.loading = true

            const { data } = await axios.patch(`uploads/${this.item.id}`, this.item)

            this.loading = false
            window.toast(this.$t('i.update', { i: 'Snap' }))

            const moveToFile = this.item.moveToFile

            if (moveToFile) {
                await axios.post(`uploads/${this.item.id}/move/${moveToFile.id}`)
                this.$router.push({ name: 'files.show', params: { id: moveToFile.id } })
            }

            this.$emit('update', data)
        },

        textFromType(type) {
            const userLang = this.$store.state.language
            const typeLanguage = type.languages.find(l => l.lang === userLang) || type.languages.find(l => l.lang === 'en') || {}

            return typeLanguage.description
        },
    },
}
</script>
