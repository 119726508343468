<template>
    <div>
        <div class="flex-space">
            <div class="text-large text-bold">{{ $t('issues.linked_files') }}</div>
            <SearchFileDropdown @selectFile="createAssociation" :selectedFiles="selectedFiles" class="dropdown-right">
                <i class="fa-solid fa-plus fa-fw"></i>
            </SearchFileDropdown>
        </div>

        <div v-for="association in associations" class="btn-group mr-2 mt-2">
            <router-link class="btn" target="_blank"
                :to="{ name: 'files.show', params: { id: association.association_object.id } }">
                <div class="text-clamped">{{ association.association_object.scan_code }}</div>
            </router-link>
            <button class="btn" @click="removeAssociation(association)">
                <i class="fa-solid fa-times"></i>
            </button>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import SearchFileDropdown from '../SearchFileDropdown.vue'

export default {
    props: { associations: Array },
    components: { SearchFileDropdown },

    data() {
        return {
            issue_number: this.$route.params.issue_number,
        }
    },

    computed: {
        selectedFiles() {
            return this.associations.map(association => association.association_object.id)
        }
    },

    methods: {
        async createAssociation(file) {
            const exists = this.associations.find(a => a.association_object.id === file.id)

            if (exists) return await this.removeAssociation(exists)

            const association = { associable_type: 'File', associable_id: file.id, association_object: file }
            this.associations.push(association)

            try {
                const { data } = await axios.post(`issues/${this.issue_number}/associations`, association)
                this.associations[this.associations.indexOf(association)] = data
            } catch { }

            this.$emit('update')
        },

        async removeAssociation(association) {
            if (!association.id) return

            this.associations.splice(this.associations.indexOf(association), 1)
            await axios.patch(`issues/${this.issue_number}/associations/${association.id}`)
            this.$emit('update')
        },
    }
}
</script>