<template>
    <form @submit.prevent="submit()" :class="{ ghost: !ready }">
        <div class="form-group">
            <h1>{{ $t('auth.login_to_your_cargosnap_account') }}</h1>
        </div>

        <div class="form-group">
            <router-link :to="{ name: 'register' }">
                {{ $t('auth.dont_have_an_account_click_here_to_register') }}
            </router-link>
        </div>

        <div class="form-group">
            <Errors :errors="errors" />
        </div>

        <div class="form-group" :class="{ 'has-error': v$.email.$error }">
            <label class="form-label" for="email">{{ $t('users.email') }}</label>
            <input type="email" v-model="email" id="email" class="form-input" autocomplete="email" />
        </div>

        <div class="form-group" :class="{ 'has-error': v$.password.$error }">
            <label class="form-label" for="password">{{ $t('users.password') }}</label>
            <input v-model="password" id="password" type="password" class="form-input"
                autocomplete="current-password" />
        </div>

        <div class="form-group">
            <button type="submit" class="btn btn-primary my-2" :class="{ loading }" :disabled="!email">
                <span>{{ $t('auth.login') }}</span>
            </button>
        </div>

        <div class="form-group">
            <router-link class :to="{ name: 'password.request' }">{{ $t('auth.forgot_password') }}</router-link>
        </div>

        <div class="divider"></div>

        <div class="my-4">
            <img src="/images/microsoft-signin.svg" class="c-hand" @click="azureSignIn()" alt="Microsoft" />
        </div>
    </form>
</template>

<script>
import axios from 'axios'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import azureADAuth from '~/plugins/azureADAuth.js'

export default {
    setup: () => ({ v$: useVuelidate() }),

    data() {
        return {
            ready: true,
            errors: null,
            loading: false,

            email: this.$route.query.email,
            password: '',
        }
    },

    validations() {
        return {
            email: { required },
            password: { required },
        }
    },

    mounted() {
        /**
         * Microsoft SSO
         */
        azureADAuth.initialize()
        const requestInfo = azureADAuth.getRequestInfo()

        if (requestInfo.valid) {
            this.ready = false

            axios
                .post('auth/login_with_microsoft', requestInfo.parameters)
                .then(this.login)
                .catch(({ response }) => {
                    this.ready = true
                    this.loading = false
                    this.errors = response.data
                })
        }
    },

    methods: {
        async submit() {
            if (!await this.v$.$validate()) return

            this.errors = null
            this.loading = true

            axios
                .post('auth/login', {
                    email: this.email,
                    password: this.password,
                })
                .then(this.login)
                .catch(({ response }) => {
                    this.loading = false
                    this.errors = response.data
                })
        },

        azureSignIn() {
            azureADAuth.signIn()
        },

        login({ data: jwt }) {
            this.$store.dispatch('startSession', jwt)
            this.$router.push(this.$store.state.pathAfterLogin || '/')
        },
    },
}
</script>
