<template>
    <Modal @close="$router.push({ name: 'files.show' })">
        <ul class="tab">
            <li class="tab-item">
                <router-link :to="{ name: 'files.share.create' }" exact>{{ $t('files.share') }}</router-link>
            </li>
            <li class="tab-item">
                <router-link class="badge" :to="{ name: 'files.share.active' }" :data-badge="file.shares_count">
                    {{ $t('main.shares') }}
                </router-link>
            </li>
        </ul>

        <router-view v-slot="{ Component }" :file="file" :shares="shares.data" :lists="lists.data">
            <keep-alive>
                <component :is="Component" />
            </keep-alive>
        </router-view>
    </Modal>
</template>

<script>
import axios from 'axios'

export default {
    props: ['file'],

    data() {
        return {
            routeName: 'files.share.index',
            id: this.$route.params.id,

            shares: { data: [] },
            lists: { data: [] },
        }
    },

    async mounted() {
        await this.fetchContactLists()
        await this.fetchActiveShares()
    },

    methods: {
        async fetchContactLists() {
            const { data } = await axios.get('distribution_lists', { params: { limit: 1000 } })

            this.lists = data
        },

        async fetchActiveShares() {
            const { data } = await axios.get(`files/${this.id}/shares`, {
                params: { limit: 200 },
            })

            this.shares = data
        },
    },
}
</script>
