<template>
    <div>
        <ContextBar :title="$t('main.companies')">
            <DebounceInput v-model="search" clearable searchIcon />
        </ContextBar>

        <div class="flex-space">
            <div style="flex: 1">
                <div class="d-flex">
                    <div v-for="status in statuses" :key="status.name" class="mr-2">
                        <label class="form-checkbox">
                            <input type="checkbox" v-model="status.checked" />
                            <i class="form-icon"></i>
                            <span>{{ status.title }}</span>
                        </label>
                    </div>
                </div>
                <div class="d-flex">
                    <div v-for="region in regions" :key="region.name" class="mr-2">
                        <label class="form-checkbox">
                            <input type="checkbox" v-model="region.checked" />
                            <i class="form-icon"></i>
                            <span>{{ region.title }}</span>
                        </label>
                    </div>
                </div>
            </div>
        </div>

        <DataTable v-if="regions.length" endpoint="admin/tenants" :columns="columns" :parameters="parameters"
            :ignoreUrlParameters="['regions', 'statuses']" :key="refresh">
            <template #column(id)="{ value }">{{ value }}</template>
            <template #column(company)="{ item }">
                <router-link :to="{ name: 'companies.show', params: { id: item.id } }" class="table-link">
                    {{ item.company }}
                </router-link>
            </template>
            <template #column(domain)="{ item }">
                <a :class="{ ghost: !item.crm_id }" target="_blank"
                    :href="`https://app-eu1.hubspot.com/contacts/25965942/company/${item.crm_id}`">
                    <i class="fab fa-hubspot"></i>
                </a>
                <a class="mx-2" :href="'https://' + item.domain" target="_blank">
                    <i class="fas fa-external-link-alt"></i>
                </a>
                <span>{{ item.domain }}</span>
            </template>
            <template #column(status)="{ item }">
                <span class="text-capitalize mr-2">{{ item.status }}</span>
                <div v-if="item.trial_expired" class="label text-tiny">Trial expired</div>
                <span v-if="item.abowire_subscription_id" class="tooltip tooltip-right"
                    data-tooltip="Customer has a paid subscription">
                    <i class="fas fa-check text-gray"></i>
                </span>
            </template>
            <template #column(region)="{ value }">
                <span class="text-capitalize">{{ value }}</span>
            </template>
            <template #column(created_at)="{ value }">
                <DateTime :date="value"></DateTime>
            </template>
            <template #column(statistics)="{ item }">
                <router-link class="btn" :to="{ name: 'companies.stats', params: { id: item.id } }">
                    <i class="fa-solid fa-chart-simple"></i>
                </router-link>
            </template>
        </DataTable>

        <router-view :regions="regions" :statuses="statuses" @refresh="refresh++"></router-view>
    </div>
</template>

<script>
import axios from 'axios'
import DataTable from '~/components/DataTable.vue'

export default {
    components: { DataTable },

    data() {
        return {
            refresh: 0,
            search: this.$route.query.name,

            columns: [
                { name: 'id', th: 'ID', sortable: true },
                { name: 'company', th: 'Name', sortable: true },
                { name: 'domain', th: 'Domain', sortable: true },
                { name: 'status', th: 'Status', sortable: true },
                { name: 'region', th: 'Region', sortable: true },
                { name: 'created_at', th: 'Created', sortable: true },
                { name: 'statistics', th: 'Statistics' },
            ],

            regions: [],

            statuses: [
                { name: 'standard', title: 'Standard', selectable: false },
                { name: 'enterprise', title: 'Enterprise', selectable: false },
                { name: 'trial', title: 'Trial', selectable: true },
                { name: 'free', title: 'Free', selectable: true },
                { name: 'deleted', title: 'Deleted', selectable: true },
                { name: 'sandbox', title: 'Sandbox', selectable: true },
            ],

            regions_checked: this.$store.state.companyRegionsChecked,
            statuses_checked: this.$store.state.companyStatusesChecked,
        }
    },

    computed: {
        parameters() {
            return {
                name: this.search || undefined,
                regions: this.regions_checked || undefined,
                statuses: this.statuses_checked || undefined,
            }
        },
    },

    async mounted() {
        const { data: regions } = await axios.get('/admin/regions')

        this.regions = regions

        this.regions.forEach(i => i.checked = this.regions_checked.includes(i.name))
        this.statuses.forEach(i => i.checked = this.statuses_checked.includes(i.name))
    },

    watch: {
        regions: {
            deep: true,
            handler(regions) {
                this.regions_checked = regions.filter(region => region.checked).map(region => region.name)
                this.$store.commit('UPDATE_COMPANIES_REGIONS_CHECKED', this.regions_checked)
            },
        },
        statuses: {
            deep: true,
            handler(statuses) {
                this.statuses_checked = statuses.filter(status => status.checked).map(status => status.name)
                this.$store.commit('UPDATE_COMPANIES_STATUSES_CHECKED', this.statuses_checked)
            },
        },
    },
}
</script>
