<template>
    <div class="loading loading-lg mt-4"></div>
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            search: this.$route.query.search,
            endpoint: this.$route.params.endpoint,
        }
    },

    async mounted() {
        const params = { search: this.search, rd: false }

        const { data: paginator } = await axios.get(this.endpoint, { params })

        if (paginator.data.length === 1) {
            this.$router.replace({ name: `${this.endpoint}.show`, params: { id: paginator.data[0].id } })
        } else {
            this.$router.replace({ name: this.endpoint, query: params })
        }
    },
}
</script>
