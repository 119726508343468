<template>
    <Modal :title="$t('files.add_field_to_file')" @close="back()">
        <div class="form-group">
            <label class="form-checkbox">
                <input v-model="updateExistingValues" type="checkbox" />
                <i class="form-icon"></i>
                <span>{{ $t('files.update_values_of_existing_fields') }}</span>
            </label>
        </div>

        <div class="form-group">
            <select v-model="fieldSelected" class="form-select">
                <option v-for="field in fields" :key="field.id" :value="field">{{ field.name }}</option>
            </select>
        </div>

        <div class="form-group">
            <FieldsModel v-if="fieldSelected" :field="fieldSelected" v-model="fieldSelected.value"></FieldsModel>
        </div>

        <template #footer>
            <button @click="save()" class="btn btn-primary" :disabled="!fieldSelected" :class="{ loading }">
                {{ $t('main.create') }}
            </button>
            <button @click="back()" class="btn ml-2">{{ $t('main.cancel') }}</button>
        </template>
    </Modal>
</template>

<script>
import axios from 'axios'
import FieldsModel from '~/components/files/FileFieldsModel.vue'

export default {
    components: { FieldsModel },

    data() {
        return {
            show: false,
            loading: false,
            ids: this.$route.params.id ? [this.$route.params.id] : this.$route.query.files,

            fields: [],
            fieldSelected: null,
            updateExistingValues: false,
        }
    },

    async mounted() {
        const { data: paginator } = await axios.get('fields', { params: { limit: 200 } })

        this.fields = paginator.data
    },

    methods: {
        back() {
            if (this.$route.params.id) {
                this.$router.push({ name: 'files.show' })
            } else {
                this.$router.push({ name: 'files' })
            }
        },

        async save() {
            this.loading = true

            await axios.post('files_fields', {
                files: this.ids,
                field_id: this.fieldSelected.id,
                field_value: this.fieldSelected.value || '',
                update_existing: this.updateExistingValues,
            })

            this.back()
            this.$emit('refresh')
        },
    },
}
</script>
