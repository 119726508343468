<template>
    <SidePanel ref="panel" :title="$t('main.edit')" @close="$router.push({ name: 'settings.contact_lists' })">
        <form v-if="list.id" @submit.prevent="updateContactList()">
            <div class="form-group">
                <Errors :errors="errors" />
            </div>

            <div class="form-group">
                <label class="form-label" for="name">{{ $t('distribution_lists.name') }}</label>
                <input
                    id="name"
                    class="form-input"
                    v-model="list.name"
                    :placeholder="$t('distribution_lists.name')"
                    required
                />
            </div>

            <div class="form-group">
                <label class="form-label" for="customer_id">{{ $t('distribution_lists.customer_id') }}</label>
                <input
                    id="customer_id"
                    class="form-input"
                    v-model="list.customer_id"
                    :placeholder="$t('distribution_lists.customer_id')"
                    required
                />
            </div>

            <div class="form-group">
                <div class="input-group my-1" v-for="(v, i) in list.recipients" :key="i">
                    <input
                        type="email"
                        class="form-input input-sm"
                        v-model="list.recipients[i].email"
                        :placeholder="$t('users.email')"
                        required
                    />

                    <input
                        class="form-input input-sm ml-1"
                        v-model="list.recipients[i].label"
                        :placeholder="$t('distribution_lists.label')"
                    />

                    <a href="#" class="btn btn-sm btn-clear" @click.prevent="removeRecipient(i)"></a>
                </div>

                <button type="button" class="btn btn-sm" @click="addRecipient()">
                    <i class="fas fa-plus-circle mr-2"></i>
                    <span>{{ $t('distribution_lists.add_recipient') }}</span>
                </button>
            </div>

            <div class="form-group">
                <label class="form-label" for="language">{{ $t('main.language') }}</label>
                <select v-model="list.language" id="language" class="form-select" required>
                    <option v-for="(lang, key) in locales" :key="key" :value="key">{{ lang }}</option>
                </select>
            </div>

            <div class="flex-space my-4">
                <button class="btn btn-primary" type="submit">{{ $t('main.save') }}</button>
                <button type="button" class="btn btn-error" @click="deleteList()">{{ $t('main.delete') }}</button>
            </div>
        </form>
    </SidePanel>
</template>

<script>
import axios from 'axios'
import SidePanel from '~/components/SidePanel.vue'

export default {
    components: { SidePanel },

    data() {
        return {
            errors: null,
            id: this.$route.params.id,
            locales: window.config.locales,

            list: {},
        }
    },

    async mounted() {
        await this.fetchData()
    },

    methods: {
        async fetchData() {
            const { data: list } = await axios.get(`distribution_lists/${this.id}`)

            this.list = list
        },

        updateContactList() {
            axios
                .patch(`distribution_lists/${this.id}`, this.list)
                .then(() => {
                    this.$emit('refresh')
                    this.$router.push({ name: 'settings.contact_lists' })
                    window.toast(this.$t('i.update', { i: this.list.name }))
                })
                .catch(({ response }) => {
                    this.errors = response.data
                })
        },

        deleteList() {
            this.$swal({
                showCancelButton: true,
                title: this.$t('main.are_you_sure'),
                text: this.$t('main.you_wont_be_able_to_revert_this'),
                confirmButtonText: this.$t('main.yes_delete_it'),
                cancelButtonText: this.$t('main.cancel'),
            }).then(result => {
                if (result.value) {
                    axios.delete(`distribution_lists/${this.list.id}`).then(() => {
                        this.$emit('refresh')
                        this.$router.push({ name: 'settings.contact_lists' })
                        window.toast(this.$t('i.delete', { i: this.list.name }))
                    })
                }
            })
        },

        addRecipient() {
            this.list.recipients.push({ email: '', label: '' })
        },

        removeRecipient(index) {
            if (this.list.recipients.length > 1) {
                this.list.recipients.splice(index, 1)
            }
        },
    },
}
</script>
