<template>
    <Modal :title="$t('kiosk.editandaccept')" @close="$router.push({ name: 'kiosk_submits' })">
        <div class="form-group">
            <Errors :errors="errors" />
        </div>

        <div class="form-group" v-for="answer in submit.answers" :key="answer.id">
            <label class="form-label" :class="{ 'text-bold': answer.step.model === 'reference' }">
                {{ answer.step.question }}
            </label>
            <input v-model="answer.answer" class="form-input" />
        </div>

        <template #footer>
            <button class="btn btn-primary" @click="acceptSubmit()">{{ $t('main.create') }}</button>
        </template>
    </Modal>
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            errors: null,
            id: this.$route.params.id,
            submit: {},
        }
    },

    async mounted() {
        const { data: submit } = await axios.get(`kiosk_submits/${this.id}`)

        this.submit = submit
    },

    methods: {
        acceptSubmit() {
            axios
                .post(`kiosk_submits/${this.submit.id}/accept`, this.submit)
                .then(() => {
                    this.$emit('refresh')
                    this.$router.push({ name: 'kiosk_submits' })
                    window.toast(this.$t('webhooks.NEW_FILE_CREATED'))
                })
                .catch(({ response }) => {
                    this.errors = response.data
                })
        },
    },
}
</script>
