<template>
    <div class="form-horizontal">
        <Errors :errors="errors" />

        <div class="form-group">
            <div class="column col-3 col-md-12">
                <label class="form-label" for="email">{{ $t('share.recipients') }}</label>
            </div>
            <div class="column">
                <div class="d-flex">
                    <div class="form-autocomplete" style="width: 100%" v-clickaway="hideMenu">
                        <div class="form-autocomplete-input form-input form-group mb-0"
                            :class="{ 'is-focused': searching, 'has-error': emailHasError }">
                            <span class="chip" v-for="(email, i) in share.emails" :key="i">
                                <span>{{ email }}</span>
                                <a href="#" class="btn btn-sm btn-clear" @click.prevent="share.emails.splice(i, 1)"></a>
                            </span>

                            <input id="email" ref="email" type="email" class="form-input" v-model="email"
                                :placeholder="$t('users.email')" @keyup.enter="addRecipient(email)"
                                @focus="startSearching()" @blur="addRecipient(email, true)" />
                        </div>
                        <ul class="menu" v-if="searching && last_used_emails.length">
                            <template v-for="e in last_used_emails">
                                <li :key="e" class="menu-item"
                                    v-if="e.indexOf(email) > -1 && share.emails.indexOf(e) === -1">
                                    <a href="#" @click.prevent="addRecipient(e)">
                                        {{ e }}
                                    </a>
                                </li>
                            </template>
                        </ul>
                    </div>
                    <div class="dropdown dropdown-right ml-2">
                        <a tabindex="0" type="button" class="btn dropdown-toggle tooltip tooltip-left"
                            :data-tooltip="$t('main.distribution_lists')">
                            <i class="fas fa-at"></i>
                        </a>
                        <ul class="menu">
                            <li class="menu-item" v-for="list in lists" :key="list.id">
                                <a href="#" @click.prevent="addContactList(list)">{{ list.name }}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="form-group">
            <div class="column col-3 col-md-12">
                <label class="form-label" for="subject">{{ $t('share.sub') }}</label>
            </div>
            <div class="column">
                <div class="d-flex">
                    <input id="subject" class="form-input" v-model="share.subject" :placeholder="$t('share.sub')"
                        required />
                    <div class="dropdown dropdown-right ml-2">
                        <a tabindex="0" class="btn dropdown-toggle">
                            <i class="fas fa-globe"></i>
                        </a>
                        <ul class="menu">
                            <li class="menu-item" v-for="(lang, code) in locales" :key="code">
                                <a href="#" @click.prevent="share.language = code"
                                    :class="{ active: share.language === code }">
                                    <span>{{ lang }}</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="form-group">
            <div class="column col-3 col-md-12">
                <label class="form-label" for="customer_reference">{{ $t('share.customer_reference') }}</label>
            </div>
            <div class="column">
                <input id="customer_reference" class="form-input" v-model="share.customer_reference"
                    :placeholder="$t('share.customer_reference')" />
            </div>
        </div>

        <div class="form-group">
            <div class="column col-3 col-md-12">
                <label class="form-label" for="comment">{{ $t('share.comment') }}</label>
            </div>
            <div class="column">
                <textarea id="comment" v-model="share.comment" rows="5" class="form-input"></textarea>
            </div>
        </div>

        <div class="form-group">
            <div class="column col-3 col-md-12">
                <label class="form-label" for="send_me_a_cc">{{ $t('share.send_me_a_cc') }}</label>
            </div>
            <div class="column">
                <label class="form-checkbox">
                    <input id="send_me_a_cc" v-model="share.send_cc" type="checkbox" />
                    <i class="form-icon"></i>
                </label>
            </div>
        </div>

        <div class="form-group">
            <div class="column col-3 col-md-12">
                <label class="form-label" for="allow_zip_download">{{ $t('share.allow_zip_download') }}</label>
            </div>
            <div class="column">
                <label class="form-checkbox">
                    <input id="allow_zip_download" v-model="share.allow_zip_download" type="checkbox" />
                    <i class="form-icon"></i>
                </label>
            </div>
        </div>

        <div class="form-group">
            <div class="column col-3 col-md-12">
                <label class="form-label" for="allow_pdf_download">{{ $t('share.allow_pdf_download') }}</label>
            </div>
            <div class="column">
                <div class="input-group">
                    <label class="form-checkbox">
                        <input type="checkbox" id="allow_pdf_download" v-model="share.allow_pdf_download"
                            @change="loadReportTemplates()" />
                        <i class="form-icon"></i>
                    </label>
                    <select v-if="share.allow_pdf_download" v-model="share.allow_pdf_download_layout"
                        class="form-select">
                        <option :value="null" disabled hidden>{{ $t('reports.layout') }}</option>
                        <option v-for="t in templates" :key="t.id" :value="t.code">{{ t.name }}</option>
                    </select>
                </div>
            </div>
        </div>

        <div class="form-group">
            <div class="column col-3 col-md-12">
                <label class="form-label">{{ $t('main.expiry') }}</label>
            </div>
            <div class="column">
                <DatePicker v-model="share.expiry" :placeholder="$t('main.no_expiry')"></DatePicker>
            </div>
        </div>
        <div class="form-group">
            <button @click="createShare()" class="btn btn-primary" :class="{ loading }">{{ $t('files.share') }}</button>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { directive as clickaway } from 'vue3-click-away'

export default {
    props: ['file', 'lists'],

    directives: { clickaway },

    data() {
        return {
            searching: false,
            last_used_emails: [],
            locales: window.config.locales,

            email: '',
            emailHasError: false,

            errors: null,
            loading: false,

            templates: [],

            shareDefaults: this.$store.state.user.location,

            share: {
                emails: [],
                subject: '',
                comment: '',
                expiry: '',
                send_cc: false,
                customer_reference: null,
                allow_zip_download: false,
                allow_pdf_download: false,
                allow_pdf_download_layout: null,
                language: this.$store.state.language,
            },
        }
    },

    watch: { 'share.language': 'translateSubject' },

    mounted() {
        this.translateSubject()

        this.share.customer_reference = this.buildCustomerReferenceFromFields()
        this.share.allow_zip_download = this.shareDefaults.share_allow_zip_download || false
        this.share.allow_pdf_download = this.shareDefaults.share_allow_pdf_download || false

        this.share.allow_pdf_download_layout =
            this.shareDefaults.share_allow_pdf_download_layout ||
            this.$store.state.lastReportCreatedValues.template ||
            '1'

        if (this.share.allow_pdf_download) {
            this.loadReportTemplates()
        }
    },

    methods: {
        loadReportTemplates() {
            if (!this.templates.length) {
                axios.get('report_templates').then(({ data }) => {
                    this.templates = data
                })
            }
        },

        startSearching() {
            this.searching = true

            if (!this.last_used_emails.length) {
                axios.get('shares/last_used_emails').then(({ data }) => {
                    const array = this.extractEmailsFromText(data.toString())

                    if (array) {
                        this.last_used_emails = array.filter((item, pos) => array.indexOf(item) === pos)
                    }
                })
            }
        },

        async translateSubject() {
            const mask = this.shareDefaults.share_subject_mask

            if (mask) {
                this.share.subject = this.buildSubjectByMask(mask)

                return
            }

            const language = this.share.language.slice(-2).toLowerCase()
            const messages = await import(`~/assets/translations/${language}.json`)

            this.$i18n.setLocaleMessage(language, messages.default)

            this.share.subject = this.$t('share.subject', language, {
                shared_by: this.$store.state.user.name,
                scan_code: this.file.scan_code,
            })
        },

        buildCustomerReferenceFromFields() {
            const fieldId = this.shareDefaults.share_customer_reference_field_id
            const fields = this.file.fields.filter(field => field.tag_id === fieldId)
            const field = fields.map(i => i.value).join(',')

            return field
        },

        buildSubjectByMask(mask) {
            // NOTE! this is building the mask in the UI. There is also a PHP function, with this name,
            // doing the same but then for the backend!!!
            let str = mask

            str = str.replace('{{reference}}', this.file.scan_code)
            str = str.replace('{{name}}', this.$store.state.user.name)
            str = str.replace('{{email}}', this.$store.state.user.email)
            str = str.replace('{{company}}', this.$store.state.user.company.company)

            // match and get field value for mask
            str = str.replace(/{{field:(.*?)}}/g, (match, contents) => {
                const tag_id = parseInt(contents)

                const field = this.file.fields.find(f => f.tag_id === tag_id)

                return field ? field.value : ''
            })

            const submits = this.file.form_submits

            str = str.replace(/{{formfield:(.*?)}}/g, (match, contents) => {
                const form_field_id = parseInt(contents)

                for (let i = 0; i < submits.length; i++) {
                    const answers = submits[i].answers

                    for (let j = answers.length - 1; j >= 0; j--) {
                        const answer = answers[j]

                        if (answer.form_field_id === form_field_id && answer.value) {
                            return answer.value
                        }
                    }
                }

                return ''
            })

            return str
        },

        createShare() {
            if (!this.share.emails.length) {
                this.emailHasError = true
            }

            if (this.emailHasError) {
                this.$refs.email.focus()
            } else {
                this.errors = null
                this.loading = true

                axios
                    .post(`files/${this.file.id}/shares`, this.share)
                    .then(() => {
                        this.file.shares_count++
                        this.$router.push({ name: 'files.show' })
                        window.toast(this.$t('i.shared', { i: this.file.scan_code }))
                    })
                    .catch(({ response }) => {
                        this.loading = false
                        this.errors = response.data
                    })
            }
        },

        extractEmailsFromText(text) {
            return text.match(/([a-zA-Z0-9+._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi)
        },

        addRecipient(string) {
            this.emailHasError = false

            if (string) {
                const array = this.extractEmailsFromText(string || this.email)

                if (array && array.length) {
                    array.forEach(email => {
                        if (this.share.emails.indexOf(email) === -1) {
                            this.share.emails.push(email)
                        }
                    })
                    this.email = ''
                } else {
                    this.emailHasError = true
                }
            }
        },

        addContactList(list) {
            const emailString = list.recipients.map(author => author.email).join(',')

            this.addRecipient(emailString)
        },

        hideMenu() {
            this.searching = false
        },
    },
}
</script>
