<template>
    <div class="issue-timeline-item issue-timeline-description comment">
        <IssueTimelineItem :name="issue.author.name" type="created" :timestamp="issue.created_at"></IssueTimelineItem>

        <div class="issue-timeline-item-comment">
            <TextEditor v-if="issue.edit" v-model="issue.description" autoFocus @save="updateDescription(issue)"
                @cancel="issue.edit = false" :placeholder="$t('issues.write_a_comment')">
            </TextEditor>

            <div v-else class="text-comment">
                <div class="ql-editor p-0" v-html="issue.description"></div>
                <i v-if="issue.author.id === $store.state.user.id" @click="issue.edit = true"
                    class="fa-regular fa-pencil c-hand"></i>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import IssueTimelineItem from './IssueTimelineItem.vue'
import TextEditor from '~/components/htmlform/TextEditor.vue'

export default {
    emits: ['update'],
    props: { issue: Object },
    components: { IssueTimelineItem, TextEditor },

    data() {
        return {
            issue_number: this.$route.params.issue_number,
        }
    },

    methods: {
        async updateDescription(issue) {
            await axios.patch(`issues/${this.issue_number}`, { description: issue.description })
            issue.edit = false
            this.$emit('update')
        },
    }
}
</script>