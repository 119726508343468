import { nextTick } from 'vue'
import { createI18n } from 'vue-i18n'
import { loadDayjsLocales } from './dayjs.js'
import en from '~/assets/translations/en.json'

const acceptedLocales = ['en', 'nl', 'pt', 'br', 'fr', 'de', 'it', 'es', 'ru', 'pl', 'tr', 'da', 'ro', 'lv', 'se', 'zh']

const i18n = createI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages: { en },
})

async function loadLocaleMessages(locale) {
    const messages = await import(`~/assets/translations/${locale}.json`)

    i18n.global.setLocaleMessage(locale, messages.default)
}

export async function setI18nLanguage(locale) {
    locale = locale.slice(-2).toLowerCase()
    locale = acceptedLocales.includes(locale) ? locale : 'en'

    loadDayjsLocales(locale)

    // set locale and locale message
    if (!i18n.global.availableLocales.includes(locale)) {
        await loadLocaleMessages(locale)
    }

    i18n.global.locale = locale
    document.querySelector('html').setAttribute('lang', locale)

    return nextTick()
}

export default i18n
