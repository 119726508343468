<template>
    <SidePanel ref="panel" :title="$t('webhooks.edit')" @close="$router.push({ name: 'settings.webhooks' })">
        <form v-if="$store.state.user.properties.use_api" @submit.prevent="updateWebhook()">
            <div class="form-group">
                <Errors :errors="errors" />
            </div>

            <div class="form-group">
                <label class="form-label" for="description">{{ $t('webhooks.description') }}</label>
                <input id="description" class="form-input" v-model="hook.description"
                    :placeholder="$t('webhooks.description')" />
            </div>

            <div class="form-group">
                <label class="form-label" for="action">{{ $t('webhooks.action') }}</label>
                <select id="action" v-model="hook.action" class="form-select" required>
                    <option :value="action" v-for="action in actions" :key="action">
                        {{ $t(`webhooks.${action}`) }}
                    </option>
                </select>
            </div>

            <div class="form-group">
                <label class="form-label" for="url">{{ $t('webhooks.url') }}</label>
                <input type="url" id="url" class="form-input" v-model="hook.public_url"
                    placeholder="https://webhook.url" required />
            </div>

            <div class="form-group">
                <label class="form-label" for="email_on_failure">{{ $t('webhooks.email_on_failure') }}</label>
                <input type="email" class="form-input" id="email_on_failure" v-model="hook.email_on_failure"
                    :placeholder="$t('webhooks.emailcsv')" required />
            </div>

            <div class="form-group">
                <label class="form-checkbox">
                    <input type="checkbox" v-model="hook.paused" />
                    <i class="form-icon"></i>
                    <span>{{ $t('webhooks.paused') }}</span>
                </label>
            </div>

            <div class="dev-feature" v-if="$store.getters.can('Use internal features')">
                <div class="form-group">
                    <label class="form-label" for="scope">Scope</label>
                    <input type="number" id="scope" class="form-input" v-model="hook.scope" />
                </div>
            </div>

            <div class="flex-space">
                <button type="submit" class="btn btn-primary">{{ $t('main.save') }}</button>
                <button type="button" class="btn btn-error" @click="deleteWebhook()">{{ $t('main.delete') }}</button>
            </div>
        </form>
        <NeedsPremiumLicense v-else :text="$t('license_limit.api_tokens')"
            :limit-text="$t('license_limit.premium_feature')"></NeedsPremiumLicense>
    </SidePanel>
</template>

<script>
import axios from 'axios'
import SidePanel from '~/components/SidePanel.vue'
import NeedsPremiumLicense from '~/components/NeedsPremiumLicense.vue'

export default {
    components: { SidePanel, NeedsPremiumLicense },

    data() {
        return {
            hook: {},
            errors: null,
            id: this.$route.params.id,

            actions: [
                'NEW_FILE_CREATED',
                'SUBMIT_FORM',
                'WORKFLOW_RUN_COMPLETE',
                'SHARE_CREATED',
                'FIELD_VALIDATION_FAILED',
                'FILE_CLOSED',
            ],
        }
    },

    mounted() {
        axios.get(`webhooks/${this.id}`).then(({ data }) => {
            this.hook = data
        })
    },

    methods: {
        updateWebhook() {
            this.errors = null

            axios
                .post(`webhooks/${this.hook.id}/edit`, this.hook)
                .then(() => {
                    this.$emit('refresh')
                    this.$router.push({ name: 'settings.webhooks' })
                })
                .catch(({ response }) => {
                    this.errors = response.data
                })
        },

        deleteWebhook() {
            this.$swal({
                showCancelButton: true,
                title: this.$t('main.are_you_sure'),
                text: this.$t('main.you_wont_be_able_to_revert_this'),
                confirmButtonText: this.$t('main.yes_delete_it'),
                cancelButtonText: this.$t('main.cancel'),
            }).then(result => {
                if (result.value) {
                    axios.delete(`webhooks/${this.hook.id}`).then(() => {
                        this.$emit('refresh')
                        this.$router.push({ name: 'settings.webhooks' })
                    })
                }
            })
        },
    },
}
</script>
