<template>
    <div>
        <ContextBar
            :title="$t('main.devices')"
            :buttonText="$t('context.create_devices')"
            buttonAbility="Create devices"
            buttonRoute="settings.devices.create"
        >
            <DebounceInput v-model="search" clearable searchIcon />
        </ContextBar>

        <DataTable
            endpoint="devices"
            :columns="columns"
            :parameters="parameters"
            sort="recent_snap_scan_date_time"
            :key="refresh"
        >
            <template #column(nickname)="{ item }">
                <router-link :to="{ name: 'settings.devices.show', params: { id: item.id } }" class="table-link">
                    {{ item.nickname }}
                </router-link>
            </template>
            <template #column(location_id)="{ item }">{{ getLocationName(item) }}</template>
            <template #column(app_version)="{ item }">
                <span class="mr-2">{{ item.app_version }}</span>
                <i class="fa-brands" :class="['fa-' + getDeviceOS(item)]"></i>
            </template>
            <template #column(activated)="{ value }">
                <i class="fas" :class="{ 'fa-check': value }"></i>
            </template>
            <template #column(recent_snap_scan_date_time)="{ value }">
                <DateTime :date="value"></DateTime>
            </template>
            <template #column(updated_at)="{ value }">
                <DateTime :date="value"></DateTime>
            </template>
        </DataTable>

        <router-view @refresh="refresh++" :locations="locations"></router-view>
    </div>
</template>

<script>
import axios from 'axios'
import DataTable from '~/components/DataTable.vue'

export default {
    components: { DataTable },

    data() {
        return {
            refresh: 0,
            locations: [],

            search: this.$route.query.search,
            location_id: this.$route.query.location_id,

            columns: [
                { name: 'nickname', th: 'main.name', sortable: true, class: 'mw-250' },
                { name: 'location_id', th: 'main.location', sortable: true },
                { name: 'app_version', th: 'devices.version', sortable: true },
                { name: 'activated', th: 'devices.activated', sortable: true },
                { name: 'recent_snap_scan_date_time', th: 'files.recent_upload', sortable: true },
                { name: 'updated_at', th: 'main.updated', sortable: true },
            ],
        }
    },

    computed: {
        parameters() {
            return {
                search: this.search || undefined,
                location_id: this.location_id || undefined,
            }
        },
    },

    async mounted() {
        const { data: paginator } = await axios.get('locations', { params: { limit: 200 } })

        this.locations = paginator.data
    },

    methods: {
        getLocationName(device) {
            const location = this.locations.find(l => l.id === device.location_id)

            return location ? location.location : ''
        },

        getDeviceOS(device) {
            if (!device.attributes) return

            const platform = device.attributes.platform

            return platform === 'ios' ? 'apple' : platform
        },
    },
}
</script>
