<template></template>

<script>
import axios from 'axios'

export default {
    async mounted() {
        axios
            .post(`auth/activate/${this.$route.params.token}`)
            .then(async () => {
                await this.$store.dispatch('fetchUser')

                if (this.hasNotStartedTour && this.companyIsInTrial && this.isLicenseAdmin) {
                    this.$store.dispatch('startTour')
                }

                this.$router.replace({ name: 'dashboard' })
                window.toast(this.$t('auth.account_activated'))
            })
            .catch(() => {
                this.$router.replace({ name: 'dashboard' })
                window.toast(this.$t('main.oops'), 'error')
            })
    },

    computed: {
        hasNotStartedTour() {
            return !this.$store.state.user.company.properties?.onboarding
        },

        companyIsInTrial() {
            return this.$store.state.jwt.status === 'trial'
        },

        isLicenseAdmin() {
            return this.$store.state.user.roles.includes('LicenseAdmin')
        }
    }
}
</script>
