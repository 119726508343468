<template>
    <div class="mb-4">
        <form @submit.prevent="sendActivateByEmail()" v-if="activateByEmail">
            <div class="form-group">
                <label class="form-label" for="email">{{ $t('devices.email_of_device') }}</label>
                <input
                    id="email"
                    type="email"
                    v-model="email"
                    class="form-input"
                    :placeholder="$t('devices.email_of_device')"
                    required
                />
            </div>

            <div class="form-group">
                <button type="submit" class="btn btn-primary mr-2" :disabled="activateByEmailSent">
                    {{ $t('devices.send_activation') }}
                </button>
                <button type="button" class="btn" @click="activateByEmail = false">
                    {{ $t('devices.back_to_qr') }}
                </button>
            </div>
        </form>

        <div class="text-center" v-else>
            <QrcodeVue :size="150" :value="`device_id=${device.id}&token=${device.token}`"></QrcodeVue>
            <div>{{ $t('devices.scan_the_qr') }}</div>
            <div>
                <a href="#" @click.prevent="activateByEmail = true">{{ $t('devices.activate_alternative') }}</a>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import QrcodeVue from 'qrcode.vue'

export default {
    props: ['device'],

    components: { QrcodeVue },

    data() {
        return {
            timer: null,

            email: this.device.email || null,
            activateByEmail: !!this.device.email,
            activateByEmailSent: false,
        }
    },

    methods: {
        sendActivateByEmail() {
            this.activateByEmailSent = true
            window.toast(`Email sent to ${this.email}`)
            axios.post(`device_activate_by_email/${this.device.id}/${this.email}`)
        },
    },

    mounted() {
        this.timer = setInterval(() => {
            axios.get(`devices/${this.device.id}/activated`).then(({ data }) => {
                if (data.activated) {
                    this.$emit('activated')
                    clearInterval(this.timer)
                }
            })
        }, 5000)
    },

    beforeUnmount() {
        clearInterval(this.timer)
    },
}
</script>
