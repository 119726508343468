<template>
    <div class="counters-config">
        <table class="table">
            <tr v-for="(item, index) in config" :key="index">
                <td>
                    <div class="d-flex">
                        <select v-model="item.counter" class="form-select" style="flex: 1">
                            <option value="uploads">{{ $t('profile.counters_uploads') }}</option>
                            <option value="damages">{{ $t('profile.counters_damages') }}</option>
                            <option value="files">{{ $t('profile.counters_files') }}</option>
                            <option value="files_with_damages">{{ $t('profile.counters_files_with_damages') }}</option>
                            <option value="forms">{{ $t('profile.counters_forms') }}</option>
                            <option value="shares">{{ $t('profile.counters_shares') }}</option>
                        </select>

                        <select
                            v-if="item.counter === 'forms'"
                            v-model="item.params"
                            class="form-select ml-2"
                            style="flex: 1"
                        >
                            <option :value="undefined">Any</option>
                            <option v-for="form in forms" :key="form.id" :value="form">{{ form.title }}</option>
                        </select>
                    </div>
                </td>
                <td>
                    <input type="color" v-model="item.color" id="color" class="form-input" />
                </td>
                <td>
                    <div class="dropdown dropdown-right">
                        <a tabindex="0" class="btn dropdown-toggle">
                            <i class="fa-regular" :class="item.icon" :style="{ color: item.color }"></i>
                        </a>
                        <ul class="menu">
                            <li class="menu-item c-hand" v-for="icon in faIcons" :key="icon">
                                <a @click.prevent="item.icon = icon">
                                    <i class="fa-regular" :class="icon" :style="{ color: item.color }"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </td>

                <td>
                    <button
                        type="button"
                        style="align-self: center"
                        class="btn btn-sm btn-clear"
                        @click="remove(index)"
                    ></button>
                </td>
            </tr>
        </table>

        <button class="btn my-2" :class="{ ghost: !canAddMoreCounters }" @click="add()">
            <i class="fa-regular fa-circle-plus mr-2"></i>
            <span>{{ $t('profile.add_counter') }}</span>
        </button>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    props: ['config'],

    data() {
        return {
            forms: [],

            faIcons: [
                'fa-camera',
                'fa-align-left',
                'fa-exclamation-circle',
                'fa-folder',
                'fa-list-alt',
                'fa-share',
                'fa-truck',
                'fa-truck-loading',
                'fa-plane',
                'fa-ship',
                'fa-car-side',
                'fa-box',
                'fa-boxes',
                'fa-pallet',
                'fa-clipboard-check',
                'fa-shipping-fast',
                'fa-warehouse',
            ],
        }
    },

    computed: {
        canAddMoreCounters() {
            return this.config.length < 6
        },
    },

    mounted() {
        axios.get('forms', { params: { limit: 200 } }).then(({ data }) => {
            this.forms = data.data.map(({ id, title }) => ({ id, title }))
        })
    },

    methods: {
        remove(index) {
            if (this.config.length <= 1) return

            this.config.splice(index, 1)
        },

        add() {
            if (!this.canAddMoreCounters) return

            this.config.push({ counter: 'uploads', icon: 'fa-camera', color: '#1f9f9f' })
        },
    },
}
</script>
