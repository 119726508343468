<template>
    <div class="settings columns col-gapless">
        <div class="column col-3 col-lg-12">
            <SideNav>
                <template #toggle="{ open }">
                    <div class="show-lg">
                        <button class="btn" @click="open">
                            <i class="fa-regular fa-bars mr-2"></i>
                            <span>{{ $t('main.settings') }}</span>
                        </button>
                    </div>
                </template>
                <h2 class="sidenav-title">{{ $t('main.settings') }}</h2>
                <div class="nav-item nav-heading">{{ $t('profile.personal_settings') }}</div>
                <div class="nav-item">
                    <router-link :to="{ name: 'settings' }" exact>
                        <i class="fa-regular fa-fw fa-circle-user"></i>
                        <span>{{ $t('profile.profile') }}</span>
                    </router-link>
                </div>
                <div class="nav-item" v-if="!isFreeAccount">
                    <router-link :to="{ name: 'settings.workspace' }">
                        <i class="fa-regular fa-fw fa-gauge"></i>
                        <span>{{ $t('profile.workspace') }}</span>
                    </router-link>
                </div>
                <div class="nav-item" v-if="!isFreeAccount">
                    <router-link :to="{ name: 'settings.notifications' }">
                        <i class="fa-regular fa-fw fa-bell"></i>
                        <span>{{ $t('profile.notifications') }}</span>
                    </router-link>
                </div>
                <div class="nav-item nav-heading" v-if="can('Use management')">
                    <div class="divider"></div>
                    <span>{{ $t('profile.teams_devices_users') }}</span>
                </div>
                <div class="nav-item" v-if="can('Create locations')">
                    <router-link :to="{ name: 'settings.teams' }">
                        <i class="fa-regular fa-fw fa-building"></i>
                        <span>{{ $t('main.teams') }}</span>
                    </router-link>
                </div>
                <div class="nav-item" v-if="can('Create devices')">
                    <router-link :to="{ name: 'settings.devices' }">
                        <i class="fa-regular fa-fw fa-mobile"></i>
                        <span>{{ $t('main.devices') }}</span>
                    </router-link>
                </div>
                <div class="nav-item" v-if="can('Create users')">
                    <router-link :to="{ name: 'settings.users' }">
                        <i class="fa-regular fa-fw fa-users"></i>
                        <span>{{ $t('main.users') }}</span>
                    </router-link>
                </div>
                <template v-if="can('Use global settings')">
                    <div class="nav-item nav-heading">
                        <div class="divider"></div>
                        <span>{{ $t('profile.company_settings') }}</span>
                    </div>
                    <div class="nav-item">
                        <router-link :to="{ name: 'settings.company' }">
                            <i class="fa-regular fa-fw fa-building"></i>
                            <span>{{ $t('main.general') }}</span>
                        </router-link>
                    </div>
                    <div class="nav-item">
                        <router-link :to="{ name: 'settings.permissions' }">
                            <i class="fa-regular fa-fw fa-lock"></i>
                            <span>{{ $t('main.permissions') }}</span>
                        </router-link>
                    </div>
                    <div class="nav-item">
                        <router-link :to="{ name: 'settings.fields' }">
                            <i class="fa-regular fa-fw fa-tags"></i>
                            <span>{{ $t('main.fields') }}</span>
                        </router-link>
                    </div>
                    <div class="nav-item">
                        <router-link :to="{ name: 'settings.api_tokens' }">
                            <i class="fa-regular fa-fw fa-key"></i>
                            <span>{{ $t('main.api_tokens') }}</span>
                        </router-link>
                    </div>
                    <div class="nav-item">
                        <router-link :to="{ name: 'settings.webhooks' }">
                            <i class="fa-regular fa-fw fa-webhook"></i>
                            <span>{{ $t('main.webhooks') }}</span>
                        </router-link>
                    </div>
                </template>

                <div class="nav-item" v-if="can('Use distribution lists')">
                    <router-link :to="{ name: 'settings.contact_lists' }">
                        <i class="fa-regular fa-fw fa-envelope-square"></i>
                        <span>{{ $t('main.distribution_lists') }}</span>
                    </router-link>
                </div>
            </SideNav>
        </div>
        <div class="column col-9 col-lg-12">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SideNav from '~/components/SideNav.vue'

export default {
    components: { SideNav },

    computed: mapGetters(['can', 'isFreeAccount']),
}
</script>
