<template>
    <div class="company-dropdown dropdown dropdown-right">
        <a tabindex="0" class="btn dropdown-toggle">
            <Avatar :initials="user.company.company" class="bg-orange"></Avatar>
            <span class="ml-2">{{ user.company.company }}</span>
        </a>

        <div class="menu">
            <div class="nav">
                <div class="flex-space my-2">
                    <Avatar :initials="user.name" online></Avatar>
                    <div class="ml-2" style="flex: 1">
                        <div class="text-bold">{{ user.name }}</div>
                        <div class="">{{ user.email }}</div>
                    </div>
                </div>
                <div class="divider"></div>
                <div class="nav-item">
                    <router-link :to="{ name: 'settings' }" exact>
                        <i class="fa-regular fa-fw fa-user"></i>
                        <span>{{ $t('profile.personal_settings') }}</span>
                    </router-link>
                </div>
                <div class="nav-item" v-if="can('Use management')">
                    <router-link :to="{ name: 'settings.teams' }">
                        <i class="fa-regular fa-fw fa-users-medical"></i>
                        <span>{{ $t('profile.teams_devices_users') }}</span>
                    </router-link>
                </div>
                <div class="nav-item" v-if="can('Use global settings')">
                    <router-link :to="{ name: 'settings.company' }">
                        <i class="fa-regular fa-fw fa-building"></i>
                        <span>{{ $t('profile.company_settings') }}</span>
                    </router-link>
                </div>
                <div class="nav-item" v-if="can('Use subscriptions')">
                    <router-link :to="{ name: 'billing' }">
                        <i class="fa-regular fa-fw fa-credit-card"></i>
                        <span>{{ $t('main.billing') }}</span>
                    </router-link>
                </div>
                <div class="divider"></div>
                <div class="nav-item">
                    <a href="#" @click="$store.dispatch('endSession')">{{ $t('profile.sign_out') }}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Avatar from '~/components/Avatar.vue'

export default {
    props: ['user'],

    computed: mapGetters(['can']),

    components: { Avatar },
}
</script>
