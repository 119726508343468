<template>
    <div class="my-4">
        <h4>{{ $t('trial.activate_your_free_trial') }}</h4>
        <p>{{ $t('trial.for_the_next_14_days') }}</p>
        <ul>
            <li>{{ $t('trial.create_devices') }}</li>
            <li>{{ $t('trial.upload_unlimited_snaps_and_forms') }}</li>
        </ul>

        <p>{{ $t('trial.trial_will_end_on', { date }) }}</p>

        <button class="btn btn-primary" @click="activate()">{{ $t('trial.activate') }}</button>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            date: dayjs().add(2, 'week').format('MMMM D, YYYY'),
        }
    },

    methods: {
        activate() {
            axios.post('activate_trial').then(async () => {
                this.$store.dispatch('startSession', { ...this.$store.state.jwt, status: 'trial' })

                await this.$store.dispatch('fetchUser')
                this.$router.push({ name: 'dashboard' })

                window.toast(this.$t('trial.trial_has_been_activated'))
            })
        },
    },
}
</script>
