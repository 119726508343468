import axios from 'axios'
import router from '~/router'
import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { setI18nLanguage } from '../plugins/i18n.js'
import azureADAuth from '../plugins/azureADAuth.js'
import * as Sentry from '@sentry/vue'
import tour from './modules/tour'

export default createStore({
    plugins: [createPersistedState()],

    modules: { tour },

    state: {
        layout: 'AuthLayout',
        language: (navigator.language || 'en').substring(0, 2).toLowerCase(),

        darkMode: false,
        preferColorScheme: 'auto',

        jwt: {},
        loggedIn: false,
        loggedInWithMicrosoft: false,
        pathAfterLogin: '/',

        hasAllAbilities: false,
        user: {
            settings: {},
            abilities: {},
            properties: {},
            company: {
                properties: {
                    onboarding: null,
                },
            },
        },

        intercomSettings: {},

        preferences: {
            maps: false,
            timeline: true,
            counters: false,
            start_date: 'all_time',
        },

        indexLimits: {},
        searchFields: [],
        countersConfig: [],
        emailImagesTo: '',
        lastReportCreatedValues: {},

        timelineSortDesc: true,
        timelineViewType: 'list',

        filesTableIsOpenCheckbox: false,
        formSubmitsTableCompactView: false,
        issueTimelineShowOnlyComments: false,
        issueIndexFilterStatus: undefined,
        issueIndexFilterAssignees: '',

        abowireCouponCode: null,
        abowireLicenseCount: 0,

        companyRegionsChecked: [],
        companyStatusesChecked: ['trial', 'standard', 'enterprise'],
    },

    mutations: {
        SET_LAYOUT(state, layout) {
            state.layout = layout
        },

        START_SESSION(state, jwt) {
            state.jwt = jwt
            state.loggedIn = true
            state.loggedInWithMicrosoft = jwt.microsoft
        },

        END_SESSION(state, currentRoute) {
            state.jwt = {}
            state.loggedIn = false
            state.loggedInWithMicrosoft = false
            state.pathAfterLogin = currentRoute
            state.user = {
                abilities: [],
                settings: {},
                properties: {},
                company: {},
            }
        },

        UPDATE_USER(state, user) {
            state.user = user
            state.user.role = user.roles[0] || 'User'
            state.hasAllAbilities = user.abilities.includes('All abilities')

            state.language = user.settings.language

            if (user.settings.counters_settings) {
                state.countersConfig = user.settings.counters_settings
            }

            state.preferences.counters = user.settings.show_counters
            state.preferences.maps = user.settings.show_map_on_dashboard

            state.intercomSettings = {
                app_id: import.meta.env.VITE_INTERCOM_APP_ID,
                user_id: user.id,
                user_hash: user.intercom,
                created_at: user.created_at,

                name: user.name,
                email: user.email,
                user_role: user.role,
                user_language: state.language,
                language_override: state.language,

                tenant_id: user.tenant_id,
                tenant_status: user.company.status,
                location_id: user.location_id,

                company: {
                    company_id: user.company.id,
                    name: user.company.company,
                    created_at: user.company.created_at,
                    domain: user.company.domain,
                    region: user.company.region,
                    plan: user.company.status,
                    trial_expires_at: user.company.trial_expires_at,
                    size: user.company.counts.users,
                    forms_count: user.company.counts.forms,
                    devices_count: user.company.counts.devices,
                    reports_count: user.company.counts.reports,
                    workflows_count: user.company.counts.workflows,
                },
            }
        },

        UPDATE_LANGUAGE(state, { language }) {
            state.language = language
        },

        UPDATE_PREFERENCES(state, preferences) {
            state.preferences = preferences
        },

        UPDATE_COUNTERS(state, counters) {
            state.counters = counters
        },

        UPDATE_COUNTERS_CONFIG(state, config) {
            state.countersConfig = config
        },

        SET_EMAIL_IMAGES_TO(state, to) {
            state.emailImagesTo = to
        },

        SET_LAST_REPORT_CREATED_VALUES(state, to) {
            state.lastReportCreatedValues = to
        },

        UPDATE_FILES_SEARCH_FIELDS(state, to) {
            state.searchFields = to
        },

        UPDATE_INDEX_LIMITS(state, { key, limit }) {
            state.indexLimits[key] = limit
        },

        UPDATE_TIMELINE_SORT_DESC(state, desc) {
            state.timelineSortDesc = desc
        },

        UPDATE_TIMELINE_VIEW_TYPE(state, type) {
            state.timelineViewType = type
        },

        STORE_SEARCH_DEVICE_NICKNAME(state, nickname) {
            state.search_device_nickname = nickname
        },

        UPDATE_GLOB_SETTINGS_SEARCH_FORMS(state, search) {
            state.glob_settings_search_forms = search
        },

        UPDATE_GLOB_SETTINGS_SEARCH_FIELDS(state, search) {
            state.glob_settings_search_fields = search
        },

        UPDATE_GLOB_SETTINGS_SEARCH_WORKFLOWS(state, search) {
            state.glob_settings_search_workflows = search
        },

        UPDATE_COMPANIES_REGIONS_CHECKED(state, checked) {
            state.companyRegionsChecked = checked
        },

        UPDATE_COMPANIES_STATUSES_CHECKED(state, checked) {
            state.companyStatusesChecked = checked
        },

        UPDATE_COLOR_SCHEME(state, scheme) {
            state.preferColorScheme = scheme
        },

        DARKMODE_TOGGLE(state, bool) {
            state.darkMode = bool
        },

        ABOWIRE_PAYMENT_METHOD_ID(state, payment_method_id) {
            state.user.company.abowire_payment_method_id = payment_method_id
        },

        ABOWIRE_SUBSCRIPTION_ID(state, subscription_id) {
            state.user.company.abowire_subscription_id = subscription_id
        },

        ABOWIRE_COUPON_CODE(state, coupon_code) {
            state.abowireCouponCode = coupon_code
        },

        ABOWIRE_LICENSE_COUNT(state, count) {
            state.abowireLicenseCount = count
        },

        UPDATE_FORM_SUBMITS_TABLE_COMPACT_VIEW(state, bool) {
            state.formSubmitsTableCompactView = bool
        },

        UPDATE_FILES_TABLE_IS_OPEN_CHECKBOX(state, bool) {
            state.filesTableIsOpenCheckbox = bool
        },

        UPDATE_ISSUE_TIMELINE_SHOW_ONLY_COMMENTS(state, bool) {
            state.issueTimelineShowOnlyComments = bool
        },

        UPDATE_ISSUE_INDEX_FILTER_STATUS(state, status) {
            state.issueIndexFilterStatus = status
        },

        UPDATE_ISSUE_INDEX_FILTER_ASSIGNEES(state, assignees) {
            state.issueIndexFilterAssignees = assignees
        },
    },

    actions: {
        setLayout({ commit, state }, layout) {
            if (state.layout === layout) return

            commit('SET_LAYOUT', layout)
        },

        initColorScheme({ commit, state }) {
            commit('DARKMODE_TOGGLE', false)
            const scheme = state.preferColorScheme
            let darkMode = scheme === 'dark'

            // Set initial theme to light theme (when not previously set)
            // TODO: once we 'perfected' our dark mode we should remove this if statement
            // so that we always initially show the desired theme based on the system preference

            if (scheme === 'auto') {
                commit('UPDATE_COLOR_SCHEME', 'light')
                darkMode = false
            }

            // if (scheme === 'auto' && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            //     darkMode = true
            // }

            document.documentElement.setAttribute('data-color-scheme', darkMode ? 'dark' : 'light')

            commit('DARKMODE_TOGGLE', darkMode)
        },

        updateColorScheme({ commit, dispatch }, scheme) {
            commit('UPDATE_COLOR_SCHEME', scheme)

            dispatch('initColorScheme')
        },

        async refreshJWT({ commit, state }) {
            const { data } = await axios.post('auth/refresh', { microsoft: state.loggedInWithMicrosoft })
            commit('START_SESSION', data)
        },

        async fetchUser({ commit, state }) {
            const { data } = await axios.get('user')
            commit('UPDATE_USER', data)

            setI18nLanguage(state.language)
            Sentry.setUser({ id: data.id, email: data.email })

            if (data.intercom && !data.impersonate_tenant_id) {
                if (window.Intercom) window.Intercom('update', state.intercomSettings)
            }

            return data
        },

        startSession({ commit }, jwt) {
            commit('START_SESSION', jwt)
        },

        endSession({ commit, state }, currentRoute = null) {
            if (state.loggedInWithMicrosoft) {
                azureADAuth.initialize()
                azureADAuth.signOut()
            }

            // can be triggered multiple times by 401 responses,
            // so we check if sessions has already been ended.
            commit('END_SESSION', currentRoute || router.currentRoute.fullPath)

            // For impersonation reset abowire defaults
            commit('ABOWIRE_LICENSE_COUNT', 0)
            commit('ABOWIRE_COUPON_CODE', null)

            // For licenseadmins reset tour
            commit('TOUR_RESET')

            router.push({ name: 'login' })

            if (window.Intercom) {
                window.Intercom('shutdown')
            }

            return true
        },
    },

    getters: {
        can: state => ability => {
            if (!state.user.abilities.length) return
            return state.user.abilities.includes(ability) || state.hasAllAbilities
        },
        isFreeAccount: state => state.jwt.status === 'free',
        filesEditableUntil: state => state.user.company.files_editable_until,
        hasIssueManagement: state => {
            const env = import.meta.env.VITE_ISSUE_MANAGEMENT_TENANTS
            const tenants = env.split(',').map(Number)

            return tenants.includes(state.user.tenant_id)
        }
    },
})
