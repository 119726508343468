<template>
    <PrintFile v-if="loaded" :files="files"></PrintFile>
    <div v-else class="print-loader">
        <div class="mb-4">Loading...</div>
        <div class="loading loading-lg"></div>
    </div>
</template>

<script>
import axios from 'axios'
import PrintFile from '~/components/print/PrintFile.vue'

export default {
    components: { PrintFile },

    data() {
        return {
            files: [],
            loaded: false,
        }
    },

    async mounted() {
        const ids = this.$route.params.id.split(',')

        if (ids.length > 100) {
            window.toast('Too many files, maximum of 100 files', 'error')
        }

        for (const id of ids) {
            try {
                const { data } = await axios.get(`files/${id}`)

                data.uploads = data.uploads.filter(upload => upload.include_in_share)
                data.form_submits = data.form_submits.filter(submit => submit.shared)

                this.files.push(data)
            } catch {
                window.toast('Something went wrong', 'error')
            }
        }

        document.title = this.files[0].scan_code

        this.loaded = true
    },
}
</script>
