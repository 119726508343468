<template>
    <PrintFile v-if="loaded" :files="files"></PrintFile>
    <div v-else class="print-loader">
        <div class="mb-4">Loading...</div>
        <div class="loading loading-lg"></div>
    </div>
</template>

<script>
import axios from 'axios'
import PrintFile from '~/components/print/PrintFile.vue'

export default {
    components: { PrintFile },

    data() {
        return {
            files: [],
            loaded: false,
            token: this.$route.params.token,
        }
    },

    async mounted() {
        const { data } = await axios.get(`share/${this.token}`)

        this.files.push(data)

        document.title = this.files[0].scan_code

        this.loaded = true
    },
}
</script>
