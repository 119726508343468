<template>
    <div :data-active="active" @dragenter.prevent="active = true" @dragleave.prevent="active = false"
        @dragover.prevent="active = true" @drop.prevent="onDrop">
        <slot></slot>
    </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue'
const emit = defineEmits(['onDrop'])

let active = ref(false)

function onDrop(e) {
    active.value = false
    emit('onDrop', [...e.dataTransfer.files])
}

function preventDefaults(e) {
    e.preventDefault()
}

const events = ['dragenter', 'dragover', 'dragleave', 'drop']

onMounted(() => {
    events.forEach((eventName) => {
        document.body.addEventListener(eventName, preventDefaults)
    })
})

onUnmounted(() => {
    events.forEach((eventName) => {
        document.body.removeEventListener(eventName, preventDefaults)
    })
})
</script>