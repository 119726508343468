<template>
	<div>
		<div class="loading loading-lg mt-4" v-if="!error"></div>
		<div v-if="error" class="alert alert-danger mt-4">{{ error }}</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			payload: this.$route.params.payload,
			error: null
		}
	},

	mounted() {
		const decodedBase64Data = decodeURIComponent(this.payload)
		const decodedData = window.atob(decodedBase64Data)
		const jsonData = JSON.parse(decodedData)

		if (jsonData.data === undefined) {
			this.error = 'Invalid payload data for redirect.'
			return
		}

		if (jsonData.type === 'workflow_run') {
			this.$router.push(`/files/${jsonData.data.file_id}`)
		}

		if (jsonData.type === 'issue') {
			this.$router.push(`/issues/${jsonData.data.issue_number}`)
		}

		this.error = 'Invalid payload type for redirect.'

	}
}
</script>
