<template>
    <div class="counters">
        <router-link v-for="(counter, index) in counters" :style="{ color: counter.color }" :to="getRoute(counter)"
            :key="index">
            <span v-if="counter.loading" class="loading"></span>

            <i class="fa-solid fa-3x" :class="counter.icon">
                <div class="counter-background"></div>
            </i>
            <h4 class="text-bold mt-4">
                <span>{{ counter.count }}</span>
            </h4>
            <div class="text-bold">{{ $t(`profile.counters_${counter.counter}`) }}</div>
        </router-link>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            startDate: this.getStartDate(),
            counters: this.$store.state.countersConfig,
        }
    },

    mounted() {
        this.counters.forEach(this.getCount)
    },

    beforeUnmount() {
        this.$store.commit('UPDATE_COUNTERS_CONFIG', this.counters)
    },

    methods: {
        async getCount(counter) {
            counter.loading = true

            try {
                const { data: count } = await axios.get(`statistics/counters/${counter.counter}`, {
                    params: { start_date: this.startDate, ...counter.params },
                })
                counter.count = count
            } catch { }

            counter.loading = false
        },

        getRoute(counter) {
            const ROUTES = {
                forms: { name: 'forms' },
                shares: { name: 'sharing.out' },
                damages: { name: 'files', query: { contains_damage: true } },
                files: { name: 'files', query: { sort: 'created_at', by: 'desc' } },
                files_with_damages: { name: 'files', query: { contains_damage: true } },
                uploads: { name: 'files', query: { sort: 'recent_snap_id', by: 'desc' } },
            }

            return ROUTES[counter.counter]
        },

        getStartDate() {
            const preference = this.$store.state.preferences.start_date

            if (preference === 'all_time') return null

            const DATES = { today: 0, last_7_days: 7, last_30_days: 30 }

            let date = new Date()
            date.setHours(0, 0, 0, 0)
            date.setDate(date.getDate() - DATES[preference])

            return date.toISOString()
        },
    },
}
</script>
