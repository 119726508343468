import axios from 'axios'
import i18n from '~/plugins/i18n.js'

export const duplicateForm = async (form, swal) => {
    const { value } = await swal({
        showCancelButton: true,
        confirmButtonColor: '#0092c8',
        title: i18n.global.t('forms.duplicate_this_form'),
        confirmButtonText: i18n.global.t('main.confirm'),
        cancelButtonText: i18n.global.t('main.cancel'),
    })

    if (!value) return await new Promise(() => { })

    const { data } = await axios.get(`forms/${form.id}`)

    Object.assign(form, data)

    const duplicated = JSON.parse(JSON.stringify(form))

    duplicated.title = duplicated.title + ' (Copy)'

    delete duplicated.id
    duplicated.fields.forEach(field => delete field.id)

    return await axios.post('forms', duplicated)
}

export const deleteForm = async (form, swal) => {
    const { value } = await swal({
        showCancelButton: true,
        title: i18n.global.t('main.are_you_sure'),
        text: i18n.global.t('main.you_wont_be_able_to_revert_this'),
        confirmButtonText: i18n.global.t('main.yes_delete_it'),
        cancelButtonText: i18n.global.t('main.cancel'),
    })

    if (!value) return await new Promise(() => { })

    await axios.delete(`forms/${form.id}`)
}

export const duplicateWorkflow = async (workflow, swal) => {
    const { value } = await swal({
        showCancelButton: true,
        confirmButtonColor: '#0092c8',
        title: i18n.global.t('workflows.duplicate_this_workflow'),
        confirmButtonText: i18n.global.t('main.confirm'),
        cancelButtonText: i18n.global.t('main.cancel'),
    })

    if (!value) return await new Promise(() => { })

    const { data } = await axios.get(`workflows/${workflow.id}`)

    Object.assign(workflow, data)

    const duplicated = JSON.parse(JSON.stringify(workflow))

    duplicated.name = duplicated.name + ' (Copy)'

    delete duplicated.id
    duplicated.steps.forEach(step => delete step.id)

    return await axios.post('workflows', duplicated)
}

export const deleteWorkflow = async (workflow, swal) => {
    const { value } = await swal({
        showCancelButton: true,
        title: i18n.global.t('main.are_you_sure'),
        text: i18n.global.t('main.you_wont_be_able_to_revert_this'),
        confirmButtonText: i18n.global.t('main.yes_delete_it'),
        cancelButtonText: i18n.global.t('main.cancel'),
    })

    if (!value) return await new Promise(() => { })

    return await axios.delete(`workflows/${workflow.id}`)
}

export const getAnswerForField = (field, answers) => {
    for (let i = 0; i < answers.length; i++) {
        if (answers[i].form_field_id === field.id) {
            if (field.type === 'snap' || field.type === 'drawing') {
                // show image/thumb of the first answer
                // because a field may contain multiple answers
                field.image_thumb_sm = answers[i].image_thumb_sm
            }
            else if (field.type === 'single_select') {
                const str = answers[i].value

                // for multi: parse db string to array and remove falsies
                // eg: ['foo',null,undefined,'0'] becomes ['foo', '0']
                if (str.startsWith('[') && str.endsWith(']')) {
                    return JSON.parse(str).filter(item => item)
                }
            }

            return answers[i].value
        }
    }
}