<template>
    <SidePanel ref="panel" :title="`${widget.name} config`" @close="$emit('close')">
        <div class="dev-feature">
            <div class="form-group">
                <label class="form-label" for="transformation">
                    Transformation
                    <a target="_blank" href="https://jsonata.org">(https://jsonata.org)</a>
                </label>
                <input id="transformation" class="form-input" v-model="widget.settings.transformation"
                    placeholder="$ ~> $replace(/\ /, '') ~> $uppercase()" />
            </div>
            <div class="form-group" v-if="widget.name === 'timestamp'">
                <div class="form-group">
                    <label class="form-label" for="timezone">Timezone</label>

                    <VSelect inputId="timezone" :options="timezones" v-model="widget.settings.timezone"></VSelect>
                </div>

                <div class="form-group">
                    <label class="form-label" for="format">Format</label>
                    <input id="format" class="form-input" v-model="widget.settings.timestamp_format"
                        placeholder="YYYY-MM-DD HH:mm:ss" />
                </div>
            </div>
            <div class="form-group" v-else-if="widget.name === 'get_file'">
                <div class="form-group">
                    <label class="form-checkbox">
                        <input type="checkbox" v-model="widget.settings.only_get_files_made_by_device" />
                        <i class="form-icon"></i>
                        <span>Only get files made by device</span>
                    </label>
                </div>

                <div class="form-group">
                    <label class="form-label" for="exclude_files_by_sql_mask">exclude_files_by_sql_mask</label>
                    <input id="exclude_files_by_sql_mask" class="form-input"
                        v-model="widget.settings.exclude_files_by_sql_mask" placeholder="___U_______" />
                </div>
            </div>

            <template v-if="showRegexOptions">
                <div class="form-group">
                    <label class="form-label" for="validationRegex">Validation Regex</label>
                    <input placeholder="regex" id="validationRegex" class="form-input"
                        v-model="widget.settings.validationRegex" />
                </div>

                <div class="form-group">
                    <label class="form-label" for="extractionRegex">Extraction Regex</label>
                    <input placeholder="regex" id="extractionRegex" class="form-input"
                        v-model="widget.settings.extractionRegex" />
                </div>

                <div class="form-group">
                    <label class="form-label" for="replacementRegex">Replacement Regex</label>

                    <div class="input-group">
                        <input placeholder="regex" id="replacementRegex" class="form-input"
                            v-model="widget.settings.replacementRegex[0][0]" />
                        <input placeholder="replacement" id="replacementRegex" class="form-input"
                            v-model="widget.settings.replacementRegex[0][1]" />
                    </div>
                </div>
            </template>

            <div class="form-group">
                <label class="form-checkbox">
                    <input type="checkbox" v-model="widget.settings.get_cutout" />
                    <i class="form-icon"></i>
                    <span>Get Cutout</span>
                </label>

                <label class="form-checkbox">
                    <input type="checkbox" v-model="widget.settings.use_anyline_scanner" />
                    <i class="form-icon"></i>
                    <span>Use Anyline Scanner</span>
                </label>
            </div>

            <div class="form-group">
                <label class="form-label" for="width">Width</label>
                <input min="0" max="100" type="number" id="width" class="form-input" placeholder="width"
                    v-model.number="widget.settings.width" />
            </div>

            <div class="form-group">
                <label class="form-label" for="height">Height</label>
                <input min="0" max="100" type="number" id="height" class="form-input" placeholder="height"
                    v-model.number="widget.settings.height" />
            </div>

            <template v-if="scannerType === 'T'">
                <div class="form-group">
                    <label class="form-label" for="verifyCount">verifyCount</label>
                    <input min="0" max="100" type="number" id="verifyCount" class="form-input" placeholder="verifyCount"
                        v-model.number="widget.settings.verifyCount" />
                </div>

                <div class="form-group">
                    <label class="form-label" for="validChars">Valid Characters</label>
                    <input placeholder="validChars" id="validChars" class="form-input"
                        v-model="widget.settings.validChars" />
                </div>

                <div class="form-group">
                    <label class="form-label" for="replaceCharMap">Replace Character Map</label>

                    <div class="input-group">
                        <input placeholder="replaceCharMap" id="replaceCharMap" class="form-input"
                            v-model="widget.settings.replaceCharMap[0][0]" />
                        <input placeholder="replaceCharMap" id="replaceCharMap" class="form-input"
                            v-model="widget.settings.replaceCharMap[0][1]" />
                    </div>
                </div>
            </template>

            <button type="submit" class="btn btn-primary" @click="save()">{{ $t('main.save') }} </button>
        </div>
    </SidePanel>
</template>

<script>
import SidePanel from '~/components/SidePanel.vue'

const getTimezones = () => import('~/assets/timezones.json').then(m => m.default || m)

export default {
    props: ['modelValue'],
    emits: ['update', 'close'],
    components: { SidePanel },

    data() {
        return {
            timezones: [],
            widget: this.modelValue,
        }
    },

    created() {
        this.applyDefaultSettings()
    },

    async mounted() {
        if (this.widget.name === 'timestamp') {
            const { timezones } = await getTimezones()
            this.timezones = timezones
        }

        // validationRegex: regex (default: undefined)
        // extractionRegex: regex (default: /.*/)
        // replacementRegex: [regex, string][] (default: [[/\s+/g, '']])
        // get_cutout: boolean (default: false) // Upload cutout after scan?
        // width: number (default: 80) // Cutout width in percentage.
        // height: number (default: 20) // Cutout height in percentage.
        // verifyCount: number (default: 2) // How many times do we need the same scan result for it to be valid?
        // validChars: regex (default: \\s\\S) // Which characters are valid?
        // replaceCharMap: [string, string][] (default: []) // Replace chars.
    },

    computed: {
        showRegexOptions() {
            return this.$route.name !== 'workflows.edit'
        },

        scannerType() {
            const TYPE_TO_CONFIG = {
                QRtags: 'B',
                shareDL: 'B',
                barcode: 'B',
                notafiscal: 'B',
                barcodeTag: 'B',
                serialBarcode: 'B',
                document: 'B',

                vin: 'T',
                seal: 'T',
                number: 'T',
                plate_br: 'T',
                plate_eu: 'T',
                container: 'T',
                textScanner: 'T',
                containerTag: 'T',
                licensePlateScanner: 'T',
            }

            return TYPE_TO_CONFIG[this.widget.name]
        }
    },

    methods: {
        save() {
            this.$emit('update', this.widget)
        },

        applyDefaultSettings() {
            const s = { ...this.widget.settings }

            s.transformation = s.transformation || ''
            s.validationRegex = s.validationRegex || ''
            s.extractionRegex = s.extractionRegex || '/.*/'
            s.replacementRegex = s.replacementRegex || [['/\\s+/g', '']]
            s.get_cutout = s.get_cutout || false
            s.width = s.width || 80
            s.height = s.height || 20

            if (this.scannerType === 'T') {
                s.verifyCount = s.verifyCount || 2
                s.validChars = s.validChars || '\\s\\S'
                s.replaceCharMap = s.replaceCharMap || [['', '']]
            }

            s.use_anyline_scanner = s.use_anyline_scanner || false

            this.widget.settings = s
        },
    },
}
</script>
