<template>
    <div>
        <div v-if="items.length" class="timeline" :class="[{ 'hide-references': hideReferences }, viewType, filter]"
            v-viewer="options">
            <template v-for="item in orderedItems" :key="item.upload_type + item.id">
                <component :is="TYPE_TO_COMPONENT[item.upload_type]" :item="item" showReference
                    :data-no-access="filesEditableUntil < item.created_at ? $t('table.trial_expired') : null"
                    @edit="onEdit" @share="onShare" @delete="onDelete" :class="item.upload_type">
                </component>
            </template>
        </div>

        <EmptyDashboard v-else></EmptyDashboard>

        <TimelineItemUploadEdit v-if="itemEdit" :item="itemEdit" @update="updateEdit" @delete="deleteEdit"
            @close="itemEdit = null"></TimelineItemUploadEdit>

        <Modal v-if="showOnMap" @close="$router.push({ query: $route.query, hash: '' })">
            <MapsView :snap_id="showOnMap" :uploads="uploads" style="height: 50vh" class="my-4"></MapsView>
        </Modal>
    </div>
</template>

<script>
import 'viewerjs/dist/viewer.css'
import { directive as viewer } from 'v-viewer'

import axios from 'axios'
import { mapGetters } from 'vuex'
import MapsView from '~/components/MapsView.vue'
import TimelineItemForm from './TimelineItemForm.vue'
import TimelineItemUpload from './TimelineItemUpload.vue'
import TimelineItemUploadEdit from './TimelineItemUploadEdit.vue'
import TimelineItemAttachment from './TimelineItemAttachment.vue'
import EmptyDashboard from '~/components/empty_states/EmptyDashboard.vue'

export default {
    components: {
        MapsView,
        EmptyDashboard,
        TimelineItemForm,
        TimelineItemUpload,
        TimelineItemUploadEdit,
        TimelineItemAttachment,
    },

    props: ['uploads', 'filter', 'sortDesc', 'viewType', 'hideReferences'],

    directives: { viewer: viewer() },

    data() {
        return {
            items: [],

            itemIdx: -1,
            itemEdit: null,
            showOnMap: null,

            TYPE_TO_COMPONENT: {
                form: 'TimelineItemForm',
                snap: 'TimelineItemUpload',
                seal: 'TimelineItemUpload',
                licensePlateScanner: 'TimelineItemUpload',
                document: 'TimelineItemUpload',
                attachment: 'TimelineItemAttachment',
                'serial-shooter': 'TimelineItemUpload',
                barcodeTag: 'TimelineItemUpload',
                containerTag: 'TimelineItemUpload',
                video: 'TimelineItemUpload',
            },

            options: {
                button: false,
                transition: false,
                url: 'data-source',
                viewed: this.onView,
            },
        }
    },

    watch: {
        '$route.hash': {
            immediate: true,
            handler(hash) {
                const splits = hash.split('#map-')
                this.showOnMap = splits[1] ? splits[1] : null
            },
        },
    },

    computed: {
        ...mapGetters(['filesEditableUntil']),

        orderedItems() {
            if (this.sortDesc) {
                return [...this.items].reverse()
            }

            return this.items
        },
    },

    created() {
        this.items = this.uploads.sort((a, b) => new Date(a.scan_date_time) - new Date(b.scan_date_time))
    },

    methods: {
        onView(e) {
            const viewer = e.target.viewer
            const thumbElement = viewer.images[viewer.index]

            // Check if thumbnail is loaded
            if (thumbElement.getAttribute('lazy') === 'error') return

            const origIsPortrait = this.isPortrait(viewer.imageData)
            const thumbIsPortrait = this.isPortrait(thumbElement)

            if (origIsPortrait !== thumbIsPortrait) {
                viewer.rotate(90)
            }
        },

        isPortrait(img) {
            const w = img.naturalWidth || img.width
            const h = img.naturalHeight || img.height

            return h > w
        },

        onShare(item) {
            if (item.upload_type === 'form') {
                item.shared = !item.shared
                axios.patch(`submits/${item.id}`, item)
            } else {
                item.include_in_share = !item.include_in_share
                axios.patch(`uploads/${item.id}`, item)
            }
        },

        onEdit(item) {
            this.itemEdit = { ...item } // keep original data and use this for editting
            this.itemIdx = this.items.indexOf(item) // on save we update the original
        },

        onDelete(item) {
            let options = {
                showCancelButton: true,
                title: this.$t('main.are_you_sure'),
                text: this.$t('main.you_wont_be_able_to_revert_this'),
                confirmButtonText: this.$t('main.yes_delete_it'),
                cancelButtonText: this.$t('main.cancel'),
            }

            if (item.upload_type === 'snap') {
                options['input'] = 'select'
                options['inputOptions'] = window.config.snapDeletedReasons
            }

            this.$swal(options).then(result => {
                if (result.value) {
                    const endpoint = item.upload_type === 'form' ? 'submits' : 'uploads'

                    axios.delete(`${endpoint}/${item.id}`, { data: { reason: result.value } }).then(() => {
                        window.toast(this.$t('i.delete', { i: '' }))
                        this.itemIdx = this.items.indexOf(item) // on save we update the original
                        this.deleteEdit()
                    })
                }
            })
        },

        deleteEdit() {
            this.items.splice(this.itemIdx, 1)
            this.itemEdit = null
        },

        updateEdit(data) {
            Object.assign(data, this.itemEdit)
            this.items[this.itemIdx] = data
            this.itemEdit = null
        },
    },
}
</script>