<template>
    <Modal v-if="tenant" :title="tenant.company" @close="$router.push({ name: 'companies' })">
        <div class="form-group">
            <Errors :errors="errors" />
        </div>

        <form class="form-horizontal" @submit.prevent="save()" ref="form">
            <div class="form-group">
                <div class="col-3 col-sm-12">
                    <label class="form-label" for="company">Company</label>
                </div>
                <div class="col-9 col-sm-12">
                    <input v-model="tenant.company" id="company" class="form-input" />
                </div>
            </div>
            <div class="form-group">
                <div class="col-3 col-sm-12">
                    <label class="form-label" for="domain">Domain</label>
                </div>
                <div class="col-9 col-sm-12">
                    <input v-model="tenant.domain" id="domain" class="form-input" />
                </div>
            </div>
            <div class="form-group">
                <div class="col-3 col-sm-12">
                    <label class="form-label" for="phone">Phone number</label>
                </div>
                <div class="col-9 col-sm-12">
                    <input v-model="tenant.phone" id="phone" class="form-input" />
                </div>
            </div>
            <div class="form-group">
                <div class="col-3 col-sm-12">
                    <label class="form-label" for="country">Country</label>
                </div>
                <div class="col-9 col-sm-12">
                    <input v-model="tenant.country" id="country" class="form-input" />
                </div>
            </div>
            <div class="form-group">
                <div class="col-3 col-sm-12">
                    <label class="form-label" for="region">Region</label>
                </div>
                <div class="col-9 col-sm-12">
                    <select v-model="tenant.region" id="region" class="form-select">
                        <option v-for="region in regions" :key="region.name" :value="region.name">
                            {{ region.title }}
                        </option>
                    </select>
                </div>
            </div>

            <div class="form-group">
                <div class="col-3 col-sm-12">
                    <label class="form-label" for="limit">Max devices</label>
                </div>
                <div class="col-9 col-sm-12">
                    <input type="number" v-model.number="tenant.license_devices_limit" id="limit" class="form-input" />
                </div>
            </div>

            <div class="form-group">
                <div class="col-3 col-sm-12">
                    <label class="form-label" for="status">Status</label>
                </div>
                <div class="col-9 col-sm-12">
                    <select v-model="tenant.status" id="status" class="form-select" @change="changeStatus">
                        <option v-for="status in statuses" :key="status.name" :value="status.name"
                            :disabled="!status.selectable">
                            {{ status.title }}
                        </option>
                    </select>
                </div>
            </div>

            <div class="form-group" v-if="tenant.status === 'trial'">
                <div class="col-3 col-sm-12">
                    <label class="form-label" for="expiry">Trial expiry</label>
                </div>
                <div class="col-9 col-sm-12">
                    <DatePicker v-model="tenant.trial_expires_at" placeholder="Trial expiry" required></DatePicker>
                </div>
            </div>

            <div class="mb-2">
                <div v-if="tenant.abowire_subscription_id" class="form-group">
                    <div class="col-3 col-sm-12"></div>
                    <div class="col-9 col-sm-12">Customer has a paid subscription</div>
                </div>

                <template v-else>
                    <div class="form-group">
                        <div class="col-3 col-sm-12">
                            <label class="form-label" for="currency">Currency</label>
                        </div>
                        <div class="col-9 col-sm-12">
                            <select v-model="tenant.license_currency" id="currency" class="form-select">
                                <option v-for="currency in currencies" :key="currency.id" :value="currency.value">
                                    {{ currency.name }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="form-group" :class="{ ghost: fetchingData }">
                        <div class="col-3 col-sm-12">
                            <label class="form-label" for="plan">Default Subscription</label>
                        </div>
                        <div class="col-9 col-sm-12">
                            <select id="plan" class="form-select" v-model="tenant.abowire_default_sku">
                                <option :value="null">Default (standard)</option>
                                <option v-for="plan in plans" :key="plan.id" :value="plan.sku">
                                    {{ plan.name }} ({{ plan.sku }})
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="form-group" :class="{ ghost: fetchingData }">
                        <div class="col-3 col-sm-12">
                            <label class="form-label" for="coupon">Default Coupon</label>
                        </div>
                        <div class="col-9 col-sm-12">
                            <select id="coupon" class="form-select" v-model="tenant.abowire_default_coupon_code">
                                <option :value="null">Default (no coupon)</option>
                                <option v-for="coupon in coupons" :key="coupon.id" :value="coupon.code">
                                    {{ coupon.name }} ({{ coupon.code }})
                                </option>
                            </select>
                        </div>
                    </div>
                </template>
            </div>

            <div class="form-group">
                <div class="col-3 col-sm-12"></div>
                <div class="col-9 col-sm-12">
                    <label class="form-checkbox">
                        <input type="checkbox" v-model="tenant.use_weather_api" />
                        <i class="form-icon"></i>
                        <span>{{ $t('settings.license_product_use_weather_api') }}</span>
                        <small class="text-bold ml-2">€ 5 per licence per month</small>
                    </label>
                </div>
                <div class="col-3 col-sm-12"></div>
                <div class="col-9 col-sm-12">
                    <label class="form-checkbox">
                        <input type="checkbox" v-model="tenant.use_kiosks" />
                        <i class="form-icon"></i>
                        <span>{{ $t('settings.license_product_use_kiosks') }}</span>
                        <small class="text-bold ml-2">€ 25 per licence per month</small>
                    </label>
                </div>
                <div class="col-3 col-sm-12"></div>
                <div class="col-9 col-sm-12">
                    <label class="form-checkbox">
                        <input type="checkbox" v-model="tenant.use_report_in_browser" />
                        <i class="form-icon"></i>
                        <span>{{ $t('settings.license_product_use_report_in_browser') }}</span>
                        <small class="text-bold ml-2"></small>
                    </label>
                </div>
            </div>
        </form>
        <template #footer>
            <div class="flex-space">
                <VisibleOnChangeButton class="mr-2" ref="observer" :data="tenant"
                    @click="$refs.form.requestSubmit()">
                </VisibleOnChangeButton>

                <router-link tag="button" class="btn mr-auto" :to="{ name: 'companies' }" exact>Cancel</router-link>
                <router-link tag="button" class="btn mr-2" :to="{ name: 'companies.stats' }">Statistics</router-link>
                <button class="btn" @click="impersonateTenant()">Impersonate</button>
            </div>
        </template>
    </Modal>
</template>

<script>
import axios from 'axios'

export default {
    props: ['regions', 'statuses'],

    data() {
        return {
            errors: null,
            tenant: null,
            oldStatus: '',

            id: this.$route.params.id,
            currencies: window.config.currencies,

            plans: [],
            coupons: [],
            fetchingData: true,
        }
    },

    beforeRouteLeave(to, from, next) {
        this.$refs.observer.beforeRouteLeave(to, from, next)
    },

    async mounted() {
        const { data: tenant } = await axios.get(`admin/tenants/${this.id}`)

        this.tenant = tenant
        this.oldStatus = this.tenant.status

        if (this.tenant.trial_expires_at) {
            this.tenant.trial_expires_at = new Date(this.tenant.trial_expires_at)
        }
    },

    watch: { 'tenant.license_currency': 'fetchAbowireData' },

    methods: {
        save() {
            const tenant = this.tenant

            if (this.oldStatus !== tenant.status) {
                if (tenant.status === 'deleted') {
                    if (!confirm('This will disable the tenant, are you REALLY sure?')) return
                }

                if (tenant.status === 'free') {
                    if (!confirm('This will set all users in this tenant to FREE')) return
                }
            }

            axios
                .patch(`admin/tenants/${tenant.id}`, tenant)
                .then(() => {
                    this.$refs.observer.initialize()

                    window.toast(`Updated ${tenant.company}`)
                    this.$router.push({ name: 'companies' })
                    this.$emit('refresh')
                })
                .catch(({ response }) => {
                    this.errors = response.data
                })
        },

        changeStatus() {
            if (this.tenant.status === 'trial') {
                if (!this.tenant.trial_expires_at) {
                    this.tenant.trial_expires_at = dayjs().add(2, 'week').toDate()
                }
            } else {
                this.tenant.trial_expires_at = null
            }
        },

        async fetchAbowireData(currency) {
            this.fetchingData = true

            const { data: plans } = await axios.get('admin/abowire/plans', { params: { currency } })
            this.plans = plans

            const { data: coupons } = await axios.get('admin/abowire/coupons', { params: { currency } })
            this.coupons = coupons

            this.fetchingData = false
        },

        impersonateTenant() {
            this.$swal({
                showCancelButton: true,
                title: this.$t('main.are_you_sure'),
                cancelButtonText: this.$t('main.cancel'),
                text: 'You are about to impersonate. Any action taken will persist.',
            }).then(async result => {
                if (result.value) {
                    await axios.post(`admin/impersonate/${this.tenant.id}`)
                    this.$store.dispatch('fetchUser')
                    this.$router.push({ name: 'dashboard' })
                }
            })
        },
    },
}
</script>
