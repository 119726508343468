import axios from 'axios'
import store from '~/store'
import i18n from '~/plugins/i18n'

axios.defaults.baseURL = import.meta.env.VITE_API_BASE_URL

axios.interceptors.request.use(config => {
    if (store.state.jwt) {
        config.headers.common['Authorization'] = `Bearer ${store.state.jwt.token}`
    }

    config.headers.common['X-Requested-With'] = 'XMLHttpRequest'
    config.headers.common['Accept-Language'] = store.state.language

    return config
})

axios.interceptors.response.use(
    response => response,
    async error => {
        const status = error.response?.status

        if (status === 401 || status === 402) {
            if (store.state.loggedIn) {
                window.toast(i18n.global.t('auth.session_expired'), 'info', 5000)
            }

            await store.dispatch('endSession')
        }

        if (status === 403) {
            window.toast(i18n.global.t('main.no_permission'), 'error')
        }

        return Promise.reject(error)
    },
)
