<template>
    <button type="submit" class="btn btn-primary" :disabled="equal" @click="click">
        <slot>{{ $t('main.save') }}</slot>
    </button>
</template>

<script>
export default {
    props: ['data'],
    emits: ['click'],

    data: () => ({ equal: true, cache: null }),

    created() {
        this.initialize()
    },

    watch: {
        data: {
            deep: true,
            handler(data, old) {
                const k1 = Object.keys(old)
                const k2 = Object.keys(data)

                if (k1.length === k2.length) {
                    this.equal = JSON.stringify(data) === JSON.stringify(this.cache)
                } else {
                    this.initialize()
                }
            },
        },
    },

    methods: {
        click() {
            if (this.equal) return // prevent click when data remains the same

            this.$emit('click')
        },

        initialize() {
            this.equal = true
            this.cache = JSON.parse(JSON.stringify(this.data))
        },

        beforeRouteLeave(to, from, next) {
            if (!this.equal) {
                this.$swal({
                    showCancelButton: true,
                    cancelButtonText: this.$t('main.discard_changes'),
                    confirmButtonText: this.$t('main.cancel'),
                    title: this.$t('main.unsaved_changes'),
                }).then(result => {
                    if (result.dismiss === 'cancel') {
                        next()
                    }
                })
            } else {
                next()
            }
        },
    },
}
</script>
