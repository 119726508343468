<template>
    <div>
        <ContextBar :title="$t('kiosk.pick_kiosk')" class="bg-gray"></ContextBar>

        <div class="columns" v-if="kiosks.length">
            <div class="column col-6 col-md-12" v-for="kiosk in kiosks" :key="kiosk.id">
                <router-link :to="{ name: 'kiosks.show', params: { id: kiosk.id } }" class="card c-hand mb-2">
                    <div class="card-header">
                        <div class="card-title h5">{{ kiosk.title }}</div>
                    </div>

                    <div class="card-image">
                        <img class="img-responsive" :src="kiosk.image" alt="Kiosk Logo" />
                    </div>
                </router-link>
            </div>

            <router-view :kiosks="kiosks"></router-view>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    data: () => ({ kiosks: [] }),

    async mounted() {
        const { data } = await axios.get('kiosks')

        this.kiosks = data
    },
}
</script>
