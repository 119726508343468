<template>
    <div :id="`upload-${item.id}`" class="timeline-item"
        :class="{ 'has-damage': item.has_damage, manual: !item.device_id }">
        <div class="timeline-left">
            <a :href="`#upload-${item.id}`" class="timeline-icon">
                <i class="fas fa-sm"></i>
            </a>
        </div>
        <div class="timeline-content">
            <div class="timeline-title">
                <span>{{ $t(`timeline.${item.upload_type}`) }}</span>
                <span class="text-small">
                    <i class="far fa-clock mr-1"></i>
                    <DateTime :date="item.created_at" ago></DateTime>
                </span>
            </div>

            <div class="box">
                <div class="columns">
                    <div class="column col-3 col-xl-5 col-md-12">
                        <div class="pb-2">
                            <img v-if="preLoad" :src="item.image_thumb_sm" class="img-responsive" alt />
                            <video v-else-if="item.upload_type === 'video'" width="300" height="300" preload="metadata"
                                controls>
                                <source :src="item.image_url" type="video/mp4" />
                            </video>
                            <img v-else width="300" v-lazy="item.image_thumb_sm" :data-src="item.image_thumb_sm"
                                :data-source="item.image_url" class="img-responsive c-hand" alt />
                        </div>
                    </div>
                    <div class="column">
                        <div class="flex-space" v-if="showReference">
                            <div class="text-bold">{{ $t('files.reference') }}</div>
                            <router-link :to="{ name: 'files.show', params: { id: item.file_id } }">
                                {{ item.scan_code }}
                            </router-link>
                        </div>
                        <div class="flex-space date-time">
                            <div class="text-bold">{{ $t('timeline.snap_date_time') }}</div>
                            <DateTime :date="item.scan_date_time"></DateTime>
                        </div>
                        <div class="flex-space place" v-if="item.latitude">
                            <div class="text-bold">{{ $t('timeline.place') }}</div>
                            <div>
                                <a class="d-block" :href="`#map-${item.id}`">
                                    <span v-if="freeFormAddress">{{ freeFormAddress }}</span>
                                    <span v-else>{{ item.latitude }} / {{ item.longitude }}</span>
                                </a>

                                <div class="weather" v-if="item.properties && item.properties.weather">
                                    <div :class="`w-${item.properties.weather.weather_code}`"></div>
                                    <div>{{ item.properties.weather.temperature }}&deg;</div>
                                    <div>{{ $t(`weather.${item.properties.weather.weather_code}`) }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="flex-space uploaded-by">
                            <div class="text-bold">{{ $t('timeline.uploaded_by') }}</div>
                            <div v-if="item.device_id">
                                <router-link :to="{ name: 'settings.devices.show', params: { id: item.device_id } }">
                                    {{ item.device_nick }}
                                </router-link>
                            </div>
                            <div v-else>{{ item.device_nick }}</div>
                        </div>

                        <div class="flex-space damage-type">
                            <div class="text-bold">{{ $t('timeline.damage_type') }}</div>
                            <div>{{ item.damage_type_desc }}</div>
                        </div>

                        <div class="flex-space document-type" v-if="item.document_type_id">
                            <div class="text-bold">{{ $t('timeline.document_type') }}</div>
                            <div>{{ item.document_type_desc }}</div>
                        </div>

                        <div class="flex-space workflow-item" v-if="item.workflow_step_id">
                            <div class="text-bold">{{ item.workflow_description }}</div>
                            <div>{{ item.workflow_step_description }}</div>
                        </div>

                        <div class="flex-space comment" v-if="item.comment">
                            <div class="text-bold">{{ $t('timeline.comment') }}</div>
                            <div>{{ item.comment }}</div>
                        </div>

                        <Fields class="mt-2" :fields="item.fields"></Fields>

                        <div class="text-right">
                            <button @click="$emit('delete', item)" class="delete-snap btn mr-2">
                                <i class="fas fa-trash"></i>
                            </button>

                            <button @click="$emit('edit', item)" class="edit-snap btn mr-2">
                                <i class="fas fa-pencil"></i>
                            </button>

                            <a :href="item.image_url" target="_blank" class="btn mr-2">
                                <i class="far fa-circle-down"></i>
                            </a>

                            <button @click="$emit('share', item)" class="edit-snap btn"
                                :class="{ 'text-gray': !item.include_in_share }">
                                <i class="fas fa-share-alt"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Fields from '~/components/files/FileFields.vue'

export default {
    props: {
        item: {
            type: Object,
            required: true
        },
        showReference: {
            type: Boolean,
            default: false
        },
        preLoad: {
            type: Boolean,
            default: false
        }
    },

    components: { Fields },

    computed: {
        freeFormAddress() {
            if (!this.item.geocoding) return
            if (!this.item.geocoding.addresses) return
            if (!this.item.geocoding.addresses.length) return
            return this.item.geocoding.addresses[0].address.freeformAddress
        }
    },
}
</script>
