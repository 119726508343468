<template>
    <div class="dropdown language-dropdown">
        <a tabindex="0" class="btn dropdown-toggle">
            <i class="fa-solid fa-globe mr-2"></i>
            <span>{{ locales[currentLocale] }}</span>
            <i class="fa-solid fa-caret-down ml-2"></i>
        </a>

        <ul class="menu">
            <li class="menu-item c-hand" v-for="(lang, code) in locales" :key="code"
                :class="{ active: currentLocale === code }">
                <a @click.prevent="setLanguage(code)">{{ lang }}</a>
            </li>
        </ul>
    </div>
</template>

<script>
import axios from 'axios'
import { setI18nLanguage } from '~/plugins/i18n.js'

export default {
    props: { save: Boolean },
    data: () => ({ locales: window.config.locales }),

    computed: {
        currentLocale() {
            return this.$i18n.locale
        },
    },

    methods: {
        setLanguage(language) {
            setI18nLanguage(language)
            this.$store.commit('UPDATE_LANGUAGE', { language })

            if (!this.save) return
            axios.post('sync', { language: this.$store.state.language })
        },
    },
}
</script>
