<template>
    <div class="pagination">
        <div class="pagination-left">
            <button class="btn" @click.prevent="prevPage()" :class="{ ghost: !hasPrevPage }">
                <i class="fa-regular fa-chevron-left fa-fw"></i>
            </button>

            <div>{{ $t('main.page') }} {{ paginator.current_page }}</div>

            <button class="btn" @click.prevent="nextPage()" :class="{ ghost: !hasNextPage }">
                <i class="fa-regular fa-chevron-right fa-fw"></i>
            </button>
        </div>

        <div class="pagination-right">
            <div>{{ $t('main.pagination', { from: paginator.from, to: paginator.to, total: paginator.total }) }}</div>

            <div class="mb-1">
                <select :value="limit" @change="updateLimit($event.target.value)" class="form-select">
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="200">200</option>
                </select>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        paginator: {
            type: Object,
            required: true,
        },
        limit: {
            type: [Number, String],
            required: true,
        },
    },

    emits: ['updatePage', 'updateLimit'],

    computed: {
        hasPrevPage() {
            return this.paginator.prev_page_url || this.paginator.prev
        },

        hasNextPage() {
            return this.paginator.next_page_url || this.paginator.next
        }
    },

    methods: {
        prevPage() {
            if (!this.hasPrevPage) return
            this.$emit('updatePage', this.paginator.current_page - 1)
        },

        nextPage() {
            if (!this.hasNextPage) return
            this.$emit('updatePage', this.paginator.current_page + 1)
        },

        updateLimit(limit) {
            this.$emit('updateLimit', limit)
        },
    },
}
</script>
