<template>
    <div :id="`form-${item.id}`" class="timeline-item">
        <div class="timeline-left">
            <a :href="`#form-${item.id}`" class="timeline-icon">
                <i class="fas fa-clipboard-list fa-sm"></i>
            </a>
        </div>
        <div class="timeline-content">
            <div class="timeline-title">
                <span>{{ $t('timeline.form', { title: item.form.title }) }}</span>
                <span class="text-small">
                    <i class="far fa-clock mr-1"></i>
                    <DateTime :date="item.created_at" ago></DateTime>
                </span>
            </div>

            <div class="box">
                <div class="columns">
                    <div class="column col-lg-12">
                        <div class="flex-space" v-for="field in filteredFields" :key="field.id" :class="field.type">
                            <div class="text-bold">{{ field.name }}</div>
                            <i v-if="field.type === 'checkbox'" class="far"
                                :class="field.value == '1' ? 'fa-check-square' : 'fa-square'"></i>
                            <template v-else-if="field.type === 'snap' || field.type === 'drawing'">
                                <img v-if="preLoad" :src="field.image_thumb_sm" class="img-responsive" alt />
                                <img v-else v-lazy="field.image_thumb_sm" class="img-responsive c-hand"
                                    :data-source="field.value" alt />
                            </template>
                            <div v-else>{{ field.value }}</div>
                        </div>
                    </div>
                    <div class="column col-lg-12">
                        <div class="flex-space" v-if="showReference">
                            <div class="text-bold">{{ $t('files.reference') }}</div>
                            <router-link :to="{ name: 'files.show', params: { id: item.file_id } }">
                                {{ item.scan_code }}
                            </router-link>
                        </div>

                        <div class="flex-space date-time">
                            <div class="text-bold">{{ $t('timeline.submit_date_time') }}</div>
                            <DateTime :date="item.submit_date_time"></DateTime>
                        </div>

                        <div class="flex-space place" v-if="item.latitude">
                            <div class="text-bold">{{ $t('timeline.place') }}</div>
                            <span v-if="freeFormAddress">{{ freeFormAddress }}</span>
                            <span v-else>{{ item.latitude }} / {{ item.longitude }}</span>
                        </div>

                        <div class="flex-space uploaded-by">
                            <div class="text-bold">{{ $t('timeline.uploaded_by') }}</div>
                            <div v-if="item.device_id">
                                <router-link :to="{ name: 'settings.devices.show', params: { id: item.device_id } }">
                                    {{ item.nick }}
                                </router-link>
                            </div>
                            <div v-else>{{ item.nick }}</div>
                        </div>

                        <div class="flex-space workflow-item" v-if="item.workflow_step_id">
                            <div class="text-bold">{{ item.workflow_description }}</div>
                            <div>{{ item.workflow_step_description }}</div>
                        </div>

                        <div class="text-right">
                            <button @click="$emit('delete', item)" class="delete-submit btn mr-2">
                                <i class="fas fa-trash"></i>
                            </button>

                            <router-link class="edit-submit btn mr-2"
                                :to="{ name: 'files.form_submit.edit', params: { id: item.file_id, submit_id: item.id } }">
                                <i class="fas fa-pencil"></i>
                            </router-link>

                            <button @click="$emit('share', item)" class="edit-submit btn"
                                :class="{ 'text-gray': !item.shared }">
                                <i class="fas fa-share-alt"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getAnswerForField } from '~/utils'

export default {
    props: {
        item: {
            type: Object,
            required: true
        },
        showReference: {
            type: Boolean,
            default: false
        },
        preLoad: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        filteredFields() {
            return this.item.form.fields.filter(field => !field.hide_from_table)
        },

        freeFormAddress() {
            if (!this.item.geocoding) return
            if (!this.item.geocoding.addresses) return
            if (!this.item.geocoding.addresses.length) return
            return this.item.geocoding.addresses[0].address.freeformAddress
        },
    },

    created() {
        const answers = this.item.answers
        const fields = this.item.form.fields

        const isEmpty = value => value === '' || value === undefined

        for (let i = fields.length - 1; i >= 0; i--) {
            const field = fields[i]

            // if the field is hidden (to be revealed by the answer of another field) it should not be required
            if (field.options.hidden) {
                field.required = false
            }

            field.value = getAnswerForField(field, answers)

            // check if answer is an array (multi-select) 
            if (Array.isArray(field.value)) {
                field.value = field.value.join(', ')
            }

            // show always when "show_in_platform" BUT NOT when there is no value to show AND
            // (the field has been deleted OR we're hiding empty values)
            field.hide_from_table = !(
                field.show_in_platform &&
                !(isEmpty(field.value) && (field.deleted_at || !this.item.form.display_empty_values))
            )
        }
    }
}
</script>
