<template>
    <SidePanel :title="`Field ${id || 'create'}`" @close="callback('cancel')">
        <form @submit.prevent="updateTag(tag)">
            <div class="form-group">
                <Errors :errors="errors" />
            </div>

            <div class="form-group">
                <label class="form-label" for="name">{{ $t('fields.field') }}</label>
                <input id="name" class="form-input" v-model="tag.name" :placeholder="$t('fields.field')" required />
            </div>

            <div v-if="!id" class="form-group">
                <label class="form-label" for="type">{{ $t('fields.type') }}</label>
                <select v-model="tag.type" id="type" class="form-select" required>
                    <option v-for="t in types" :key="t" :value="t">{{ $t(`fields.${t}`) }}</option>
                </select>
            </div>

            <div v-if="tag.type === 'select' || tag.type === 'sharing'" class="form-group">
                <div class="input-group my-1" v-for="(v, i) in tag.properties[tag.type]" :key="i">
                    <input class="form-input input-sm" v-model="tag.properties[tag.type][i]"
                        :placeholder="$t('main.value') + ' ' + (i + 1)" required />

                    <a href="#" class="btn btn-sm btn-clear" @click.prevent="removeOption(i, tag.type)"></a>
                </div>

                <button type="button" class="btn btn-sm" @click="addOption(tag.type)">
                    <i class="fas fa-plus-circle mr-2"></i>
                    <span>{{ $t('forms.add_option') }}</span>
                </button>
            </div>

            <div v-else-if="tag.type === 'geo_fencing'" class="form-group">
                <button type="button" class="btn btn-sm my-1" @click="geoModal = true">
                    <i class="fas fa-cog mr-2"></i>
                    <span>{{ $t('fields.geofence_options') }}</span>
                </button>

                <div v-for="(fence, i) in tag.properties.geo_fencing" :key="i" class="my-1">
                    <span class="label">{{ fence.name }}</span>
                </div>
            </div>

            <div v-else-if="tag.type === 'seal'" class="form-group">
                <label class="form-label" for="regex">
                    {{ $t('regex.regular_expression') }}
                </label>
                <input id="regex" class="form-input" v-model="tag.properties.seal.regex"
                    placeholder="[A-Z]{1,8}\d{1,12}" />
            </div>

            <div v-if="tag.type === 'checkbox' || tag.type === 'select'" class="form-group">
                <label class="form-switch">
                    <input type="checkbox" v-model="tag.auto_tag" />
                    <i class="form-icon"></i>
                    <span>{{ $t('fields.add_to_new_files') }}</span>
                </label>
            </div>

            <div class="form-group">
                <label class="form-label" for="color">{{ $t('fields.color') }}</label>
                <input type="color" v-model="tag.color" id="color" class="form-input" />
            </div>

            <div class="form-group">
                <label class="form-label" for="location_id">{{ $t('fields.available_for_locations') }}</label>
                <select v-model="tag.location_id" id="location_id" class="form-select">
                    <option :value="null">{{ $t('fields.all_locations') }}</option>
                    <option v-for="location in locations" :key="location.id" :value="location.id">
                        {{ location.location }}
                    </option>
                </select>
            </div>

            <div v-if="!id" class="flex-space">
                <button type="submit" class="btn btn-primary" :class="{ loading }">{{ $t('main.create') }}</button>
            </div>
            <div v-else class="flex-space">
                <button type="submit" class="btn btn-primary" :class="{ loading }">{{ $t('main.save') }}</button>
                <button type="button" class="btn btn-error" @click="removeTag()">{{ $t('main.delete') }}</button>
            </div>
        </form>

        <GeofenceModal v-if="geoModal" :data="tag.properties.geo_fencing" @close="geoModal = false"
            @save="saveGeofenceSettings"></GeofenceModal>
    </SidePanel>
</template>

<script>
import axios from 'axios'
import router from '~/router'
import SidePanel from '~/components/SidePanel.vue'
import GeofenceModal from './FieldsCreateGeofenceModal.vue'

export default {
    components: { SidePanel, GeofenceModal },

    props: {
        locations: { type: Array },
        root: { type: Boolean, default: true },
        callback: {
            type: Function,
            default() {
                router.push({ name: 'settings.fields' })
            },
        },
        types: {
            type: Array,
            default() {
                return ['text', 'number', 'date', 'checkbox', 'select', 'geo_fencing', 'sharing', 'seal']
            },
        },
    },

    data() {
        return {
            errors: null,
            loading: false,

            id: this.root ? this.$route.params.id : null,

            tag: {
                auto_tag: false,
                location_id: null,
                color: '#ffffff',
                type: 'text',
                name: '',
                properties: {
                    select: [''],
                    sharing: [''],
                    seal: { regex: '' },
                    geo_fencing: [],
                },
            },

            geoModal: false,
        }
    },

    async mounted() {
        if (this.id) {
            const { data } = await axios.get(`fields/${this.id}`)

            this.tag = data
        }
    },

    methods: {
        updateTag(tag) {
            if (tag.type === 'seal') {
                if (tag.properties.seal.regex) {
                    try {
                        new RegExp(tag.properties.seal.regex)
                    } catch {
                        return alert('Regular expression pattern error')
                    }
                } else {
                    tag.properties.seal.regex = '[A-Z]{0,8}\\d{4,12}'
                }
            }

            this.errors = null
            this.loading = true

            // only store what is necessary per type
            tag.properties = {
                [tag.type]: tag.properties[tag.type],
            }

            axios({
                method: this.id ? 'patch' : 'post',
                url: this.id ? `fields/${this.id}` : 'fields',
                data: tag,
            }).then(
                ({ data }) => {
                    this.$emit('refresh')
                    this.callback('created', data)
                    window.toast(
                        this.$t(this.id ? 'i.update' : 'i.create', {
                            i: tag.name,
                        }),
                    )
                },
                ({ response }) => {
                    this.loading = false
                    this.errors = response.data
                },
            )
        },

        removeTag() {
            this.$swal({
                showCancelButton: true,
                title: this.$t('main.are_you_sure'),
                text: this.$t('main.you_wont_be_able_to_revert_this'),
                confirmButtonText: this.$t('main.yes_delete_it'),
                cancelButtonText: this.$t('main.cancel'),
            }).then(result => {
                if (result.value) {
                    axios.delete(`fields/${this.id}`).then(() => {
                        this.$emit('refresh')
                        this.$router.push({ name: 'settings.fields' })
                    })
                }
            })
        },

        addOption(type) {
            if (this.tag.properties[type].length < 50) {
                this.tag.properties[type].push('')
            }
        },

        removeOption(index, type) {
            if (this.tag.properties[type].length > 1) {
                this.tag.properties[type].splice(index, 1)
            }
        },

        saveGeofenceSettings(data) {
            this.tag.properties.geo_fencing = data
            this.geoModal = false
        },
    },
}
</script>
