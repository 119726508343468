<template>
    <div class="shared-file">
        <div class="navbar-background">
            <div class="navbar-wrapper">
                <header class="navbar">
                    <section class="navbar-section">
                        <CompanyLogo :company="file.company" />
                    </section>
                    <section class="navbar-section">
                        <LanguageDropdown class="dropdown-right"></LanguageDropdown>
                    </section>
                </header>
            </div>
        </div>

        <div class="platform-wrapper">
            <div class="content">
                <div v-if="file_not_found" class="text-center">
                    <img height="250" src="/images/snappy/uhooh2.png" alt />
                    <div class="text-gray">{{ $t('share.expired') }}</div>
                </div>

                <FileShow v-if="file.id" :file="file" class="" :class="{ 'hide-places-in-share': hide_gps }">
                    <template #actions>
                        <button v-if="allow_zip_download" class="btn" @click="downloadZip()"
                            :class="{ loading: downloading_zip }">
                            {{ $t('files.download_images_as_zip') }}
                        </button>

                        <button v-if="allow_pdf_download" class="btn ml-2" @click="downloadPdf()"
                            :class="{ loading: downloading_pdf }">
                            {{ $t('files.download_pdf') }}
                        </button>
                    </template>
                </FileShow>

                <div v-else class="loading loading-lg" style="margin-top: 50px"></div>

                <LoginOrRegisterPrompt v-if="login_prompt" :type="login_prompt" :properties="login_prompt_props"
                    @close="login_prompt = false"></LoginOrRegisterPrompt>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import FileShow from '~/components/files/FileShow.vue'
import CompanyLogo from '~/components/CompanyLogo.vue'
import LoginOrRegisterPrompt from './LoginOrRegisterPrompt.vue'

export default {
    components: { FileShow, LoginOrRegisterPrompt, CompanyLogo },

    data() {
        return {
            file: {},

            file_not_found: false,
            hide_gps: false,

            downloading_zip: false,
            allow_zip_download: false,
            downloading_pdf: false,
            allow_pdf_download: false,

            login_prompt: false,
            login_prompt_props: {},
            token: this.$route.params.token,
        }
    },

    mounted() {
        this.fetchData()

        if (this.$store.state.loggedIn) return

        axios.get(`share/${this.token}/email_exists_lookup`).then(({ data }) => {
            if (!data.hasOwnProperty('modal_closable')) return

            this.login_prompt_props = data

            if (data.user_exists) {
                // user exists, so offer login
                this.login_prompt = 'login'
            } else if (data.domain_exists) {
                // domain exists, but user does not so offer self-invite
                // TODO: later add self register to existing tenant
            } else {
                // both user/domain dont exist, so offer register
                this.login_prompt = 'register'
            }
        })
    },

    methods: {
        async fetchData() {
            try {
                const { data } = await axios.get(`share/${this.token}`)

                data.timeline = data.uploads.concat(data.form_submits)

                this.file = data
                this.hide_gps = this.file.share.hide_places

                const options = data.share.options

                if (options) {
                    this.allow_pdf_download = options.allow_pdf_download
                    this.allow_zip_download = options.allow_guest_download

                    if ('download_pdf' in this.$route.query) {
                        this.downloadPdf()

                        this.$router.replace({ ...this.$router.currentRoute, query: {} })
                    }
                }
            } catch {
                this.file_not_found = true
            }
        },

        async downloadZip() {
            window.toast(this.$t('files.download_images_as_zip'))

            this.downloading_zip = true

            const { data } = await axios.get(`share/${this.token}/zip`)

            this.downloading_zip = false

            const link = document.createElement('a')

            link.href = data

            document.body.appendChild(link)
            link.click()
        },

        async downloadPdf() {
            window.toast(this.$t('files.download_images_as_pdf'))

            if (this.file.company.use_report_in_browser) {
                return window.open(`/share/${this.token}/print`, '_blank')
            }

            this.downloading_pdf = true

            const { data } = await axios.get(`share/${this.token}/pdf`)

            this.downloading_pdf = false

            const link = document.createElement('a')

            link.href = data
            link.target = '_blank'

            document.body.appendChild(link)
            link.click()
        },
    },
}
</script>
