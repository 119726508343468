<template>
    <div class="form-group" v-if="!item.workflow_step_id">
        <label class="form-label">{{ $t('files.move_to_another_file') }}</label>

        <div class="form-autocomplete text-left" v-clickaway="stopSearching">
            <DebounceInput v-model="search" :placeholder="$t('files.move_to_another_file')" clearable searchIcon />

            <ul class="menu" v-if="searching">
                <li v-for="file in files" :key="file.id" class="menu-item">
                    <a href="#" @click.prevent="setNewFile(file)">{{ file.scan_code }}</a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { directive as clickaway } from 'vue3-click-away'

export default {
    props: ['item'],

    directives: { clickaway },

    data() {
        return {
            files: [],
            search: '',
            searching: false,
            originalReference: this.item.scan_code,
        }
    },

    watch: {
        async search(search) {
            if (!search) {
                this.searching = false
                return
            }

            this.searching = true

            const { data } = await axios.get('search', { params: { query: search, open: true, limit: 10 } })

            this.files = data
        }
    },

    methods: {
        stopSearching() {
            this.searching = false
        },

        setNewFile(file) {
            this.searching = false
            this.search = file.scan_code
            this.item.moveToFile = file
        },
    },
}
</script>
