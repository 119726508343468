<template>
    <div :id="`fields-${step.submit_date_time}`" class="timeline-item fields">
        <div class="timeline-left">
            <a :href="`#fields-${step.submit_date_time}`" class="timeline-icon">
                <i class="fas fa-sm"></i>
            </a>
        </div>
        <div class="timeline-content">
            <div class="timeline-title">
                <span>{{ $t('timeline.fields_added') }}</span>
                <span class="text-small">
                    <i class="far fa-clock mr-1"></i>
                    <DateTime :date="step.submit_date_time" ago></DateTime>
                </span>
            </div>

            <div class="box">
                <div class="columns">
                    <div class="column col-sm-12">
                        <Fields class="mt-2" :fields="step.entities"></Fields>
                    </div>
                    <div class="column">
                        <div class="flex-space date-time">
                            <div class="text-bold">{{ $t('timeline.submit_date_time') }}</div>
                            <DateTime :date="step.submit_date_time"></DateTime>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Fields from '~/components/files/FileFields.vue'

export default {
    props: ['step'],

    components: { Fields },
}
</script>
