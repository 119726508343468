<template>
    <Modal :title="$t('context.create_issues')" @close="$router.push({ name: 'issues' })" class="issues-create">
        <form @submit.prevent="save()" ref="form">
            <div class="form-group">
                <Errors :errors="errors" />
            </div>

            <div class="form-group" :class="{ 'has-error': v$.issue.title.$error }">
                <label class="form-label" for="title">{{ $t('issues.short_description') }}</label>
                <input v-model="issue.title" id="title" class="form-input"
                    :placeholder="$t('issues.short_description')" />
            </div>

            <div class="form-group" :class="{ 'has-error': v$.issue.description.$error }">
                <label class="form-label" for="description">{{ $t('issues.long_description') }}</label>
                <TextEditor v-model="issue.description" :placeholder="$t('issues.long_description')"></TextEditor>
            </div>

            <div class="form-group">
                <label class="form-label" for="assignees">{{ $t('issues.assignees') }}</label>
                <VSelect label="name" inputId="assignees" :reduce="u => u.id" :options="users"
                    v-model="issue.assignees_ids" :multiple="true">
                    <template #selected-option="{ name }">
                        <Avatar :initials="name" class="avatar-sm"></Avatar>
                        <div>{{ name }}</div>
                    </template>
                    <template #option="{ name }">
                        <div class="flex-space" style="width: fit-content">
                            <Avatar :initials="name" class="avatar-sm"></Avatar>
                            <div class="ml-2">{{ name }}</div>
                        </div>
                    </template>
                </VSelect>
            </div>

            <div v-if="!associations" class="form-group">
                <label class="form-label" for="associations">{{ $t('issues.linked_files') }}</label>
                <VSelect v-model="issue.associations" inputId="associations" label="scan_code" :multiple="true"
                    :options="files" :filterable="false" @search="fetchFilesBySearch">
                </VSelect>
            </div>
        </form>

        <template #footer>
            <VisibleOnChangeButton ref="observer" :data="issue" :class="{ loading }"
                @click="$refs.form.requestSubmit()">
                {{ $t('issues.save_issue') }}
            </VisibleOnChangeButton>
        </template>
    </Modal>
</template>

<script>
import axios from 'axios'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import Avatar from '~/components/Avatar.vue'
import TextEditor from '~/components/htmlform/TextEditor.vue'

const descriptionNotEmpty = v => v && v !== '<p><br></p>'

export default {
    props: { associations: Array },

    components: { Avatar, TextEditor },

    setup: () => ({ v$: useVuelidate() }),

    data() {
        return {
            errors: null,
            loading: false,

            users: [this.$store.state.user],
            files: [],
            issue: {
                title: '',
                status: '1',
                description: '<p><br></p>',
                associations: this.associations || [],
                assignees_ids: [this.$store.state.user.id],
            },
        }
    },

    validations() {
        return {
            issue: {
                title: { required },
                description: { descriptionNotEmpty },
            },
        }
    },

    async mounted() {
        this.fetchFilesBySearch()

        const { data: paginator } = await axios.get('users', {
            params: { limit: 200, sort: 'name', by: 'asc' },
        })

        this.users = paginator.data
    },

    methods: {
        async save() {
            if (!await this.v$.$validate()) return

            this.loading = true
            const associations = this.issue.associations.map(a => ({ id: a.id, type: 'File' }))

            try {
                const { data } = await axios.post('issues', { ...this.issue, associations })

                this.$router.push({ name: 'issues.show', params: { issue_number: data.issue_number } })
                window.toast(this.$t('i.create', { i: 'Issue' }))
            } catch ({ response }) {
                this.errors = response.data
            }

            this.loading = false
        },

        async fetchFilesBySearch(query) {
            const { data } = await axios.get('search', { params: { query } })

            this.files = data
        }
    }
}
</script>