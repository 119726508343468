<template>
    <SidePanel ref="panel" :title="$t('context.create_teams')" @close="$router.push({ name: 'settings.teams' })">
        <form @submit.prevent="createLocation()">
            <div class="form-group">
                <Errors :errors="errors" />
            </div>

            <div class="form-group">
                <label class="form-label" for="name">{{ $t('main.name') }}</label>
                <input
                    id="name"
                    class="form-input"
                    v-model="location.location"
                    :placeholder="$t('main.name')"
                    required
                />
            </div>

            <div class="form-group">
                <label class="form-label" for="language">{{ $t('main.language') }}</label>
                <select v-model="location.lang" id="language" class="form-select" required>
                    <option v-for="(lang, key) in locales" :key="key" :value="key">{{ lang }}</option>
                </select>
            </div>

            <div class="flex-space">
                <button class="btn btn-primary" type="submit">{{ $t('main.create') }}</button>
            </div>
        </form>
    </SidePanel>
</template>

<script>
import axios from 'axios'
import SidePanel from '~/components/SidePanel.vue'

export default {
    components: { SidePanel },

    data() {
        return {
            errors: null,
            locales: window.config.locales,
            location: { lang: this.$store.state.language },
        }
    },

    methods: {
        createLocation() {
            this.errors = null

            axios
                .post('locations', this.location)
                .then(({ data }) => {
                    this.$emit('refresh')
                    this.$router.push({
                        name: 'settings.teams.show',
                        params: { id: data.id },
                    })
                    window.toast(this.$t('i.create', { i: this.location.location }))
                })
                .catch(({ response }) => {
                    this.errors = response.data
                })
        },
    },
}
</script>
