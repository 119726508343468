<template>
    <div>
        <ContextBar :title="$t('main.dashboard')"></ContextBar>

        <div v-if="preferences.counters" class="mb-4">
            <Counters></Counters>
        </div>

        <div v-if="preferences.maps" class="my-4 pb-2">
            <MapsView :uploads="pages.flat()"></MapsView>
        </div>

        <div class="files-show my-4" :class="{
            'can-edit-snap': canEditSnaps,
            'can-edit-snap': canEditSnaps,
            'can-delete-snap': canDeleteSnaps,
            'can-delete-submit': canDeleteSubmits,
        }">
            <Timeline v-for="(uploads, index) in pages" :key="index" filter="all" :uploads="uploads" :sortDesc="true"
                :hideReferences="false"></Timeline>

            <div v-if="hasMore" class="text-center" style="margin-top: 50px">
                <button @click="fetchUploads()" class="btn btn-link loading-lg" :class="{ loading }">
                    {{ $t('timeline.load_more') }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import MapsView from '~/components/MapsView.vue'
import Counters from '~/components/Counters.vue'
import Timeline from '~/components/timeline/Timeline_old.vue'

export default {
    components: { Timeline, Counters, MapsView },

    data() {
        return {
            pages: [],
            loading: false,
            hasMore: true,

            preferences: this.$store.state.preferences,

            canEditSnaps: this.$store.getters.can('Update snaps'),
            canEditSubmits: this.$store.getters.can('Update form submits'),
            canDeleteSnaps: this.$store.getters.can('Delete snaps'),
            canDeleteSubmits: this.$store.getters.can('Delete form submits'),
        }
    },

    mounted() {
        this.fetchUploads()

        window.addEventListener('scroll', this.handleScroll)
    },

    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll)
    },

    methods: {
        async fetchUploads() {
            this.loading = true

            const { data: paginator } = await axios.get('uploads', { params: { page: this.pages.length + 1 } })

            this.pages.push(paginator.data)
            this.loading = false

            this.hasMore = !!paginator.next_page_url
        },

        handleScroll() {
            if (this.loading || !this.hasMore) return
            if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
                this.fetchUploads()
            }
        },
    },
}
</script>
