<template>
	<div>
		<table>
			<tr>
				<td><strong>API</strong></td>
				<td>{{ apiBase }}</td>
			</tr>
			<tr v-for="(item, label) in netlifyContext" :key="label">
				<td><strong>{{ label }}</strong></td>
				<td>{{ item }}</td>
			</tr>
		</table>
	</div>
</template>

<script>
import axios from 'axios'
export default {
	computed: {
		apiBase() {
			return axios.defaults.baseURL
		},
		netlifyBuildId() {
			// if(this.netlifyContext().buildId){
			//   return this.netlifyContext().buildId
			// }
			return 'Not available'
		},
		netlifyContext() {
			return window.$netlifyContext
		}
	},
}
</script>