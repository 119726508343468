import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import localizedFormat from 'dayjs/plugin/localizedFormat'

const modules = import.meta.glob('/node_modules/dayjs/locale/(en|nl|pt|br|fr|de|it|es|ru|pl|tr|da|ro|lv|se|zh).js')

dayjs.extend(relativeTime)
dayjs.extend(localizedFormat)

window.dayjs = dayjs

export async function loadDayjsLocales(locale) {
    await modules[`/node_modules/dayjs/locale/${locale}.js`]()

    dayjs.locale(locale)
}